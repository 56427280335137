import { HealthCondition } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useOidcUserProfile } from "@/shared/hooks";
import { convertHealthConditionToFormValues } from "../../others/utils";
import { HealthConditionInformationForm } from "../HealthConditionInformation/HealthConditionInformationForm";
import {
  healthConditionUpsertFormDefaultValues,
  healthConditionUpsertFormSchema,
  IHealthConditionUpsertFormValues,
} from "./HealthConditionUpsertFormSchema";

export type IHealthConditionUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthConditionUpsertFormValues;
  };
};

type HealthConditionUpsertFormProps = {
  healthCondition?: HealthCondition;
  submitButtonLabel: string;
  isClone?: boolean;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IHealthConditionUpsertFormEvent) => void;
};

export const HealthConditionUpsertForm: FC<HealthConditionUpsertFormProps> = props => {
  const { healthCondition, submitButtonLabel, isClone, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IHealthConditionUpsertFormValues>({
    defaultValues: healthConditionUpsertFormDefaultValues,
    schema: healthConditionUpsertFormSchema,
  });

  const {
    setValues,
    formState: { errors },
    handleSubmit,
  } = form;

  const onSubmit = (values: IHealthConditionUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (healthCondition) {
      const _healthCondition = convertHealthConditionToFormValues(healthCondition, !!isClone);
      setValues(_healthCondition);
    }
  }, [healthCondition, isClone, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Health Condition Information")} loading={isLoading} doYouHaveData>
                <HealthConditionInformationForm isUpdateMode={!!healthCondition} isClone={isClone} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Health Condition Icon")} loading={isLoading} doYouHaveData>
                <ImageUploadController
                  control={form.control}
                  name={"icon"}
                  label={t("Icon")}
                  validationRation
                  error={!!errors?.icon}
                  helperText={errors?.icon?.message}
                  token={accessToken!}
                />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
