import { HealthParameter, Maybe, TemplateFieldType } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { InfoItems } from "@toolkit/ui";
import { isEmpty } from "lodash";

export const getHealthParameterDefaultValuesInfoItems = (
  healthParameter: Maybe<Pick<HealthParameter, "type" | "allowedValues" | "defaultValue" | "minNormalRangeValue" | "maxNormalRangeValue">>
): InfoItems => {
  if (isEmpty(healthParameter)) return [];
  const { type, minNormalRangeValue, maxNormalRangeValue } = healthParameter!;
  if (type === TemplateFieldType.Number) {
    return [
      {
        label: i18n.t("Min Normal Range Value", { ns: "gcadmin" }),
        value: minNormalRangeValue || "-",
        direction: "row",
      },
      {
        label: i18n.t("Max Normal Range Value", { ns: "gcadmin" }),
        value: maxNormalRangeValue || "-",
        direction: "row",
      },
    ];
  }
  if (type === TemplateFieldType.String) {
    return [
      {
        label: i18n.t("Default Value", { ns: "gcadmin" }),
        value: healthParameter?.defaultValue || "-",
        direction: "row",
      },
      {
        label: i18n.t("Allowed Values", { ns: "gcadmin" }),
        value: healthParameter?.allowedValues || [],
        direction: "column",
        valueDisplayMode: "chips",
      },
    ];
  }
};
