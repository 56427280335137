import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IMedicalFormClassificationItem } from "../../types";

export const useMedicalFormClassificationsTableColumns = (): CustomTableColumnProps<IMedicalFormClassificationItem>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "label",
        header: t("Label"),
        accessor: ({ name }) => name,
      },
      {
        key: "scoreValueRange",
        header: t("Score Value Range"),
        accessor: ({ rangeFrom, rangeTo }) => `${rangeFrom} - ${rangeTo}`,
      },
    ];
  }, [t]);
};
