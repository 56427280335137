import { ArticleCategory } from "@/schema/types";
import { FC } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, FormCard, Grid, TitleText } from "@toolkit/ui";
import { useArticleCategoryInformationStyle } from "./useArticleCategoryInformationStyle";

type ArticleCategorySubCategoriesProps = {
  articleCategory: ArticleCategory;
  isLoading?: boolean;
};

export const ArticleCategoryInformation: FC<ArticleCategorySubCategoriesProps> = props => {
  const { articleCategory, isLoading } = props;

  const { t } = useTranslation("gcadmin");

  const { classes } = useArticleCategoryInformationStyle();

  return (
    <FormCard title={t("Article Category Information")} doYouHaveData={!!articleCategory} loading={isLoading} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TitleText title={t("Name")} text={articleCategory?.display!} />
        </Grid>

        <Grid item xs={6}>
          <TitleText title={t("Name (Arabic)")} text={articleCategory?.displayAr!} />
        </Grid>

        <Grid item xs={6}>
          <TitleText title={t("Code")} text={articleCategory?.code!} />
        </Grid>

        <Grid item xs={6}>
          <Box className={classes.itemWrapper}>
            <TitleText
              title={t("Health Conditions")}
              text={
                articleCategory?.healthConditions?.length
                  ? articleCategory?.healthConditions?.map(item => pickLocalizedValue(item?.display!, item?.arabicDisplay!))?.join(", ")
                  : ""
              }
            />
          </Box>
        </Grid>
      </Grid>
    </FormCard>
  );
};
