import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const guidedCareTemplateHealthParameterUpsertFormSchema = z.object({
  healthParameter: zodSchema.healthParameter,
});

export type IGuidedCareTemplateHealthParameterUpsertForm = z.infer<typeof guidedCareTemplateHealthParameterUpsertFormSchema>;

export const guidedCareTemplateHealthParameterUpsertFormDefaultValues: Partial<IGuidedCareTemplateHealthParameterUpsertForm> = {
  healthParameter: undefined,
};
