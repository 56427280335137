import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { AdminAgentUpdateContainer } from "../containers/AdminAgentUpdateContainer";
import { AdminAgentsList } from "../containers/AdminAgentsListPage";
import { aiAgentsPaths, agentsRoute } from "./AIAgentsPaths";

export const aiAgentsRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  return {
    id: "ai-agents",
    text: i18n.t("AI Agents", { ns: "gcadmin" }),
    route: agentsRoute,
    hidden: !hasPermission(PermissionEnum.ManageChatFlow),
    isProhibited: !hasPermission(PermissionEnum.ManageChatFlow),
    subItems: [
      {
        id: "ai-agents-list",
        route: aiAgentsPaths.main.route,
        fullPath: aiAgentsPaths.main.fullPath,
        element: <AdminAgentsList />,
        onClick: route => navigate(route),
      },
      {
        id: "ai-agent-update",
        element: <AdminAgentUpdateContainer />,
        route: aiAgentsPaths.update.route,
        fullPath: aiAgentsPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
