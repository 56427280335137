import { ChartType, TemplateFieldType, HealthParameter, HealthParameterInput, HealthParameterUpdateInput } from "@/schema/types";
import { chartTypeOptionsMap, fieldStandardOptionsMap, templateFieldTypeOptionsMap } from "@health/enum-options";
import { createAutocompleteStringOption } from "@toolkit/ui";
import {
  createHealthParameterCategoryAutocompleteOption,
  createHealthParameterUnitAutocompleteOption,
  createSystemCodeAutocompleteOption,
} from "@health/autocompletes";
import { IHealthParameterUpsertFormValues } from "../forms/HealthParameterUpsert/HealthParameterUpsertFormSchema";

export const convertHealthParameterToFormValues = (healthParameter: HealthParameter): IHealthParameterUpsertFormValues => {
  return {
    code: healthParameter?.code!,
    display: healthParameter?.display!,
    arabicDisplay: healthParameter?.arabicDisplay!,
    category: createHealthParameterCategoryAutocompleteOption(healthParameter?.category!),
    unit: healthParameter?.unit ? createHealthParameterUnitAutocompleteOption(healthParameter?.unit) : undefined,
    type: templateFieldTypeOptionsMap[healthParameter?.type!],
    chartType: chartTypeOptionsMap[healthParameter?.chartType!],
    standard: fieldStandardOptionsMap[healthParameter?.standard!],
    standardCode: createSystemCodeAutocompleteOption({
      code: healthParameter?.standardCode!,
      display: healthParameter?.standardCodeDisplay!,
    }),
    allowedValues: (healthParameter?.allowedValues || []) as string[],
    defaultValue: healthParameter?.defaultValue ? createAutocompleteStringOption(healthParameter?.defaultValue) : undefined,
    referenceRange: healthParameter?.referenceRange,
    minNormalRangeValue: healthParameter?.minNormalRangeValue,
    maxNormalRangeValue: healthParameter?.maxNormalRangeValue,
    isActive: !!healthParameter?.isActive,
    isMandatory: !!healthParameter?.isMandatory,
    isVisibleForPatient: !!healthParameter?.visibleForPatientOverview,
    allowManualEditForPatient: !!healthParameter?.allowManualEditForPatient,
    icon: healthParameter?.icon,
  };
};

export const convertHealthParameterFormValuesToBackEndValues = (values: IHealthParameterUpsertFormValues): HealthParameterInput => {
  return {
    code: values.code,
    display: values.display,
    arabicDisplay: values.arabicDisplay,
    category: { id: values.category?.value?.id! },
    unit: values.unit?.value?.id ? { id: values.unit?.value?.id } : undefined,
    type: values?.type?.value,
    chartType: values?.chartType?.value,
    allowedValues: values?.allowedValues,
    defaultValue: values?.defaultValue?.value,
    minNormalRangeValue: values?.minNormalRangeValue,
    maxNormalRangeValue: values?.maxNormalRangeValue,
    standard: values?.standard?.value! ?? undefined,
    standardCode: values?.standardCode?.value?.code! ?? undefined,
    referenceRange: values?.referenceRange,
    isActive: values?.isActive,
    isMandatory: values?.isMandatory,
    visibleForPatientOverview: values?.isVisibleForPatient,
    allowManualEditForPatient: values?.allowManualEditForPatient,
    icon: values?.icon,
  };
};

export const convertHealthParameterUpdateFormValuesToBackEndValues = (
  values: IHealthParameterUpsertFormValues
): HealthParameterUpdateInput => {
  return {
    display: values.display,
    arabicDisplay: values.arabicDisplay,
    category: { id: values.category?.value?.id! },
    unit: values.unit?.value?.id ? { id: values.unit?.value?.id } : undefined,
    chartType: values?.chartType?.value,
    defaultValue: values?.defaultValue?.value,
    minNormalRangeValue: values?.minNormalRangeValue,
    maxNormalRangeValue: values?.maxNormalRangeValue,
    standard: values?.standard?.value! ?? undefined,
    standardCode: values?.standardCode?.value?.code! ?? undefined,
    referenceRange: values?.referenceRange,
    isActive: values?.isActive,
    isMandatory: values?.isMandatory,
    visibleForPatientOverview: values?.isVisibleForPatient,
    allowManualEditForPatient: values?.allowManualEditForPatient,
    icon: values?.icon,
  };
};

export const createHealthParameterCategoryOverallRiskParameterFormValues = () => {
  return {
    type: templateFieldTypeOptionsMap[TemplateFieldType.Number],
    chartType: chartTypeOptionsMap[ChartType.SmoothLine],
    minNormalRangeValue: 0,
    maxNormalRangeValue: 3,
  };
};
