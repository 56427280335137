import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { guidedCareProgramsPaths } from "pages/Programs/constants";
import { GuidedCareProgramCreateMutation, useGuidedCareProgramCreateMutation } from "pages/Programs/gql";
import { useGuidedCareProgramsBreadcrumbs } from "pages/Programs/hooks";
import { convertProgramFormValuesToBackEndValues } from "pages/Programs/others";
import { IGuidedCareProgramUpsertFormEvent } from "pages/Programs/types";
import { GuidedCareProgramUpsertForm } from "../../forms/ProgramUpsert/GuidedCareProgramUpsertForm";

export const GuidedCareProgramCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { setGuidedCareProgramCreateContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const [fetchGuidedCareProgramCreateMutation, { loading: isSubmitting }] = useGuidedCareProgramCreateMutation({
    onCompleted: (mutationData: GuidedCareProgramCreateMutation) => {
      const mutationErrors = mutationData?.guidedCareHealthProgramCreate?.guidedCareHealthProgramErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Program created successfully"));
        navigate(guidedCareProgramsPaths.list.fullPath);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onProgramUpsertFormChange = (event: IGuidedCareProgramUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      fetchGuidedCareProgramCreateMutation({
        variables: {
          input: convertProgramFormValuesToBackEndValues(event.payload.values),
        },
      });
    }
  };

  useEffect(() => {
    setGuidedCareProgramCreateContainerBreadcrumb();
  }, [setGuidedCareProgramCreateContainerBreadcrumb]);

  return (
    <GuidedCareProgramUpsertForm submitButtonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onProgramUpsertFormChange} />
  );
};
