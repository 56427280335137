import { createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IHealthParameterUnitAutocomplete } from "./schema";

export const createHealthParameterUnitAutocompleteOption = (unit: IHealthParameterUnitAutocomplete) => {
  return createAutocompleteOption(
    {
      id: unit?.id!,
      code: unit?.code!,
      display: unit?.display!,
      arabicDisplay: unit?.arabicDisplay!,
    },
    "id",
    item => pickLocalizedValue(item?.display, item?.arabicDisplay)
  );
};
