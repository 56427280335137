import { GuidedCareType, HealthProgramTemplate, TeamMemberPosition } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, FormCard, Grid, PageWrapper, useAddToast } from "@toolkit/ui";
import { convertTemplateToFormValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { GuidedCareTemplatePublish } from "../../components/TemplatePublish/GuidedCareTemplatePublish";
import { GuidedCareTemplateActivitiesForm } from "../TemplateActivities/GuidedCareTemplateActivitiesForm";
import { GuidedCareTemplateGoals } from "../TemplateGoals/GuidedCareTemplateGoals";
import { GuidedCareTemplateInformationForm } from "../TemplateInformation/GuidedCareTemplateInformationForm";
import { GuidedCareTemplateInterventions } from "../TemplateInterventions/GuidedCareTemplateInterventions";
import { GuidedCareTemplateMembersForm } from "../TemplateMembers/GuidedCareTemplateMembersForm";
import { GuidedCareTemplateHealthParametersForm } from "../TemplateHealthParameters/GuidedCareTemplateHealthParametersForm";
import { GuidedCareTemplateVerificationForm } from "../TemplateVerification/GuidedCareTemplateVerificationForm";
import {
  GuidedCareTemplateUpsertFormSchema,
  IGuidedCareTemplateUpsertFormValues,
  guidedCareTemplateUpsertFormDefaultValues,
} from "./GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateUpsertFormStyle } from "./useGuidedCareTemplateUpsertFormStyle";

type GuidedCareTemplateUpsertFormProps = {
  submitButtonLabel: string;
  isClone?: boolean;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  template?: HealthProgramTemplate;
  onChange: (values: IGuidedCareTemplateUpsertFormEvent) => void;
};

export const GuidedCareTemplateUpsertForm: FC<GuidedCareTemplateUpsertFormProps> = props => {
  const { submitButtonLabel, isClone, isLoading, isButtonDisabled, template, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const { failed } = useAddToast();

  const { classes } = useGuidedCareTemplateUpsertFormStyle();

  const form = useCustomForm<IGuidedCareTemplateUpsertFormValues>({
    defaultValues: guidedCareTemplateUpsertFormDefaultValues,
    schema: GuidedCareTemplateUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const isVerified = watch("isVerified");
  const guidedCareType = watch("guidedCareType")?.value;
  const healthCondition = watch("healthCondition")?.value;

  const isGuidedCareTypeEnrollment = guidedCareType && guidedCareType === GuidedCareType.Enrollment;

  const onSubmit = (values: IGuidedCareTemplateUpsertFormValues) => {
    const teamLeader = values?.teamMembers?.find(item => item?.position?.value === TeamMemberPosition.TeamLeader);

    if (!teamLeader?.specialization) {
      return failed(t("Team Leader must have a specialization"));
    }

    if (!values?.activities?.length) {
      return failed(t("Template must have at least one activity"));
    }

    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (template) {
      const _template = convertTemplateToFormValues(template, isClone);
      setValues(_template);
    }
  }, [isClone, setValues, template]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box className={classes.formActionsWrapper}>
              <FormActions
                hasCancel
                hasFormButton
                isLoading={isLoading}
                formButtonTitle={submitButtonLabel}
                newButtonDisabled={isButtonDisabled}
              />

              {template?.isDraft && template?.code && !isClone && <GuidedCareTemplatePublish templateCode={template.code} />}
            </Box>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormCard title={t("Template Information")} loading={isLoading} doYouHaveData>
                <GuidedCareTemplateInformationForm template={template} isClone={isClone} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Template Verification")} loading={isLoading} doYouHaveData>
                <GuidedCareTemplateVerificationForm isVerified={isVerified} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateMembersForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateActivitiesForm template={template} isClone={isClone} />
              </FormCard>
            </Grid>

            {healthCondition && (
              <Grid item xs={12}>
                <FormCard loading={isLoading} doYouHaveData>
                  <GuidedCareTemplateHealthParametersForm
                    isUpdateMode={!!template}
                    guidedCareTemplateCode={template?.code!}
                    healthConditionId={healthCondition?.id!}
                  />
                </FormCard>
              </Grid>
            )}

            {!isGuidedCareTypeEnrollment && (
              <>
                <Grid item xs={12}>
                  <FormCard loading={isLoading} doYouHaveData>
                    <GuidedCareTemplateInterventions />
                  </FormCard>
                </Grid>

                <Grid item xs={12}>
                  <FormCard loading={isLoading} doYouHaveData>
                    <GuidedCareTemplateGoals />
                  </FormCard>
                </Grid>
              </>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
