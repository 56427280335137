import { ArticleCategory, ArticleCategoryInput } from "@/schema/types";
import { createArticleCategoryAutocompleteOption, createHealthConditionAutocompleteOption } from "@health/autocompletes";
import { IArticleCategoryUpsertFormValues } from "../forms/ArticleCategoryFormUpsert/ArticleCategoryUpsertFormSchema";

export const convertArticleCategoryToFormValues = (category: ArticleCategory): IArticleCategoryUpsertFormValues => {
  return {
    code: category?.code!,
    display: category?.display!,
    displayAr: category?.displayAr!,
    healthConditions: category?.healthConditions?.length
      ? category?.healthConditions?.map(item => createHealthConditionAutocompleteOption(item!))
      : [],
    parentCategory: category?.parent ? createArticleCategoryAutocompleteOption(category?.parent) : undefined,
    icon: category?.icon!,
  };
};

export const convertArticleCategoryFormValuesToBackEndValues = (values: IArticleCategoryUpsertFormValues): ArticleCategoryInput => {
  return {
    display: values?.display,
    displayAr: values?.displayAr,
    code: values?.code,
    healthConditionIds: values?.healthConditions?.length ? values?.healthConditions?.map(item => item?.value?.id!) : [],
    parentId: values?.parentCategory?.value?.id,
    icon: values?.icon,
  };
};
