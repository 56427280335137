import { HealthSymptom, HealthSymptomOrderField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { healthSymptomsPaths } from "../../constants";
import { useHealthSymptomDeleteMutation, useHealthSymptomListQuery } from "../../gql";
import { useSetHealthSymptomsBreadcrumbs } from "../../hooks";
import { useHealthSymptomListContainerColumns } from "./useHealthSymptomListContainerColumns";

export const HealthSymptomListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(healthSymptomsPaths.create.fullPath);
  };

  const onEditRowClick = (item: HealthSymptom) => {
    navigate(healthSymptomsPaths.update.fullPathWithParams({ healthSymptomId: item?.id }));
  };

  useSetHealthSymptomsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthSymptomList"}
      columns={useHealthSymptomListContainerColumns()}
      query={useHealthSymptomListQuery}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: true,
        onEditRow: onEditRowClick,
      }}
      hasTableSetting
      skipUrlState={false}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: HealthSymptomOrderField.Created,
        },
      }}
      deleteItemProps={{
        name: "Health Symptom",
        entityTypeName: "HealthSymptom",
        useDeleteMutation: useHealthSymptomDeleteMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
