import { FC } from "react";
import { useTitleTextStyle } from "./useTitleTextStyle";
import { Box, Typography, TypographyProps } from "../../base/mui";
import { TruncateTypography } from "../TruncateTypography";

type TitleTextProps = {
  title: string;
  text: string;
  titleProps?: TypographyProps;
  textProps?: TypographyProps;
};

export const TitleText: FC<TitleTextProps> = props => {
  const { title, text, titleProps, textProps } = props;

  const { classes } = useTitleTextStyle();

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title} {...titleProps}>
        {title}:
      </Typography>

      <TruncateTypography text={text || "-"} {...textProps} />
    </Box>
  );
};
