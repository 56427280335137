import { Grid, useAddToast } from "@toolkit/ui";
import { HealthParametersAutocomplete } from "@health/autocompletes";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { FC, useEffect } from "react";
import {
  guidedCareTemplateHealthParameterUpsertFormSchema,
  guidedCareTemplateHealthParameterUpsertFormDefaultValues,
  IGuidedCareTemplateHealthParameterUpsertForm,
} from "./GuidedCareTemplateHealthParameterUpsertFormSchema";
import { IGuidedCareTemplateUpsertFormValues } from "@/pages/Templates/forms/TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useTranslation } from "@toolkit/i18n";
import { HealthParameterCategoryCode } from "@/pages/HealthParameters/types";

export const GuidedCareTemplateHealthParameterUpsertFormApi: {
  submit: () => void;
  getForm: () => UseFormReturn<IGuidedCareTemplateHealthParameterUpsertForm> | null;
} = {
  submit: () => {},
  getForm: () => null,
};

export type IGuidedCareTemplateHealthParameterUpsertFormEvent = {
  type: "CHANGE";
  payload: {
    values: IGuidedCareTemplateHealthParameterUpsertForm;
  };
};

type GuidedCareTemplateHealthParameterUpsertFormProps = {
  healthConditionId: string;
  healthParameters: IGuidedCareTemplateUpsertFormValues["healthParameters"];
  onChange: (event: IGuidedCareTemplateHealthParameterUpsertFormEvent) => void;
};

export const GuidedCareTemplateHealthParameterUpsertForm: FC<GuidedCareTemplateHealthParameterUpsertFormProps> = props => {
  const { healthConditionId, healthParameters, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const { failed } = useAddToast();

  const form = useCustomForm<IGuidedCareTemplateHealthParameterUpsertForm>({
    defaultValues: guidedCareTemplateHealthParameterUpsertFormDefaultValues,
    schema: guidedCareTemplateHealthParameterUpsertFormSchema,
  });

  const onSubmit = (values: IGuidedCareTemplateHealthParameterUpsertForm) => {
    const isHealthParameterAlreadyExist = healthParameters?.find(
      item => item?.healthParameter?.value?.code === values?.healthParameter?.value?.code
    );

    if (isHealthParameterAlreadyExist) {
      return failed(t("Health Parameter Already Exist"));
    }

    onChange({
      type: "CHANGE",
      payload: { values },
    });
  };

  useEffect(() => {
    GuidedCareTemplateHealthParameterUpsertFormApi.submit = form.handleSubmit(onSubmit);
    GuidedCareTemplateHealthParameterUpsertFormApi.getForm = () => form;

    return () => {
      GuidedCareTemplateHealthParameterUpsertFormApi.submit = () => {};
      GuidedCareTemplateHealthParameterUpsertFormApi.getForm = () => null;
    };
  }, [form]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HealthParametersAutocomplete
              name={"healthParameter"}
              filter={{ healthConditionId, excludeCategoryCode: [HealthParameterCategoryCode.OverallRiskParameter], isActive: true }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
