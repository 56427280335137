import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ArticleCategoryHealthConditionFragmentFragmentDoc } from '../../fragments/__generated__/ArticleCategoryHealthCondition';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoryListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ArticleCategoryFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ArticleCategoryListQuery = { __typename?: 'Query', articleCategories?: { __typename?: 'ArticleCategoryCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ArticleCategoryCountableEdge', node: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null, healthConditions?: Array<{ __typename?: 'HealthCondition', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } }> } | null };


export const ArticleCategoryListDocument = gql`
    query ArticleCategoryList($after: String, $before: String, $filter: ArticleCategoryFilterInput, $first: Int, $last: Int) {
  articleCategories(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        ...ArticleCategoryHealthConditionFragment
      }
    }
  }
}
    ${ArticleCategoryHealthConditionFragmentFragmentDoc}`;

/**
 * __useArticleCategoryListQuery__
 *
 * To run a query within a React component, call `useArticleCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useArticleCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>(ArticleCategoryListDocument, options);
      }
export function useArticleCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>(ArticleCategoryListDocument, options);
        }
export function useArticleCategoryListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>(ArticleCategoryListDocument, options);
        }
export type ArticleCategoryListQueryHookResult = ReturnType<typeof useArticleCategoryListQuery>;
export type ArticleCategoryListLazyQueryHookResult = ReturnType<typeof useArticleCategoryListLazyQuery>;
export type ArticleCategoryListSuspenseQueryHookResult = ReturnType<typeof useArticleCategoryListSuspenseQuery>;
export type ArticleCategoryListQueryResult = Apollo.QueryResult<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>;