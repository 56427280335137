import { ArticleCategory } from "@/schema/types";
import { CustomTable, PageWrapper, PlusIcon, StyledButton } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { IArticleCategorySubCategoryListContainerParams } from "../../types";
import { useArticleCategorySubCategoryListQuery } from "../../gql";
import { articlesCategoriesPaths } from "../../constants";
import { useSetArticlesCategoriesBreadcrumbs } from "../../hooks";
import { ArticleCategoryInformation } from "../../components/ArticleCategoryInformation/ArticleCategoryInformation";
import { useArticleCategorySubCategoryListContainerColumns } from "./useArticleCategorySubCategoryListContainerColumns";

export const ArticleCategorySubCategoryListContainer = () => {
  const { t } = useTranslation("gcadmin");

  const navigate = useNavigate();

  const { articleCategoryId } = useParams<IArticleCategorySubCategoryListContainerParams>();

  const { data, loading } = useArticleCategorySubCategoryListQuery({
    variables: {
      id: articleCategoryId!,
    },
    fetchPolicy: "no-cache",
    skip: !articleCategoryId,
  });

  const articleCategory = data?.articleCategory as ArticleCategory;

  const onAddNewItemClick = () => {
    navigate(`${articlesCategoriesPaths.create.fullPath}?parentId=${articleCategoryId}`);
  };

  const onEditRowClick = (item: ArticleCategory) => {
    navigate(articlesCategoriesPaths.update.fullPathWithParams({ articleCategoryId: item?.id }));
  };

  const onCancelClick = () => {
    navigate(-1);
  };

  useSetArticlesCategoriesBreadcrumbs("SUB_CATEGORY_LIST", pickLocalizedValue(articleCategory?.display!, articleCategory?.displayAr!));

  return (
    <PageWrapper
      actions={
        <>
          <StyledButton color={"success"} startIcon={<PlusIcon />} onClick={onAddNewItemClick}>
            {t("Add New")}
          </StyledButton>

          <StyledButton variant={"outlined"} onClick={onCancelClick}>
            {t("Cancel")}
          </StyledButton>
        </>
      }
    >
      <ArticleCategoryInformation articleCategory={articleCategory} isLoading={loading} />

      <CustomTable
        data={articleCategory?.children as ArticleCategory[]}
        columns={useArticleCategorySubCategoryListContainerColumns()}
        isEditVisible
        scrollable
        isLoading={loading}
        hasFooter={false}
        onEditRow={onEditRowClick}
      />
    </PageWrapper>
  );
};
