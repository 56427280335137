import { createPathDefinition } from "@toolkit/core";

export const healthConditionsRoute = `/health-conditions`;

export const healthConditionsPaths = createPathDefinition(healthConditionsRoute, {
  list: "",
  create: "new",
  update: ":healthConditionId",
  clone: ":healthConditionId/clone",
  parameterList: ":healthConditionId/parameters",
  articleCategoryList: ":healthConditionId/article-categories",
  diagnosisList: ":healthConditionId/diagnoses",
});
