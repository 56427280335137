import { HealthCondition, Maybe } from "@/schema/types";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTable, ShowButton } from "@toolkit/ui";
import { useArticleCategoryHealthConditionsColumns } from "./useArticleCategoryHealthConditionsColumns";

type ArticleCategoryHealthConditionsProps = {
  healthConditions?: Maybe<Maybe<HealthCondition>[]>;
};

export const ArticleCategoryHealthConditions: FC<ArticleCategoryHealthConditionsProps> = props => {
  const { healthConditions } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog
      type={"info"}
      button={<ShowButton disabled={!healthConditions || !healthConditions?.length} />}
      title={t("Article Category Health Conditions")}
    >
      <CustomTable
        data={healthConditions as HealthCondition[]}
        columns={useArticleCategoryHealthConditionsColumns()}
        scrollable
        hasFooter={false}
      />
    </CustomDialog>
  );
};
