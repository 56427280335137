import { TeamMemberPosition } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { teamMemberPositionOptionsMap, zodEnumSchema } from "@health/enum-options";
import { uniqueId } from "lodash";
import { z } from "zod";
import { GuidedCareTemplateActivityUpsertFormSchema } from "../TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { guidedCareTemplateGoalUpsertFormSchema } from "../TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";
import { guidedCareTemplateInterventionUpsertFormSchema } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";
import { GuidedCareTemplateMemberUpsertFormSchema } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { guidedCareTemplateHealthParameterUpsertFormSchema } from "../TemplateHealthParameterUpsert/GuidedCareTemplateHealthParameterUpsertFormSchema";

export const GuidedCareTemplateUpsertFormSchema = z.object({
  name: z.string().min(1).max(70),
  guidedCareType: zodEnumSchema.guidedCareType,
  templateDuration: z.coerce.number().min(1),
  isRenewable: z.boolean(),
  isVerified: z.boolean(),
  standardCode: z.string().max(255).nullish(),
  standardName: z.string().max(255).nullish(),
  standardURL: z.string().max(255).nullish(),
  healthCondition: zodSchema.healthCondition.nullish(),
  overAllRiskParameter: zodSchema.healthParameter.nullish(),
  riskFactorTemplate: zodSchema.riskFactorTemplate.nullish(),
  teamMembers: z.array(GuidedCareTemplateMemberUpsertFormSchema),
  activities: z.array(GuidedCareTemplateActivityUpsertFormSchema),
  healthParameters: z.array(guidedCareTemplateHealthParameterUpsertFormSchema),
  interventions: z.array(guidedCareTemplateInterventionUpsertFormSchema),
  goals: z.array(guidedCareTemplateGoalUpsertFormSchema),
});

export type IGuidedCareTemplateUpsertFormValues = z.infer<typeof GuidedCareTemplateUpsertFormSchema>;

export const guidedCareTemplateUpsertFormDefaultValues: Partial<IGuidedCareTemplateUpsertFormValues> = {
  name: undefined,
  guidedCareType: undefined,
  isRenewable: true,
  templateDuration: undefined,
  isVerified: false,
  standardCode: undefined,
  standardName: undefined,
  standardURL: undefined,
  healthCondition: undefined,
  overAllRiskParameter: undefined,
  riskFactorTemplate: undefined,
  teamMembers: [
    {
      uniqueId: uniqueId("member_"),
      position: teamMemberPositionOptionsMap[TeamMemberPosition.TeamLeader],
      specialization: undefined,
      isLicencedHealthProfessional: true,
      isBackupPersonMandatory: true,
      isKeyTeamMember: true,
    },
    {
      uniqueId: uniqueId("member_"),
      position: teamMemberPositionOptionsMap[TeamMemberPosition.TeamAdmin],
      specialization: undefined,
      isLicencedHealthProfessional: true,
      isBackupPersonMandatory: true,
      isKeyTeamMember: true,
    },
  ],
  activities: [],
  healthParameters: [],
  interventions: [],
  goals: [],
};
