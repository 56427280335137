import { i18n } from "@toolkit/i18n";
import { aiAgentsPaths } from "./AIAgentsPaths";

export const agentsBreadcrumb = () => ({
  title: i18n.t("AI Agents", { ns: "gcadmin" }),
  main: {
    id: "ai-agents",
    name: i18n.t("AI Agents", { ns: "gcadmin" }),
    path: aiAgentsPaths.main.fullPath,
  },
  update: (fieldName?: string) => ({
    id: "ai-agents-update",
    name: fieldName || i18n.t("Update", { ns: "gcadmin" }),
  }),
});
