import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { DepartmentsAutocomplete } from "@health/autocompletes";
import { medicalFormCategoryOptions, medicalFormTypeOptions } from "@health/enum-options";

type MedicalFormInformationFormProps = {
  isAdmin: boolean;
};

export const MedicalFormInformationForm: FC<MedicalFormInformationFormProps> = props => {
  const { isAdmin } = props;

  const { t } = useTranslation("domains");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nameAr"} label={t("Arabic Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"code"} label={t("Code")} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"type"} label={t("Type")} options={medicalFormTypeOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"category"} label={t("Category")} options={medicalFormCategoryOptions} />
      </Grid>

      {!isAdmin && (
        <Grid item xs={4}>
          <DepartmentsAutocomplete name={"department"} />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormTextField name={"description"} multiline rows={5} placeholder={t("Description")} label={t("Description")} />
          </Grid>

          <Grid item xs={4}>
            <FormTextField name={"welcomeCard"} multiline rows={5} label={t("Welcome Card")} />
          </Grid>

          <Grid item xs={4}>
            <FormTextField name={"welcomeCardAr"} multiline rows={5} label={t("Welcome Card (Arabic)")} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"active"} label={t("Is Active")} />
      </Grid>
    </Grid>
  );
};
