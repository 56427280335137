import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareProgramActivityItem } from "pages/Programs/types";
import { GuidedCareProgramActivityServicesManage } from "../../components/ProgramActivityServicesManage/GuidedCareProgramActivityServicesManage";
import { GuidedCareProgramActivityItems } from "../../components/ProgramActivityItems/GuidedCareProgramActivityItems";

type GuidedCareProgramActivitiesTableColumnsProps = {
  isFormDisabled?: boolean;
};

export const useGuidedCareProgramActivitiesColumns = (
  props: GuidedCareProgramActivitiesTableColumnsProps
): CustomTableColumnProps<IGuidedCareProgramActivityItem>[] => {
  const { isFormDisabled } = props;

  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "type",
        header: t("Type"),
        accessor: ({ templateActivityType }) => templateActivityType?.label,
      },
      {
        key: "position",
        header: t("Position"),
        accessor: ({ teamMember }) => teamMember?.label,
      },
      {
        key: "numberOfOccurrences",
        header: t("Occurrences"),
        accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${t(frequencyType?.label)}`,
      },
      {
        key: "serviceCode",
        header: t("Services"),
        accessor: ({ templateActivityType, templateActivityId }, index) => {
          return (
            <GuidedCareProgramActivityServicesManage
              activityType={templateActivityType?.value}
              activityIndex={index}
              isReadOnly={isFormDisabled || !!templateActivityId}
            />
          );
        },
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ activityId, templateActivityId }) => (
          <GuidedCareProgramActivityItems activityId={activityId} templateActivityId={templateActivityId} isFormDisabled={isFormDisabled} />
        ),
      },
    ];
  }, [isFormDisabled, t]);
};
