import { MedicalMessage } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { createMedicalMessagesBreadcrumbs } from "pages/MedicalMessages/constants";
import { IMedicalMessageUpsertFormEvent, MedicalMessageUpsertForm } from "pages/MedicalMessages/forms";
import {
  MedicalMessageGetDocument,
  MedicalMessageUpdateMutation,
  useMedicalMessageGetQuery,
  useMedicalMessageUpdateMutation,
} from "pages/MedicalMessages/gql";
import { convertMedicalMessageFormValuesToBackEndValues } from "pages/MedicalMessages/others";
import { IMedicalMessageUpdateContainerParams } from "pages/MedicalMessages/types";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const MedicalMessageUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { medicalMessageId } = useParams<IMedicalMessageUpdateContainerParams>();

  const { data, loading } = useMedicalMessageGetQuery({
    variables: { medicalMessageId: medicalMessageId! },
    skip: !medicalMessageId,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const medicalMessage = data?.medicalMessage as MedicalMessage;

  const [fetchMedicalMessageUpdateMutation, { loading: isSubmitting }] = useMedicalMessageUpdateMutation({
    onCompleted: (mutationData: MedicalMessageUpdateMutation) => {
      const mutationErrors = mutationData?.medicalMessageUpdate?.medicalMessageErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Medical Message updated successfully"));
        navigate(-1);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    refetchQueries: [MedicalMessageGetDocument],
  });

  const onMedicalMessageUpsertFormChange = (event: IMedicalMessageUpsertFormEvent) => {
    const values = convertMedicalMessageFormValuesToBackEndValues(event.payload.values);

    fetchMedicalMessageUpdateMutation({
      variables: {
        medicalMessageUpdateId: medicalMessageId!,
        input: values,
      },
    });
  };

  useEffect(() => {
    if (medicalMessage?.name) {
      const { title, main, field } = createMedicalMessagesBreadcrumbs(t);
      setBreadCrumb({ title, values: [main, field(medicalMessage?.name)] });
    }
  }, [setBreadCrumb, t, medicalMessage?.name]);

  return (
    <MedicalMessageUpsertForm
      medicalMessage={medicalMessage}
      submitButtonLabel={t("Update")}
      isButtonDisabled={isSubmitting}
      isLoading={loading}
      onChange={onMedicalMessageUpsertFormChange}
    />
  );
};
