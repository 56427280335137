import { Button, CircularProgress, Grid, PlusIcon, TitleText, Typography, useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { HealthParameter, Maybe } from "@/schema/types";
import { HealthParameterGetDocument, useHealthParameterRiskParameterCreateMutation } from "../../gql";
import { useHealthParameterRiskParameterStyle } from "./useHealthParameterRiskParameterStyle";

type HealthParameterRiskParameterFormProps = {
  id: string;
  riskParameter?: Maybe<HealthParameter>;
};

export const HealthParameterRiskParameterForm: FC<HealthParameterRiskParameterFormProps> = props => {
  const { id, riskParameter } = props;

  const { t } = useTranslation("gcadmin");

  const { failed } = useAddToast();

  const { classes } = useHealthParameterRiskParameterStyle();

  const [createHealthParameterRiskParameter, { loading }] = useHealthParameterRiskParameterCreateMutation({
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [{ query: HealthParameterGetDocument, variables: { id } }],
  });

  const onCreateClick = () => {
    if (riskParameter) return;

    createHealthParameterRiskParameter({
      variables: { id },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={9}>
            <Typography className={classes.title}> {t("Health Parameter Risk Parameter")} </Typography>
          </Grid>

          {!riskParameter && (
            <Grid xs={4} lg={3}>
              <Button
                fullWidth
                disabled={loading}
                startIcon={loading ? null : <PlusIcon />}
                endIcon={loading ? <CircularProgress color={"inherit"} size={20} /> : null}
                onClick={onCreateClick}
              >
                {t("Create Risk Parameter")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {riskParameter ? (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitleText title={t("Code")} text={riskParameter?.code || "-"} />
            </Grid>

            <Grid item xs={4}>
              <TitleText title={t("Display")} text={riskParameter?.display || "-"} />
            </Grid>

            <Grid item xs={4}>
              <TitleText title={t("Arabic Display")} text={riskParameter?.arabicDisplay || "-"} />
            </Grid>
          </Grid>
        ) : (
          <Typography> {t("This Health Parameter does not have Risk Parameter")} </Typography>
        )}
      </Grid>
    </Grid>
  );
};
