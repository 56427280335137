import { TemplateActivityEventItem, TemplateActivityEventItemSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter, guidedCareActivityTypeOptionsMap, teamMemberPositionOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useGuidedCareTemplateActivitiesItemListColumns = (): CustomTableColumnProps<TemplateActivityEventItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "position",
        header: t("Position"),
        accessor: ({ templateActivity }) => teamMemberPositionOptionsMap[templateActivity?.templateTeamMember?.position!]?.label,
        type: "string",
        filter: getAutocompleteEnumFilter("TeamMemberPosition", "position"),
      },
      {
        key: "activityType",
        header: t("Activity Type"),
        accessor: ({ templateActivity }) => guidedCareActivityTypeOptionsMap[templateActivity?.templateActivityType!]?.label,
        type: "string",
        filter: getAutocompleteEnumFilter("GuidedCareActivityType", "templateActivityType", { multiple: true }),
      },
      {
        key: "offsetInDays",
        header: t("Offset (Days)"),
        accessor: "offsetInDays",
        type: "string",
        sort: {
          columnEnum: TemplateActivityEventItemSortingField.Offset,
        },
      },
      {
        key: "medicalMessageName",
        header: t("Name"),
        accessor: ({ templateActivity }) => templateActivity?.medicalMessage?.name,
        type: "string",
      },
      {
        key: "fromOffset",
        header: t("From Offset (Days)"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "offsetGreaterThanOrEquals",
        },
      },
      {
        key: "toOffset",
        header: t("To Offset (Days)"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "offsetLessThanOrEquals",
        },
      },
    ];
  }, [t]);
};
