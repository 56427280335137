import { createPathDefinition } from "@toolkit/core";

export const articlesCategoriesRoute = "/knowledge-base/article-categories";

export const articlesCategoriesPaths = createPathDefinition(articlesCategoriesRoute, {
  list: "",
  create: "new",
  update: ":articleCategoryId",
  subCategoryList: ":articleCategoryId/sub-categories",
});
