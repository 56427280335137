import { PermissionEnum } from "@/schema/types";
import { hasAnyPermission, hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { ArticleCreateContainer, ArticleListContainer, ArticleUpdateContainer } from "../containers";
import { articlesPaths, articlesRoute } from "./ArticlesPaths";

export const articlesRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  return {
    id: "knowledge-base-articles-routes",
    text: i18n.t("Articles", { ns: "gcadmin" }),
    route: articlesRoute,
    hidden: !hasAnyPermission([PermissionEnum.ManageArticles, PermissionEnum.ViewArticles]),
    isProhibited: !hasAnyPermission([PermissionEnum.ManageArticles, PermissionEnum.ViewArticles]),
    subItems: [
      {
        id: "article-list-route",
        text: i18n.t("Articles", { ns: "gcadmin" }),
        route: articlesPaths.list.route,
        fullPath: articlesPaths.list.fullPath,
        element: <ArticleListContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "article-create-route",
        route: articlesPaths.create.route,
        fullPath: articlesPaths.create.fullPath,
        hidden: true,
        isProhibited: !hasPermission(PermissionEnum.ManageArticles),
        element: <ArticleCreateContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "article-update-route",
        text: i18n.t("Update", { ns: "gcadmin" }),
        route: articlesPaths.update.route,
        fullPath: articlesPaths.update.fullPath,
        hidden: true,
        isProhibited: !hasPermission(PermissionEnum.ManageArticles),
        element: <ArticleUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
