import { PermissionEnum } from "@/schema/types";
import { hasAnyPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { MedicationIcon } from "@toolkit/ui";
import { articlesRoutes } from "pages/Articles/constants";
import { articlesCategoriesRoutes } from "pages/ArticlesCategories/constants";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";

export const knowledgeBaseRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  return {
    id: "knowledge-base-routes",
    text: i18n.t("Knowledge Base", { ns: "gcadmin" }),
    icon: <MedicationIcon />,
    isProhibited: !hasAnyPermission([PermissionEnum.ManageArticles, PermissionEnum.ViewArticles]),
    hidden: !hasAnyPermission([PermissionEnum.ManageArticles, PermissionEnum.ViewArticles]),
    subItems: [articlesCategoriesRoutes({ navigate }), articlesRoutes({ navigate })],
  };
};
