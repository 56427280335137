/* eslint-disable react/prop-types */
import { AppTypes } from "@/schema/types";
import { UsersAutocomplete } from "@health/autocompletes";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, createAutocompleteOption, CustomDialog, IconButton, ShareIcon, Tooltip, useAddToast } from "@toolkit/ui";
import { difference } from "lodash";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useChatFlowAddContributorsMutation, useChatFlowRemoveContributorsMutation } from "../gql";
import { AgentsQueryNode } from "../types";

type ShareButtonsProps = {
  agentId: string;
  users: AgentsQueryNode["contributors"];
};
export const ShareButtons: React.FC<ShareButtonsProps> = ({ agentId, users }) => {
  const { open, handleOpen, handleClose } = useOpenState();
  const { t } = useTranslation("domains");
  const form = useForm({ mode: "onChange" });
  const { setValue, handleSubmit } = form;
  const { succeeded, failed } = useAddToast();
  const [deleteContributorsToChatFlow, { loading: deleteLoading }] = useChatFlowRemoveContributorsMutation({
    onCompleted: () => {
      succeeded("Contributors shared successfully");
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const [addContributorsToChatFlow, { loading }] = useChatFlowAddContributorsMutation({
    onCompleted: () => {
      deleteContributorsToChatFlow({
        variables: {
          chatFlowId: agentId,
          contributors: [],
        },
      });
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  useEffect(() => {
    setValue("users", users?.length ? users?.map(user => createAutocompleteOption(user, "id", "fullName")) : undefined);
  }, [users, setValue]);
  const onSubmit = values => {
    const defaultUser = users?.map(user => user?.id) || [];
    const formUser = values?.users?.map(user => user?.value?.id);
    const newUsers = difference(formUser, defaultUser);
    const deletedUsers = difference(defaultUser, formUser);
    addContributorsToChatFlow({
      variables: {
        chatFlowId: agentId,
        contributors: newUsers,
      },
      onCompleted: () => {
        deletedUsers &&
          deleteContributorsToChatFlow({
            variables: {
              chatFlowId: agentId,
              contributors: deletedUsers,
            },
          });
      },
    });
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomDialog
          type='base'
          open={open}
          onClose={handleClose}
          DialogTitleProps={{
            title: t("Share with users"),
          }}
          button={
            <Tooltip title={t("Share agent with users")}>
              <IconButton onClick={handleOpen}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          }
          DialogActionsProps={{
            children: (
              <Box display='flex' flexDirection='row' gap={0.5}>
                <Button
                  color='primary'
                  variant='contained'
                  disabled={loading || deleteLoading}
                  type='submit'
                  sx={{
                    paddingX: "40px",
                    borderRadius: "100px",
                    height: "40px",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("Share")}
                </Button>
                <Button
                  color='primary'
                  sx={{
                    paddingX: "35px",
                    borderRadius: "100px",
                    height: "40px",
                  }}
                  variant='outlined'
                  onClick={handleClose}
                >
                  {t("Cancel")}
                </Button>
              </Box>
            ),
          }}
        >
          <UsersAutocomplete name={"users"} multiple filter={{ appType: [AppTypes.Vendor] }} />
        </CustomDialog>
      </form>
    </FormProvider>
  );
};
