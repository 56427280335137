import {
  FormAutocomplete,
  FormChipsInput,
  FormNumberField,
  FormSwitch,
  FormTextField,
  Grid,
  mapStringsToAutocompleteOptions,
} from "@toolkit/ui";
import { HealthParameterCategoriesAutocomplete, HealthParameterUnitsAutocomplete, SystemCodeAutocomplete } from "@health/autocompletes";
import { chartTypeOptions, fieldStandardOptions, templateFieldTypeOptions } from "@health/enum-options";
import { CodeSystemCode, FieldStandard, TemplateFieldType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useCustomFormContext } from "@toolkit/core";
import { FC } from "react";
import { IHealthParameterUpsertFormValues } from "../HealthParameterUpsert/HealthParameterUpsertFormSchema";
import { HealthParameterCategoryCode } from "@/pages/HealthParameters/types";

type HealthParameterInformationFormProps = {
  isCategoryOverallRiskParameter: boolean;
  isUpdateMode: boolean;
};

export const HealthParameterInformationForm: FC<HealthParameterInformationFormProps> = props => {
  const { isCategoryOverallRiskParameter, isUpdateMode } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomFormContext<IHealthParameterUpsertFormValues>();

  const { watch } = form;

  const standard = watch("standard")?.value;
  const type = watch("type")?.value;
  const allowedValues = watch("allowedValues");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"code"} label={t("Code")} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"display"} label={t("Display")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"arabicDisplay"} label={t("Arabic Display")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthParameterCategoriesAutocomplete name={"category"} filter={{ excludeCode: HealthParameterCategoryCode.RiskParameter }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthParameterUnitsAutocomplete name={"unit"} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete
          name={"type"}
          label={t("Type")}
          disabled={isUpdateMode || isCategoryOverallRiskParameter}
          options={templateFieldTypeOptions}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"chartType"} label={t("Chart Type")} disabled={isCategoryOverallRiskParameter} options={chartTypeOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"standard"} label={t("Coding Standard")} options={fieldStandardOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SystemCodeAutocomplete
          name={"standardCode"}
          label={t("Standard Code")}
          disabled={!standard}
          filter={{ codeSystemCode: standard === FieldStandard.Cpt ? CodeSystemCode.Cpt : CodeSystemCode.Loinc }}
        />
      </Grid>

      {type === TemplateFieldType.String && (
        <>
          <Grid item xs={12} sm={8}>
            <FormChipsInput disabled={isUpdateMode} name={"allowedValues"} placeholder={t("Allowed Values")} />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormAutocomplete name={"defaultValue"} label={t("Default Value")} options={mapStringsToAutocompleteOptions(allowedValues)} />
          </Grid>
        </>
      )}

      {type === TemplateFieldType.Number && (
        <>
          <Grid item xs={12} sm={6}>
            <FormNumberField name={"minNormalRangeValue"} label={t("Min Value")} isFloatAllowed disabled={isCategoryOverallRiskParameter} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormNumberField name={"maxNormalRangeValue"} label={t("Max Value")} isFloatAllowed disabled={isCategoryOverallRiskParameter} />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <FormTextField name={"referenceRange"} multiline rows={5} label={t("Reference Range")} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormSwitch name={"isActive"} label={t("Is Active")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"isMandatory"} label={t("Is Mandatory")} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormSwitch name={"isVisibleForPatient"} label={t("Is Visible For Patient")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"allowManualEditForPatient"} label={t("Is Manually Entered By Patient")} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
