import { z } from "zod";

export const ioAgentUpsertFormSchema = z.object({
  code: z.string().min(3).max(255),
  display: z.string().min(3).max(255),
  url: z.string().url().min(3).max(255),
});

export type IIOAgentUpsertForm = z.infer<typeof ioAgentUpsertFormSchema>;

export const ioAgentUpsertFormDefaultValues: Partial<IIOAgentUpsertForm> = {
  code: undefined,
  display: undefined,
  url: undefined,
};
