import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon } from "@toolkit/ui";
import { FC, useState } from "react";
import { useHealthParametersDownloadQuery } from "../../gql";
import { useFileDownload } from "@toolkit/core";
import { getEnvVariable } from "@/env";
import { useOidcUserProfile } from "@/shared/hooks";

export const HealthParameterDownload: FC = () => {
  const { t } = useTranslation("gcadmin");
  const [loading, setLoading] = useState<boolean>(false);

  const { refetch } = useHealthParametersDownloadQuery({
    fetchPolicy: "standby",
  });
  const { downloadFile } = useFileDownload();
  const { accessToken } = useOidcUserProfile();
  const handleDownloadFile = async () => {
    setLoading(true);
    const response = await refetch();
    const _link = response?.data?.downloadHealthParameters;
    if (!_link) {
      setLoading(false);
      return;
    }
    const link = `${getEnvVariable("SERVER_URL")}${_link}`;
    downloadFile({
      url: link,
      filename: "HealthParameters.xlsx",
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    });
    setLoading(false);
  };
  return (
    <Button
      disabled={loading}
      onClick={handleDownloadFile}
      sx={{ margin: "10px", paddingInline: 4, borderRadius: "10px", whiteSpace: "nowrap" }}
      startIcon={<ExcelIcon />}
    >
      {t("Download Parameters")}
    </Button>
  );
};
