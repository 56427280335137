import { TeamMemberPosition } from "../../schema/types";
import { teamMemberNonManagementOptions, teamMemberWithSpecializationOptions } from "./TeamMemberPosition";

export const isTeamMemberNonManagement = (teamMember: TeamMemberPosition) => {
  return !!teamMemberNonManagementOptions.find(item => item?.value === teamMember);
};

export const doesTeamMemberHaveSpecialization = (teamMember: TeamMemberPosition) => {
  return !!teamMemberWithSpecializationOptions.find(item => item?.value === teamMember);
};
