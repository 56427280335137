import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterRiskParameterCreateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthParameterRiskParameterCreateMutation = { __typename?: 'Mutation', createRiskParameterFromHealthParameter?: { __typename?: 'HealthParameter', id?: string | null } | null };


export const HealthParameterRiskParameterCreateDocument = gql`
    mutation HealthParameterRiskParameterCreate($id: ID!) {
  createRiskParameterFromHealthParameter(healthParameterId: $id) {
    id
  }
}
    `;
export type HealthParameterRiskParameterCreateMutationFn = Apollo.MutationFunction<HealthParameterRiskParameterCreateMutation, HealthParameterRiskParameterCreateMutationVariables>;

/**
 * __useHealthParameterRiskParameterCreateMutation__
 *
 * To run a mutation, you first call `useHealthParameterRiskParameterCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterRiskParameterCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthParameterRiskParameterCreateMutation, { data, loading, error }] = useHealthParameterRiskParameterCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthParameterRiskParameterCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthParameterRiskParameterCreateMutation, HealthParameterRiskParameterCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthParameterRiskParameterCreateMutation, HealthParameterRiskParameterCreateMutationVariables>(HealthParameterRiskParameterCreateDocument, options);
      }
export type HealthParameterRiskParameterCreateMutationHookResult = ReturnType<typeof useHealthParameterRiskParameterCreateMutation>;
export type HealthParameterRiskParameterCreateMutationResult = Apollo.MutationResult<HealthParameterRiskParameterCreateMutation>;
export type HealthParameterRiskParameterCreateMutationOptions = Apollo.BaseMutationOptions<HealthParameterRiskParameterCreateMutation, HealthParameterRiskParameterCreateMutationVariables>;