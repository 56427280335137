import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useGuidedCareTemplatesAutocompleteQuery } from "./gql";
import { IGuidedCareTemplateAutocomplete } from "./schema";

export const createGuidedCareTemplateAutocompleteOption = (template: IGuidedCareTemplateAutocomplete) => {
  return createAutocompleteOption({ id: template?.id!, code: template?.code!, name: template?.name! }, "id", "name");
};

export const getGuidedCareTemplatesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "healthProgramTemplates",
    query: useGuidedCareTemplatesAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
