import { HealthProgramTemplate } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  GuidedCareTemplateGetDocument,
  GuidedCareTemplateUpdateMutation,
  useGuidedCareTemplateGetQuery,
  useGuidedCareTemplateUpdateMutation,
} from "pages/Templates/gql";
import { useSetGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertTemplateFormValuesToBackEndValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpdateContainerParams, IGuidedCareTemplateUpsertFormEvent } from "pages/Templates/types";
import { useNavigate, useParams } from "react-router-dom";
import { GuidedCareTemplateUpsertForm } from "../../forms/TemplateUpsert/GuidedCareTemplateUpsertForm";

export const GuidedCareTemplateUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { templateId } = useParams<IGuidedCareTemplateUpdateContainerParams>();

  const { data, loading } = useGuidedCareTemplateGetQuery({
    variables: { id: templateId! },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
    skip: !templateId,
  });

  const template = data?.healthProgramTemplate as HealthProgramTemplate;

  const [fetchTemplateUpdateMutation, { loading: isSubmitting }] = useGuidedCareTemplateUpdateMutation({
    onCompleted: (mutationData: GuidedCareTemplateUpdateMutation) => {
      const mutationErrors = mutationData?.healthProgramTemplateUpdate?.healthProgramTemplateErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Template updated successfully"));
        navigate(-1);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    refetchQueries: [GuidedCareTemplateGetDocument],
  });

  const onTemplateUpsertFormChange = (event: IGuidedCareTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertTemplateFormValuesToBackEndValues(event.payload.values, template);

      fetchTemplateUpdateMutation({
        variables: {
          input: {
            ...values,
            id: templateId,
            code: template?.code,
          },
        },
      });
    }
  };

  useSetGuidedCareTemplatesBreadcrumbs("UPDATE", template?.name!);

  return (
    <GuidedCareTemplateUpsertForm
      submitButtonLabel={t("Update")}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      template={template!}
      onChange={onTemplateUpsertFormChange}
    />
  );
};
