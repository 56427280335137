/* eslint-disable max-lines */
import {
  H_OrderDirection,
  TemplateActivityEventItem,
  TemplateActivityEventItemSortingField,
  TemplateActivityEventItemSortingInput,
} from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTable, CustomTableColumnProps, FormActions, Grid, PageWrapper, useAddToast } from "@toolkit/ui";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { GuidedCareTemplateActivitiesItemsFilter } from "../../components/TemplateActivitiesItemsFilter/GuidedCareTemplateActivitiesItemsFilter";
import {
  GuidedCareTemplateActivitiesItemListDocument,
  useGuidedCareTemplateActivitiesItemListQuery,
  useTemplateActivityItemUpdateMutation,
} from "../../gql";
import {
  convertTemplateActivitiesItemsFormValuesToBackEndValues,
  convertTemplateActivitiesItemsToFormValues,
  getTemplateActivitiesItemsModified,
} from "../../others";
import { IGuidedCareTemplateActivitiesItem, IGuidedCareTemplateActivitiesItemsFormPagination } from "../../types";
import { IGuidedCareTemplateActivitiesItemsFilterFormValues } from "../TemplateActivitiesItemsFilter/GuidedCareTemplateActivitiesItemsFilterFormSchema";
import {
  guidedCareTemplateActivitiesItemsFormDefaultValues,
  guidedCareTemplateActivitiesItemsFormSchema,
  IGuidedCareTemplateActivitiesItemsFormValues,
} from "./GuidedCareTemplateActivitiesItemsFormSchema";
import { useGuidedCareTemplateActivitiesItemsColumns } from "./useGuidedCareTemplateActivitiesItemsColumns";

type GuidedCareTemplateActivitiesItemsFormProps = {
  templateId: string;
};

export const GuidedCareTemplateActivitiesItemsForm: FC<GuidedCareTemplateActivitiesItemsFormProps> = props => {
  const { templateId } = props;

  const [pagination, setPagination] = useState<IGuidedCareTemplateActivitiesItemsFormPagination>({
    first: 10,
  });

  const [filter, setFilter] = useState<IGuidedCareTemplateActivitiesItemsFilterFormValues>();

  const [sortBy, setSortBy] = useState<TemplateActivityEventItemSortingInput>({
    field: TemplateActivityEventItemSortingField.Offset,
    direction: H_OrderDirection.Desc,
  });

  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();

  const form = useCustomForm<IGuidedCareTemplateActivitiesItemsFormValues>({
    defaultValues: guidedCareTemplateActivitiesItemsFormDefaultValues,
    schema: guidedCareTemplateActivitiesItemsFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const activitiesItems = watch("activitiesItems");

  const [updateTemplateActivityItem, { loading: isSubmitting }] = useTemplateActivityItemUpdateMutation({
    onCompleted: () => {
      succeeded(t("Items updated successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [GuidedCareTemplateActivitiesItemListDocument],
  });

  const { data, loading } = useGuidedCareTemplateActivitiesItemListQuery({
    variables: {
      ...pagination,
      filter: {
        templateId,
        templateActivityType: filter?.activityType ? [filter?.activityType?.value] : undefined,
        medicalMessageIds: filter?.medicalMessages?.length ? filter?.medicalMessages?.map(item => item?.value?.id) : undefined,
        medicalFromIds: filter?.medicalForms?.length ? filter?.medicalForms?.map(item => item?.value?.id) : undefined,
      },
      sortBy,
    },
    fetchPolicy: "no-cache",
    skip: !templateId,
  });

  const templateActivitiesItems = (data?.TemplateActivityEventItems?.edges?.map(item => item?.node) || []) as TemplateActivityEventItem[];
  const pageInfo = data?.TemplateActivityEventItems?.pageInfo;

  const templateActivitiesItemsModified = getTemplateActivitiesItemsModified(templateActivitiesItems, activitiesItems);
  const isTemplateActivitiesItemsModified = templateActivitiesItemsModified?.length > 0;

  const onSubmit = () => {
    const modifiedActivitiesItems = convertTemplateActivitiesItemsFormValuesToBackEndValues(templateActivitiesItemsModified);

    updateTemplateActivityItem({
      variables: {
        activityItems: modifiedActivitiesItems,
      },
    });
  };

  const handleGoPrevious = useCallback(() => {
    if (isTemplateActivitiesItemsModified) {
      return failed(t("Please save items before going to previous page"));
    }

    setPagination(prev => ({
      first: prev.first,
      after: undefined,
      before: pageInfo?.startCursor!,
    }));
  }, [isTemplateActivitiesItemsModified, pageInfo?.startCursor, t]);

  const handleGoNext = useCallback(() => {
    if (isTemplateActivitiesItemsModified) {
      return failed(t("Please save items before going to next page"));
    }

    setPagination(prev => ({
      first: prev.first,
      after: pageInfo?.endCursor!,
      before: undefined,
    }));
  }, [isTemplateActivitiesItemsModified, pageInfo?.endCursor, t]);

  const handleSort = (column: CustomTableColumnProps<IGuidedCareTemplateActivitiesItem>) => {
    if (!column?.sort || column?.sort?.columnEnum) return;

    setSortBy(prevSort => ({
      field: column?.sort?.columnEnum! as TemplateActivityEventItemSortingField,
      direction:
        prevSort.field === column?.sort?.columnEnum && prevSort.direction === H_OrderDirection.Asc
          ? H_OrderDirection.Desc
          : H_OrderDirection.Asc,
    }));
  };

  const onGuidedCareTemplateActivitiesItemsFilterChange = (values: IGuidedCareTemplateActivitiesItemsFilterFormValues) => {
    setFilter(values);
  };

  useEffect(() => {
    if (templateActivitiesItems?.length) {
      const _activitiesItems = convertTemplateActivitiesItemsToFormValues(templateActivitiesItems);
      setValue("activitiesItems", _activitiesItems);
    } else {
      setValue("activitiesItems", []);
    }
  }, [setValue, templateActivitiesItems?.length]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box display={"flex"}>
              <GuidedCareTemplateActivitiesItemsFilter values={filter} onChange={onGuidedCareTemplateActivitiesItemsFilterChange} />

              <FormActions
                hasCancel
                hasFormButton
                isLoading={isSubmitting}
                formButtonTitle={t("Save")}
                newButtonDisabled={!isTemplateActivitiesItemsModified || isSubmitting}
              />
            </Box>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTable
                data={activitiesItems}
                columns={useGuidedCareTemplateActivitiesItemsColumns()}
                isLoading={loading}
                onSortColumn={handleSort}
                hasPreviousPage={pageInfo?.hasPreviousPage}
                hasNextPage={pageInfo?.hasNextPage}
                onGoToPrevious={handleGoPrevious}
                onGoToNext={handleGoNext}
              />
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
