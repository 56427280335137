import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMedicalFormsAutocompleteQuery } from "./gql";
import { IMedicalFormAutocomplete } from "./schema";

export const createMedicalFormAutocompleteOption = (medicalForm: IMedicalFormAutocomplete) => {
  return createAutocompleteOption(
    {
      id: medicalForm?.id!,
      code: medicalForm?.code!,
      name: medicalForm?.name!,
      nameAr: medicalForm?.nameAr!,
    },
    "id",
    item => `${item?.code} - ${pickLocalizedValue(item?.name!, item?.nameAr!)}`
  );
};

export const getMedicalFormsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "medicalForms",
    query: useMedicalFormsAutocompleteQuery,
    labelBy: item => `${item?.code} - ${pickLocalizedValue(item?.name!, item?.nameAr!)}`,
    backendAccessor: "id",
    filterSearchKey: "search",
  });
};
