import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMedicalMessagesAutocompleteQuery } from "./gql";
import { IMedicalMessageAutocomplete } from "./schema";

export const createMedicalMessageAutocompleteOption = (medicalMessage: IMedicalMessageAutocomplete) => {
  return createAutocompleteOption(
    {
      id: medicalMessage?.id!,
      code: medicalMessage?.code!,
      name: medicalMessage?.name!,
    },
    "id",
    item => `${item?.code} - ${item?.name}`
  );
};

export const getMedicalMessagesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "medicalMessages",
    query: useMedicalMessagesAutocompleteQuery,
    labelBy: item => `${item?.code} - ${item?.name}`,
    backendAccessor: "id",
    filterSearchKey: "search",
  });
};
