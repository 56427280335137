import { ArticleCategory } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { useMemo } from "react";
import { ArticleCategoryHealthConditions } from "@/pages/ArticlesCategories/components/ArticleCategoryHealthConditions/ArticleCategoryHealthConditions";
import { getAutocompleteEnumFilter } from "@health/enum-options";

export const useHealthConditionArticleCategoryListContainerColumns = (): CustomTableColumnProps<ArticleCategory>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(
    () => [
      {
        key: "icon",
        header: t("Icon"),
        accessor: ({ icon }) => <GridImageModal image={icon!} title={t("Icon")} altText={t("Icon")} />,
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ display, displayAr }) => pickLocalizedValue(display!, displayAr!),
        filter: {
          type: "string",
          name: "display",
        },
      },
      {
        key: "parent",
        header: t("Parent"),
        type: "truncated-text",
        accessor: ({ parent }) => pickLocalizedValue(parent?.display!, parent?.displayAr!),
      },
      {
        key: "healthConditions",
        header: t("Health Conditions"),
        accessor: ({ healthConditions }) => <ArticleCategoryHealthConditions healthConditions={healthConditions} />,
      },
      {
        key: "type",
        header: t("Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("ArticleCategoryType", "type"),
      },
    ],
    [t]
  );
};
