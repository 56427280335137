import { Article, ArticleInput } from "@/schema/types";
import { createArticleAutocompleteOption, createArticleCategoryAutocompleteOption } from "@health/autocompletes";
import { IArticleUpsertFormValues } from "../forms/ArticleUpsert/ArticleUpsertFormSchema";

export const convertArticleToFormValues = (article: Article): IArticleUpsertFormValues => {
  return {
    title: article.title!,
    titleAr: article.titleAr!,
    categories: article?.category?.length ? article?.category?.map(item => createArticleCategoryAutocompleteOption(item!)) : [],
    readTimeMinutes: article.readTimeMinutes!,
    tags: article?.tags?.length ? (article?.tags as string[]) : [],
    relatedArticles: article.relatedArticles?.length ? article?.relatedArticles?.map(item => createArticleAutocompleteOption(item!)) : [],
    active: article.active!,
    description: article.description!,
    descriptionAr: article.descriptionAr!,
    references: article.references?.length ? (article.references as string[]) : [],
    content: article.content!,
    contentAr: article.contentAr!,
    headerImage: article.headerImage!,
    priority: article.priority!,
  };
};

export const convertArticleFormValuesToBackEndValues = (values: IArticleUpsertFormValues): ArticleInput => {
  return {
    title: values?.title!,
    titleAr: values?.titleAr!,
    categoryId: values?.categories?.map(item => item?.value?.id),
    readTimeMinutes: values?.readTimeMinutes!,
    tags: values?.tags!,
    relatedArticles: values?.relatedArticles!.map(article => article.value.id),
    active: values?.active!,
    references: values?.references! || [],
    description: values?.description!,
    descriptionAr: values?.descriptionAr!,
    content: values?.content!,
    contentAr: values?.contentAr!,
    headerImage: values?.headerImage!,
    priority: values?.priority!,
  };
};
