/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { ArticleCategory, ArticleCategoryType } from "@/schema/types";
import {
  ArticleCategoriesAutocomplete,
  createArticleCategoryAutocompleteOption,
  createHealthConditionAutocompleteOption,
  HealthConditionsAutocomplete,
} from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormHelperText, FormTextField, Grid, ImageUploadController, PageWrapper, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useOidcUserProfile } from "@/shared/hooks";
import { useArticleCategoryParentGetQuery, useArticleCategoryHealthConditionGetQuery } from "@/pages/ArticlesCategories/gql";
import { convertArticleCategoryToFormValues } from "../../utils";
import {
  articleCategoryUpsertFormSchema,
  articleCategoryUpsertFormSchemaDefaultValues,
  IArticleCategoryUpsertFormValues,
} from "./ArticleCategoryUpsertFormSchema";

export type IArticleCategoryUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IArticleCategoryUpsertFormValues;
  };
};

type ArticleCategoryUpsertFormProps = {
  articleCategory?: ArticleCategory;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IArticleCategoryUpsertFormEvent) => void;
};

export const ArticleCategoryUpsertForm: FC<ArticleCategoryUpsertFormProps> = props => {
  const { articleCategory, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const theme = useTheme();

  const { accessToken } = useOidcUserProfile();

  const [params] = useSearchParams();
  const parentId = params?.get("parentId");
  const healthConditionId = params?.get("healthConditionId");

  const form = useCustomForm<IArticleCategoryUpsertFormValues>({
    defaultValues: articleCategoryUpsertFormSchemaDefaultValues,
    schema: articleCategoryUpsertFormSchema,
  });

  const {
    watch,
    setValue,
    setValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const parentCategory = watch("parentCategory");

  const doesCategoryHaveChildren = !!articleCategory?.children?.length;

  const { data: articleCategoryParentData, loading: isArticleCategoryParentLoading } = useArticleCategoryParentGetQuery({
    variables: {
      id: parentId!,
    },
    skip: !parentId || !!articleCategory,
  });

  const articleCategoryFromParams = articleCategoryParentData?.articleCategory;

  const { data: articleCategoryHealthConditionData, loading: isArticleCategoryHealthConditionLoading } =
    useArticleCategoryHealthConditionGetQuery({
      variables: {
        id: healthConditionId!,
      },
      skip: !healthConditionId || !!articleCategory,
    });

  const healthConditionFromParams = articleCategoryHealthConditionData?.getHealthCondition;

  const loading = isLoading || isArticleCategoryParentLoading || isArticleCategoryHealthConditionLoading;

  const onSubmit = (values: IArticleCategoryUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (articleCategory) {
      const _articleCategory = convertArticleCategoryToFormValues(articleCategory);
      setValues(_articleCategory);
    } else if (parentId && articleCategoryFromParams) {
      setValue("parentCategory", createArticleCategoryAutocompleteOption(articleCategoryFromParams));
    } else if (healthConditionId && healthConditionFromParams) {
      setValue("healthConditions", [createHealthConditionAutocompleteOption(healthConditionFromParams)]);
    }
  }, [articleCategory, articleCategoryFromParams, healthConditionFromParams, healthConditionId, parentId, setValue, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={loading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={loading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Article Category Information")} loading={loading} doYouHaveData>
                <Grid container spacing={formGirdSpacing}>
                  <Grid item {...formGirdBreakPoints}>
                    <FormTextField name={"display"} label={t("Display")} />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <FormTextField name={"displayAr"} label={t("Arabic display")} />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <FormTextField name={"code"} label={t("Code")} />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <HealthConditionsAutocomplete name={"healthConditions"} multiple filter={{ isActive: true }} />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <ArticleCategoriesAutocomplete
                      name={"parentCategory"}
                      label={t("Parent Category")}
                      disabled={doesCategoryHaveChildren}
                      filter={{ type: ArticleCategoryType.Parent }}
                    />

                    <FormHelperText sx={{ color: theme.palette.primary.main }}>
                      {doesCategoryHaveChildren
                        ? t("This category is a parent")
                        : !parentCategory
                        ? t("If no category is selected, this will be set as Parent Category")
                        : ""}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Article Category Icon")} loading={loading} doYouHaveData>
                <ImageUploadController
                  control={form.control}
                  name={"icon"}
                  label={t("Icon")}
                  validationRation
                  error={!!errors?.icon}
                  helperText={errors?.icon?.message}
                  token={accessToken!}
                />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
