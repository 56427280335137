import { ChatFlow } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertAgentToFormValues } from "../../utils";
import { AgentInformationForm } from "../AgentFromInformation";
import { ConditionsAndRulesFormInformation } from "../ConditionsAndRulesFormInformation";
import { KnowledgeBaseForm } from "../KnowledgeBaseForm";
import { AgentUpsertFormSchema, agentUpsertFormSchemaDefaultValues, IAgentUpsertFormValues } from "./AgentUpsertFormSchema";

type AgentUpsertFormProps = {
  agent?: ChatFlow;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: IAgentUpsertFormValues) => void;
  isAdmin: boolean;
};

export const AgentUpsertForm: FC<AgentUpsertFormProps> = props => {
  const { agent, isLoading, isSubmitting, submitButtonLabel, isAdmin, onChange } = props;

  const form = useCustomForm<IAgentUpsertFormValues>({
    schema: AgentUpsertFormSchema,
    defaultValues: agentUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const { handleSubmit, setValues } = form;
  const { t } = useTranslation("domains");
  const isButtonDisabled = isLoading || isSubmitting;

  const onSubmit = (values: IAgentUpsertFormValues) => {
    onChange(values);
  };

  useEffect(() => {
    if (agent) {
      const _brand = convertAgentToFormValues(agent);
      setValues(_brand);
    }
  }, [agent, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={isLoading} doYouHaveData>
                <AgentInformationForm isAdmin={isAdmin} />
              </FormCard>
            </Grid>
            <KnowledgeBaseForm />
            <Grid item xs={12}>
              <FormCard title={t("Conditions & Rules")} loading={isLoading} doYouHaveData>
                <ConditionsAndRulesFormInformation />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
