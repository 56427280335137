/* eslint-disable react/prop-types */
import { FlowiseChat } from "@toolkit/ui";
import { domainsConfig } from "../../../domainsConfig";

type PreviewButtonsProps = {
  agentId: string;
  title?: string;
};

export const PreviewButtons: React.FC<PreviewButtonsProps> = ({ title, agentId }) => {
  const apiHost = domainsConfig.get("flowiseBaseUrl");
  return <FlowiseChat title={title} chatFlowId={agentId} apiHost={apiHost} iconButton />;
};
