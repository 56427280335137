import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { ioAgentsPaths } from "../constants";

export const useIOAgentsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", display?: string) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("IO Agents");

  const ioAgentListBreadcrumb = useMemo(() => {
    return {
      name: t("Agents"),
      route: ioAgentsPaths.list.fullPath,
    };
  }, [t]);

  const setIOAgentListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setIOAgentCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [ioAgentListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, ioAgentListBreadcrumb, t]);

  const setIOAgentUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [ioAgentListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, ioAgentListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setIOAgentListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setIOAgentCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setIOAgentUpdateContainerBreadcrumb();
    }
  }, [containerType, setIOAgentListContainerBreadcrumb, setIOAgentCreateContainerBreadcrumb, setIOAgentUpdateContainerBreadcrumb]);
};
