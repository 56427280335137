import { FC } from "react";
import { Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { guidedCareProgramsPaths } from "../../constants";
import { GuidedCareProgramTemplateActivityItemsModal } from "../../modals/ProgramTemplateActivityItems/GuidedCareProgramTemplateActivityItemsModal";

type GuidedCareProgramActivityItemsProps = {
  activityId?: string;
  templateActivityId?: string;
  isFormDisabled?: boolean;
};

export const GuidedCareProgramActivityItems: FC<GuidedCareProgramActivityItemsProps> = props => {
  const { activityId, templateActivityId, isFormDisabled } = props;

  const { t } = useTranslation("gcadmin");

  const navigate = useNavigate();

  const onManageClick = () => {
    if (!activityId || isFormDisabled) return;

    navigate(guidedCareProgramsPaths.activityItemList.fullPathWithParams({ activityId }));
  };

  return activityId ? (
    <Button disabled={isFormDisabled} onClick={onManageClick}>
      {t("Manage")}
    </Button>
  ) : (
    <>
      {templateActivityId ? (
        <GuidedCareProgramTemplateActivityItemsModal templateActivityId={templateActivityId} />
      ) : (
        <Button disabled>{t("Manage")}</Button>
      )}
    </>
  );
};
