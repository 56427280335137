import { UserFilterInput } from "@/schema/types";
import { IAutocompleteFilter, createAutocompleteOption, getAutocompleteFilter } from "@toolkit/ui";
import { useUsersEmailsAutocompleteQuery } from "./gql";
import { IUserEmailAutocomplete } from "./schema";

type IUsersEmailsAutocompleteFilter = IAutocompleteFilter & {
  filter?: UserFilterInput;
};

export const createUserEmailAutocompleteOption = (user: IUserEmailAutocomplete) => {
  return createAutocompleteOption({ id: user?.id!, email: user?.email! }, "id", "email");
};

export const getUsersEmailsAutocompleteFilter = ({ name, multiple, filter }: IUsersEmailsAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "users",
    query: useUsersEmailsAutocompleteQuery,
    filterSearchKey: "search",
    labelBy: "email",
    backendAccessor: "id",
    filtersForQuery: filter,
  });
};
