/* eslint-disable max-lines */

import { FC } from "react";
import { UploadModal } from "./UploadModal";
import { CustomTable, FormCard, Grid } from "@toolkit/ui";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useChatFlowDocsQuery } from "@/pages/AIAgents/gql";
import { useParams } from "react-router-dom";
import { IAgentUpdateContainerParams } from "@/pages/AIAgents/types";
import { useChatFlowDocsColumns } from "./useChatFlowDocsColumns";

export const KnowledgeBaseForm: FC = () => {
  const { t } = useTranslation("domains");
  const { agentId } = useParams<IAgentUpdateContainerParams>();
  const columns = useChatFlowDocsColumns();
  const {
    data,
    loading: isLoading,
    refetch,
  } = useChatFlowDocsQuery({
    variables: {
      chatFlowId: agentId!,
    },
  });
  const documents = data?.chatFlow?.documents || [];
  return (
    <Grid item xs={12}>
      <FormCard title={t("Knowledge Base")} loading={isLoading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={12}>
            <UploadModal refetch={refetch} />
          </Grid>
        </Grid>
        <Grid container spacing={formGirdSpacing} marginTop={2}>
          <Grid item xs={12}>
            <CustomTable
              TableContainerProps={{
                sx: {
                  height: 298,
                },
              }}
              columns={columns}
              data={documents}
              withoutDataMessage={t("No Knowledge Base Documents")}
              emptyIconHeight={70}
              iconBoxProps={{
                marginBottom: "15px",
                height: "70px",
              }}
              pageSize={10}
              isLoading={isLoading}
              pageIndex={10}
              pagesCount={10}
            />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
