import { GuidedCareHealthProgram } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { guidedCareProgramsPaths } from "pages/Programs/constants";
import { useGuidedCareProgramListQuery } from "pages/Programs/gql";
import { useGuidedCareProgramsBreadcrumbs } from "pages/Programs/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGuidedCareProgramListColumns } from "./useGuidedCareProgramListColumns";

export const GuidedCareProgramListContainer = () => {
  const navigate = useNavigate();

  const { setGuidedCareProgramListContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const onAddNewItemClick = () => {
    navigate(guidedCareProgramsPaths.create.fullPath);
  };

  const onEditRowClick = (item: GuidedCareHealthProgram) => {
    navigate(guidedCareProgramsPaths.update.fullPathWithParams({ programId: item?.id }));
  };

  useEffect(() => {
    setGuidedCareProgramListContainerBreadcrumb();
  }, [setGuidedCareProgramListContainerBreadcrumb]);

  return (
    <GridProvider
      gridName={"guidedCareProgramList"}
      columns={useGuidedCareProgramListColumns()}
      query={useGuidedCareProgramListQuery}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
