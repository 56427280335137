import { AgentDefinition } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useIOAgentsBreadcrumbs } from "../../hooks";
import { useIoAgentGetQuery, useIoAgentUpdateMutation } from "../../gql";
import { IIOAgentUpdateContainerParams } from "../../types";
import { convertIOAgentFormValuesToBackEndValues } from "../../utils";
import { IOAgentUpsertForm } from "../../forms/IOAgentUpsert/IOAgentUpsertForm";
import { IIOAgentUpsertForm } from "../../forms/IOAgentUpsert/IOAgentUpsertFormSchema";

export const IOAgentUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { ioAgentId } = useParams<IIOAgentUpdateContainerParams>();

  const { data, loading } = useIoAgentGetQuery({
    variables: { id: ioAgentId! },
    skip: !ioAgentId,
    fetchPolicy: "no-cache",
  });

  const ioAgent = data?.getAgentDefinition as AgentDefinition;

  const [updateIoAgent, { loading: isSubmitting }] = useIoAgentUpdateMutation({
    onCompleted: () => {
      succeeded(t("Agent updated successfully"));
      navigate(-1);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onIOAgentUpsertFormChange = (values: IIOAgentUpsertForm) => {
    updateIoAgent({
      variables: {
        id: ioAgentId!,
        input: convertIOAgentFormValuesToBackEndValues(values),
      },
    });
  };

  useIOAgentsBreadcrumbs("UPDATE", ioAgent?.display);

  return (
    <IOAgentUpsertForm
      buttonLabel={t("Update")}
      ioAgent={ioAgent}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onIOAgentUpsertFormChange}
    />
  );
};
