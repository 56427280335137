import { RiskFactorTemplate } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { riskFactorTemplatesPaths } from "../../constants";
import { useRiskFactorTemplateListQuery } from "../../gql";
import { useSetRiskFactorTemplatesBreadcrumbs } from "../../hooks";
import { useRiskFactorTemplateListContainerColumns } from "./useRiskFactorTemplateListContainerColumns";

export const RiskFactorTemplateListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(riskFactorTemplatesPaths.create.fullPath);
  };

  const onEditRowClick = (item: RiskFactorTemplate) => {
    navigate(riskFactorTemplatesPaths.update.fullPathWithParams({ riskFactorTemplateId: item?.id! }));
  };

  useSetRiskFactorTemplatesBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"riskFactorTemplateList"}
      query={useRiskFactorTemplateListQuery}
      columns={useRiskFactorTemplateListContainerColumns()}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
