import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthParametersPaths } from "../constants";

export const useSetHealthParametersBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", display?: string) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Health Parameters");

  const healthParameterListBreadcrumb = useMemo(() => {
    return {
      name: t("Health Parameters"),
      route: healthParametersPaths.list.fullPath,
    };
  }, [t]);

  const setHealthParameterListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthParameterCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthParameterListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthParameterListBreadcrumb, t]);

  const setHealthParameterUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthParameterListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthParameterListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthParameterListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthParameterCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthParameterUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthParameterListContainerBreadcrumb,
    setHealthParameterCreateContainerBreadcrumb,
    setHealthParameterUpdateContainerBreadcrumb,
  ]);
};
