import { useEffect, useState } from "react";
import { Box, CustomDrawer } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { Decision, DecisionPlanCategory } from "@/schema/types";
import { DecisionListContainer, DecisionManageDrawer, DecisionManageDrawerApi, DecisionPlanCode } from "@health/domains";

type IIOAgentDecisionsDrawerData = {
  ioAgentCode: string;
};

type IOpen = (data: IIOAgentDecisionsDrawerData) => void;
type IClose = () => void;

export const IOAgentDecisionsDrawerApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const IOAgentDecisionsDrawer = () => {
  const [data, setData] = useState<IIOAgentDecisionsDrawerData>();
  const [key, setKey] = useState(0);

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const ioAgentCode = data?.ioAgentCode!;

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const onAddNewClick = () => {
    DecisionManageDrawerApi.open({ ioAgentCode });
  };

  const onEditRowClick = (item: Decision) => {
    DecisionManageDrawerApi.open({ decisionId: item?.id!, ioAgentCode });
  };

  const onDecisionManageDrawerChange = () => {
    setKey(prev => prev + 1);
  };

  useEffect(() => {
    IOAgentDecisionsDrawerApi.open = open;
    IOAgentDecisionsDrawerApi.close = handleClose;
  }, []);

  return (
    <>
      <DecisionManageDrawer
        category={DecisionPlanCategory.PersonalizedGuidelines}
        decisionPlanCode={DecisionPlanCode.PersonalizedGuidelines}
        onChange={onDecisionManageDrawerChange}
      />

      <CustomDrawer isOpen={isOpen} onClose={handleClose}>
        <Box sx={{ marginTop: "50px" }}>
          <DecisionListContainer
            key={key}
            category={DecisionPlanCategory.PersonalizedGuidelines}
            filter={{ agentCode: ioAgentCode }}
            onAddNewClick={onAddNewClick}
            onEditRowClick={onEditRowClick}
          />
        </Box>
      </CustomDrawer>
    </>
  );
};
