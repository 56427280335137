/* eslint-disable react/prop-types */
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, IconButton, Tooltip } from "@toolkit/ui";

type EditButtonsProps = {
  handleEditClick: (agentId: string) => void;
  agentId: string;
};
export const EditButtons: React.FC<EditButtonsProps> = ({ handleEditClick, agentId }) => {
  const { t } = useTranslation("domains");
  return (
    <Tooltip title={t("Edit Agent")}>
      <IconButton
        onClick={() => handleEditClick(agentId!)}
        sx={{
          "& svg": {
            width: 30,
            height: 30,
            marginTop: 0.5,
            padding: 0,
          },
        }}
      >
        <CustomIcon icon='edit' />
      </IconButton>
    </Tooltip>
  );
};
