import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IoAgentCreateMutationVariables = Types.Exact<{
  input: Types.AgentDefinitionInput;
}>;


export type IoAgentCreateMutation = { __typename?: 'Mutation', createAgentDefinition?: { __typename?: 'AgentDefinition', id?: string | null } | null };


export const IoAgentCreateDocument = gql`
    mutation IOAgentCreate($input: AgentDefinitionInput!) {
  createAgentDefinition(agentDefinition: $input) {
    id
  }
}
    `;
export type IoAgentCreateMutationFn = Apollo.MutationFunction<IoAgentCreateMutation, IoAgentCreateMutationVariables>;

/**
 * __useIoAgentCreateMutation__
 *
 * To run a mutation, you first call `useIoAgentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIoAgentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ioAgentCreateMutation, { data, loading, error }] = useIoAgentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIoAgentCreateMutation(baseOptions?: Apollo.MutationHookOptions<IoAgentCreateMutation, IoAgentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IoAgentCreateMutation, IoAgentCreateMutationVariables>(IoAgentCreateDocument, options);
      }
export type IoAgentCreateMutationHookResult = ReturnType<typeof useIoAgentCreateMutation>;
export type IoAgentCreateMutationResult = Apollo.MutationResult<IoAgentCreateMutation>;
export type IoAgentCreateMutationOptions = Apollo.BaseMutationOptions<IoAgentCreateMutation, IoAgentCreateMutationVariables>;