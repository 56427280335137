import { AgentDefinition, AgentDefinitionSortingField, DecisionMakerOrderDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useIOAgentsBreadcrumbs } from "../../hooks";
import { ioAgentsPaths } from "../../constants";
import { useIoAgentListQuery } from "../../gql";
import { IOAgentDecisionsDrawer } from "../../components/IOAgentDecisionsDrawer/IOAgentDecisionsDrawer";
import { useIOAgentListContainerColumns } from "./useIOAgentListContainerColumns";

export const IOAgentListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(ioAgentsPaths.create.fullPath);
  };

  const onEditRowClick = (item: AgentDefinition) => {
    navigate(ioAgentsPaths.update.fullPathWithParams({ ioAgentId: item?.id! }));
  };

  useIOAgentsBreadcrumbs("LIST");

  return (
    <>
      <IOAgentDecisionsDrawer />

      <GridProvider
        gridName={"ioAgentList"}
        query={useIoAgentListQuery}
        columns={useIOAgentListContainerColumns()}
        hasTableSetting
        skipUrlState={false}
        tableAction={{
          isEditVisible: true,
          onEditRow: onEditRowClick,
        }}
        variables={{
          sortBy: {
            field: AgentDefinitionSortingField.CreatedDate,
            direction: DecisionMakerOrderDirection.Desc,
          },
        }}
      >
        <PageWrapper
          filters={<FilterGrid />}
          actions={
            <>
              <TableSettingComponent />
              <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
            </>
          }
        >
          <TableGrid />
        </PageWrapper>
      </GridProvider>
    </>
  );
};
