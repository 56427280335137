import DefaultArticleImage from "@/assets/sessionContent.png";
import { PermissionEnum } from "@/schema/types";
import { getMediaLink, hasPermission, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, CustomToggleButton, Grid, ShowButton, Typography } from "@toolkit/ui";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { articlesPaths } from "../../constants";
import { useStyles } from "./ArticlePreviewStyle";

type ArticlePreviewProps = {
  id: string;
  title: string;
  description: string;
  content: string;
  contentAr: string;
  headerImage: string;
  titleAr: string;
};

export const ArticlePreview: FC<ArticlePreviewProps> = props => {
  const { id, title, titleAr, description, content, contentAr, headerImage } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("gcadmin");
  const { classes, theme } = useStyles();
  const [isArabic, setIsArabic] = useState(false);
  const { open, handleOpen, handleClose } = useOpenState();

  const handleChangeLanguage = () => {
    setIsArabic(prev => !prev);
  };

  const onNavigateToEdit = () => {
    navigate(articlesPaths.update.fullPathWithParams({ articleId: id }));
  };
  return (
    <CustomDialog
      type={"base"}
      open={open}
      DialogContentProps={{
        className: classes.dialogContent,
      }}
      button={<ShowButton onClick={handleOpen} />}
      DialogTitleProps={{
        hasCloseButton: false,
        title: t("Article Preview"),
        children: (
          <Box className={classes.dialogBox}>
            <CustomToggleButton checked={!isArabic} color='info' onChange={handleChangeLanguage} className={classes.toggleIconButton} />
            <Typography>{isArabic ? "عربي" : "English"}</Typography>
          </Box>
        ),
      }}
      DialogActionsProps={{
        children: (
          <Box className={classes.dialogBox}>
            {hasPermission(PermissionEnum.ManageArticles) && (
              <Button color='primary' onClick={onNavigateToEdit}>
                {t("Edit")}
              </Button>
            )}
            <Box sx={{ flex: 1 }} />
            <Button color='primary' variant='outlined' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </Box>
        ),
      }}
      title={t("Article Preview")}
    >
      <Grid container className={classes.container}>
        <Grid item xs={6} className={classes.grid}>
          <Typography ml={2} mt={2} fontWeight={theme.typography.fontWeightMedium} fontSize={theme.mixins.fonts.fontSize.md}>
            {t("Description")}
          </Typography>
          <Typography mt={1} ml={2} mr={1}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.content}>
          <Box className={classes.box}>
            <img src={headerImage ? getMediaLink(headerImage) : DefaultArticleImage} alt='Header' className={classes.img} />
            <Box className={classes.boxShadow} />
          </Box>
          <Grid>
            <Typography ml={3} fontWeight={theme.typography.fontWeightMedium} fontSize={theme.mixins.fonts.fontSize.md} mt={3}>
              {isArabic ? titleAr : title}
            </Typography>
            {renderContent(isArabic ? contentAr : content || "")}
          </Grid>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

const renderContent = (text: string) => {
  return text.split("\n").map((line, index) => {
    if (line.startsWith("###")) {
      return null;
    }

    const imageMatch = line.match(/!\[(.*?)\]\((.*?)\)/);
    if (imageMatch) {
      return <img key={index} src={imageMatch[2]} alt={imageMatch[1]} width='100%' />;
    }

    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = line.split(boldRegex);

    return (
      <Typography ml={3} key={index}>
        {parts.map((part, i) =>
          i % 2 === 1 ? (
            <Typography key={i} my={1} fontWeight='bold'>
              {part}
            </Typography>
          ) : (
            part
          )
        )}
      </Typography>
    );
  });
};
