import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const articleUpsertFormSchema = z.object({
  title: z.string().min(3).max(255),
  titleAr: z.string().max(255).nullish(),
  categories: z.array(zodSchema.articleCategory),
  readTimeMinutes: z.number().min(0).nullish(),
  priority: z.number().min(0).nullish(),
  tags: z.array(z.string()),
  relatedArticles: z.array(zodSchema.article),
  active: z.boolean(),
  description: z.string().min(3),
  descriptionAr: z.string().min(3),
  references: z.array(z.string()),
  content: z.string().min(3),
  contentAr: z.string().min(3),
  headerImage: z.string().nullish(),
});

export type IArticleUpsertFormValues = z.infer<typeof articleUpsertFormSchema>;

export const articleUpsertFormSchemaDefaultValues: Partial<IArticleUpsertFormValues> = {
  title: undefined,
  titleAr: undefined,
  categories: [],
  readTimeMinutes: undefined,
  priority: undefined,
  tags: [],
  relatedArticles: [],
  active: false,
  description: undefined,
  descriptionAr: undefined,
  content: undefined,
  contentAr: undefined,
  headerImage: undefined,
  references: [],
};
