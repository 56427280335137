import { formGirdSpacing } from "@toolkit/core";
import { FormTextField, Grid } from "@toolkit/ui";
import { FC } from "react";

export const ConditionsAndRulesFormInformation: FC = () => {
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item xs={12}>
        <FormTextField name={"conditionsAndRules"} multiline minRows={20} />
      </Grid>
    </Grid>
  );
};
