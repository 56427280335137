import { zodSchema } from "@health/autocompletes";
import { z } from "zod";
import { MedicalMessage, MedicalMessageCategory } from "@/schema/types";

export const medicalMessageVariantUpsertFormSchema = (medicalMessage: MedicalMessage) => {
  const isMedicalMessageCategoryMonitoring = medicalMessage?.medicalMessageCategory === MedicalMessageCategory.Monitoring;

  return z.object({
    presentedTitleEnglish: z.string().min(1).max(255),
    presentedTitleArabic: z.string().min(1).max(255),
    bodyEnglish: z.string().min(1).max(255),
    bodyArabic: z.string().min(1).max(255),
    contentURLEnglish: z.string().max(255).optional(),
    contentURLArabic: z.string().max(255).optional(),
    buttonTextEnglish: isMedicalMessageCategoryMonitoring ? z.string().min(1).max(255) : z.string().max(255).optional(),
    buttonTextArabic: isMedicalMessageCategoryMonitoring ? z.string().min(1).max(255) : z.string().max(255).optional(),
    article: zodSchema.article.nullish(),
    videoEnglish: zodSchema.sadaVideo.nullish(),
    videoArabic: zodSchema.sadaVideo.nullish(),
    healthParameter: isMedicalMessageCategoryMonitoring ? zodSchema.healthParameter : zodSchema.healthParameter.nullish(),
    headerImage: z.string().nullish(),
  });
};

export type IMedicalMessageVariantUpsertFormValues = z.infer<ReturnType<typeof medicalMessageVariantUpsertFormSchema>>;

export const medicalMessageVariantUpsertFormDefaultValues: Partial<IMedicalMessageVariantUpsertFormValues> = {
  presentedTitleArabic: undefined,
  presentedTitleEnglish: undefined,
  bodyArabic: undefined,
  bodyEnglish: undefined,
  contentURLArabic: undefined,
  contentURLEnglish: undefined,
  buttonTextArabic: undefined,
  buttonTextEnglish: undefined,
  article: undefined,
  videoArabic: undefined,
  videoEnglish: undefined,
  healthParameter: undefined,
  headerImage: undefined,
};
