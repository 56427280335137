import { AgentDefinition } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps, formatDate } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { IOAgentDecisionsDrawerApi } from "../../components/IOAgentDecisionsDrawer/IOAgentDecisionsDrawer";

export const useIOAgentListContainerColumns = (): CustomTableColumnProps<AgentDefinition>[] => {
  const { t } = useTranslation("gcadmin");

  const onManageClick = useCallback((ioAgentCode: string) => {
    IOAgentDecisionsDrawerApi.open({ ioAgentCode });
  }, []);

  return useMemo(
    () => [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("Display"),
        accessor: "display",
        filter: {
          type: "string",
          name: "display",
        },
      },
      {
        key: "url",
        header: t("Url"),
        accessor: "url",
      },
      {
        key: "rules",
        header: t("Rules"),
        accessor: ({ code }) => <Button onClick={() => onManageClick(code)}>{t("Manage")}</Button>,
      },
      {
        key: "created",
        header: t("Created"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
      },
    ],
    [t]
  );
};
