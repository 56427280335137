import { RiskFactorTemplate, RiskFactorTemplateSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, formatDate } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RiskFactorTemplateActivation } from "../../components/RiskFactorTemplateActivation/RiskFactorTemplateActivation";
import { riskFactorTemplatesPaths } from "../../constants";

export const useRiskFactorTemplateListContainerColumns = (): CustomTableColumnProps<RiskFactorTemplate>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowParametersClick = useCallback(
    (id: string) => {
      navigate(riskFactorTemplatesPaths.parameterList.fullPathWithParams({ riskFactorTemplateId: id }));
    },
    [navigate]
  );

  const onCloneClick = useCallback(
    (id: string) => {
      navigate(riskFactorTemplatesPaths.clone.fullPathWithParams({ riskFactorTemplateId: id }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        type: "truncated-text",
        accessor: "code",
        sort: {
          columnEnum: RiskFactorTemplateSortingField.Code,
        },
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("Display"),
        type: "truncated-text",
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display!, arabicDisplay!),
        sort: {
          columnEnum: pickLocalizedValue(RiskFactorTemplateSortingField.Display, RiskFactorTemplateSortingField.ArabicDisplay),
        },
        filter: {
          type: "string",
          name: pickLocalizedValue("display", "arabicDisplay"),
        },
      },
      {
        key: "arabicDisplay",
        header: t("Arabic Display"),
        filter: {
          type: "string",
          name: "arabicDisplay",
        },
        showOnlyForFilterField: true,
        settings: {
          hideFromSettings: true,
        },
      },
      {
        key: "parameters",
        header: t("Parameters"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowParametersClick(id!),
          tooltipMessage: row => t("show {{name}} parameters", { name: pickLocalizedValue(row.display, row?.arabicDisplay) }),
        },
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon='clone' />} variant={"text"} onClick={() => onCloneClick(id!)}>
            {t("Clone")}
          </Button>
        ),
        type: "string",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        sort: {
          columnEnum: RiskFactorTemplateSortingField.CreatedDate,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <RiskFactorTemplateActivation id={id!} isActive={!!isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [onCloneClick, onShowParametersClick, t]);
};
