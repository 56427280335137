import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IoAgentListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.AgentDefinitionSortingInput>;
  filter?: Types.InputMaybe<Types.AgentDefinitionFilterInput>;
}>;


export type IoAgentListQuery = { __typename?: 'Query', getAgentDefinitions?: { __typename?: 'AgentDefinitionConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'AgentDefinitionEdge', node?: { __typename?: 'AgentDefinition', id?: string | null, code: string, display: string, url: string, createdDate?: string | null } | null } | null> | null } | null };


export const IoAgentListDocument = gql`
    query IOAgentList($first: Int, $last: Int, $after: String, $before: String, $sortBy: AgentDefinitionSortingInput, $filter: AgentDefinitionFilterInput) {
  getAgentDefinitions(
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        code
        display
        url
        createdDate
      }
    }
  }
}
    `;

/**
 * __useIoAgentListQuery__
 *
 * To run a query within a React component, call `useIoAgentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIoAgentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIoAgentListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIoAgentListQuery(baseOptions?: Apollo.QueryHookOptions<IoAgentListQuery, IoAgentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IoAgentListQuery, IoAgentListQueryVariables>(IoAgentListDocument, options);
      }
export function useIoAgentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IoAgentListQuery, IoAgentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IoAgentListQuery, IoAgentListQueryVariables>(IoAgentListDocument, options);
        }
export function useIoAgentListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IoAgentListQuery, IoAgentListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IoAgentListQuery, IoAgentListQueryVariables>(IoAgentListDocument, options);
        }
export type IoAgentListQueryHookResult = ReturnType<typeof useIoAgentListQuery>;
export type IoAgentListLazyQueryHookResult = ReturnType<typeof useIoAgentListLazyQuery>;
export type IoAgentListSuspenseQueryHookResult = ReturnType<typeof useIoAgentListSuspenseQuery>;
export type IoAgentListQueryResult = Apollo.QueryResult<IoAgentListQuery, IoAgentListQueryVariables>;