import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const articleCategoryUpsertFormSchema = z.object({
  display: z.string().min(3).max(255),
  displayAr: z.string().min(3).max(255),
  code: z.string().min(1),
  parentCategory: zodSchema.articleCategory.nullish(),
  healthConditions: z.array(zodSchema.healthCondition),
  icon: z.string(),
});

export type IArticleCategoryUpsertFormValues = z.infer<typeof articleCategoryUpsertFormSchema>;

export const articleCategoryUpsertFormSchemaDefaultValues: Partial<IArticleCategoryUpsertFormValues> = {
  display: undefined,
  displayAr: undefined,
  code: undefined,
  parentCategory: undefined,
  healthConditions: [],
  icon: undefined,
};
