import { ArticleCategory } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { getHealthConditionsAutocompleteFilter } from "@health/autocompletes";
import { useNavigate } from "react-router-dom";
import { articlesCategoriesPaths } from "../../constants";
import { ArticleCategoryHealthConditions } from "../../components/ArticleCategoryHealthConditions/ArticleCategoryHealthConditions";

export const useArticleCategoryListContainerColumns = (): CustomTableColumnProps<ArticleCategory>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowSubCategoriesClick = useCallback(
    (id: string) => {
      navigate(articlesCategoriesPaths.subCategoryList.fullPathWithParams({ articleCategoryId: id }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "icon",
        header: t("Icon"),
        accessor: ({ icon }) => <GridImageModal image={icon!} title={t("Icon")} altText={t("Icon")} />,
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ display, displayAr }) => pickLocalizedValue(display!, displayAr!),
        filter: {
          type: "string",
          name: "display",
        },
      },
      {
        key: "healthConditions",
        header: t("Health Conditions"),
        accessor: ({ healthConditions }) => <ArticleCategoryHealthConditions healthConditions={healthConditions} />,
        filter: getHealthConditionsAutocompleteFilter({ name: "healthConditionIds", multiple: true }),
      },
      {
        key: "subCategories",
        header: t("Sub Categories"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowSubCategoriesClick(id),
          tooltipMessage: row => t("show {{name}} sub categories", { name: pickLocalizedValue(row?.display, row?.displayAr) }),
        },
      },
    ];
  }, [onShowSubCategoriesClick, t]);
};
