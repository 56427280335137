import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { IGuidedCareTemplateHealthParameter } from "../../types";
import { GuidedCareTemplateHealthParameterDecisionsDrawerApi } from "../../components/TemplateHealthParameterDecisionsDrawer/GuidedCareTemplateHealthParameterDecisionsDrawer";

type GuidedCareTemplateHealthParametersColumnsProps = {
  isUpdateMode: boolean;
};

export const useGuidedCareTemplateHealthParametersColumns = (
  props: GuidedCareTemplateHealthParametersColumnsProps
): CustomTableColumnProps<IGuidedCareTemplateHealthParameter>[] => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("gcadmin");

  const onManageClick = useCallback((healthParameterCode: string) => {
    GuidedCareTemplateHealthParameterDecisionsDrawerApi.open({ healthParameterCode });
  }, []);

  return useMemo(
    () => [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ healthParameter }) => healthParameter?.value?.code,
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ healthParameter }) => pickLocalizedValue(healthParameter?.value?.display!, healthParameter?.value?.arabicDisplay!),
      },
      {
        key: "rules",
        header: t("Rules"),
        accessor: ({ healthParameter }) => (
          <Button disabled={!isUpdateMode} onClick={() => onManageClick(healthParameter?.value?.code!)}>
            {t("Manage")}
          </Button>
        ),
      },
    ],
    [isUpdateMode, onManageClick, t]
  );
};
