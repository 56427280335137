import { ArticleCategory } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { useMemo } from "react";

export const useArticleCategoriesColumns = (): CustomTableColumnProps<ArticleCategory>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(
    () => [
      {
        key: "icon",
        header: t("Icon"),
        accessor: ({ icon }) => <GridImageModal image={icon!} title={t("Icon")} altText={t("Icon")} />,
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ display, displayAr }) => pickLocalizedValue(display!, displayAr!),
      },
    ],
    [t]
  );
};
