import { LabTemplate } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { labTemplatesPaths } from "../../constants";
import { useLabTemplateListQuery } from "../../gql";
import { useSetLabTemplatesBreadcrumbs } from "../../hooks";
import { useLabTemplateListContainerColumns } from "./useLabTemplateListContainerColumns";

export const LabTemplateListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(labTemplatesPaths.create.fullPath);
  };

  const onEditRowClick = (item: LabTemplate) => {
    navigate(labTemplatesPaths.update.fullPathWithParams({ labTemplateId: item?.id! }));
  };

  useSetLabTemplatesBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"labTemplateList"}
      query={useLabTemplateListQuery}
      columns={useLabTemplateListContainerColumns()}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
