import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography } from "@toolkit/ui";
import { IGuidedCareTemplateInterventionItem } from "pages/Templates/types";
import { useFieldArray } from "react-hook-form";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateInterventionsColumns } from "./useGuidedCareTemplateInterventionsColumns";
import { useGuidedCareTemplateInterventionsStyle } from "./useGuidedCareTemplateInterventionsStyle";
import {
  GuidedCareTemplateInterventionUpsertModal,
  GuidedCareTemplateInterventionUpsertModalApi,
} from "../../modals/TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertModal";
import { IGuidedCareTemplateInterventionUpsertFormEvent } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertForm";
import { GuidedCareTemplateInterventionActivitiesModal } from "../../modals/TemplateInterventionActivities/GuidedCareTemplateInterventionActivitiesModal";

export const GuidedCareTemplateInterventions = () => {
  const { t } = useTranslation("gcadmin");

  const { classes } = useGuidedCareTemplateInterventionsStyle();

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { fields, append, remove, update } = useFieldArray<IGuidedCareTemplateUpsertFormValues, "interventions">({
    control: form.control,
    name: "interventions",
  });

  const onGuidedCareTemplateInterventionUpsertModalChange = (event: IGuidedCareTemplateInterventionUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
    } else if (event.type === "UPDATE") {
      const index = fields.findIndex(item => item.uniqueId === event.payload.values.uniqueId);
      update(index, event.payload.values);
    }

    GuidedCareTemplateInterventionUpsertModalApi.close();
  };

  const onAddClick = () => {
    GuidedCareTemplateInterventionUpsertModalApi.open();
  };

  const onEditRowClick = (item: IGuidedCareTemplateInterventionItem) => {
    const index = fields?.findIndex(field => field?.uniqueId === item?.uniqueId);
    const intervention = form.getValues("interventions")?.[index];

    GuidedCareTemplateInterventionUpsertModalApi.open({
      intervention,
    });
  };

  const onDeleteRowClick = (item: IGuidedCareTemplateInterventionItem, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateInterventionUpsertModal onChange={onGuidedCareTemplateInterventionUpsertModalChange} />
      <GuidedCareTemplateInterventionActivitiesModal />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={9}>
            <Typography className={classes.title}> {t("Personalized Interventions")} </Typography>
          </Grid>

          <Grid xs={4} lg={3}>
            <Button startIcon={<PlusIcon />} fullWidth onClick={onAddClick}>
              {t("Add Intervention")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateInterventionsColumns()}
          isEditVisible
          isDeleteVisible
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
