import { TFunction } from "@toolkit/i18n";
import { GuidedCareActivityType } from "../../schema/types";
import { guidedCareActivityTypeMedicalMessageOptions } from "./GuidedCareActivityType";

export const isGuidedCareActivityTypeMedicalMessage = (activityType: GuidedCareActivityType) => {
  return !!guidedCareActivityTypeMedicalMessageOptions.find(item => item?.value === activityType);
};

export const guidedCareActivityTypeMapper = (activityType: GuidedCareActivityType, t: TFunction, palette: any) => {
  switch (activityType) {
    case GuidedCareActivityType.HomeVisit:
      return { icon: "home3", title: t("At Home Visit", { ns: "domains" }), viewBox: "1 -1 22 22", color: palette.success.main };
    case GuidedCareActivityType.OnlineVisit:
      return { icon: "video", title: t("Virtual Visit", { ns: "domains" }), viewBox: "-2 -2 25 25", color: palette.success.main };
    case GuidedCareActivityType.FacilityVisit:
      return { icon: "inPerson", title: t("In Person visit", { ns: "domains" }), viewBox: "0 -3 26 26", color: palette.success.main };
    case GuidedCareActivityType.MedicalForm:
      return { icon: "medical-form", title: t("Medical Form", { ns: "domains" }), viewBox: "0 -2 30 30", color: palette.warning.main };
    case GuidedCareActivityType.MedicalMessageInstruction:
    case GuidedCareActivityType.MedicalMessageGeneral:
    case GuidedCareActivityType.MedicalMessageReminder:
    case GuidedCareActivityType.MedicalMessageMedical:
    case GuidedCareActivityType.MedicalMessageMonitoring:
      return { icon: "medical-massage", title: t("Medical Message", { ns: "domains" }), color: palette.warning.main };
    case GuidedCareActivityType.Lab:
      return { icon: "blood", title: t("Lab", { ns: "domains" }), viewBox: "-2 -4 30 30", color: palette.warning.main };
    case GuidedCareActivityType.Rad:
      return { icon: "job", title: t("Rad", { ns: "domains" }), viewBox: "1 0 25 25", color: palette.error.main };
    default:
      return { icon: "pills", title: activityType, color: palette.success.main };
  }
};
