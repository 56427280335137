import { IGuidedCareActivityItemUpdateFormValues } from "@/pages/GuidedCare/forms";
import { createMedicalMessageAutocompleteOption } from "@health/autocompletes";
import { notificationFrequencyOptionsMap, timeOfDayOptionsMap } from "@health/enum-options";
import {
  GuidedCareHealthProgramActivity,
  GuidedCareProgramActivityItem,
  Maybe,
  TemplateActivity,
  TemplateActivityEventItem,
  TemplateActivityEventItemInput,
  TemplateInterventionActivity,
  TemplateInterventionActivityEventItem,
} from "@/schema/types";
import { IGuidedCareTemplateActivityUpsertFormValues } from "@/pages/Templates/forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateInterventionActivityUpsertFormValues } from "@/pages/Templates/forms/TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertFormSchema";
import { isValueModified } from "@toolkit/core";
import { IGuidedCareProgramActivityUpsertFormValues } from "@/pages/Programs/forms/ProgramActivityUpsert/GuidedCareProgramActivityUpsertFormSchema";

export const convertGuidedCareActivityItemsToFormValues = (
  activityItems?: (TemplateActivityEventItem | TemplateInterventionActivityEventItem | GuidedCareProgramActivityItem)[]
): IGuidedCareActivityItemUpdateFormValues[] => {
  return (
    activityItems?.map(item => ({
      id: item?.id!,
      offset: item?.offsetInDays!,
      time: timeOfDayOptionsMap[item?.timeOfDay!],
      notificationFrequency: item?.notificationFrequency ? notificationFrequencyOptionsMap[item?.notificationFrequency] : undefined,
      beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
      beforeActivityOffset: item?.beforeCallMessageOffsetDays! || null,
      afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
      afterActivityOffset: item?.afterCallMessageOffsetDays! || null,
    })) || []
  );
};

export const convertGuidedCareActivityItemsFormValuesToBackEndValues = (
  activityItems: IGuidedCareActivityItemUpdateFormValues[]
): TemplateActivityEventItemInput[] => {
  return activityItems.map(item => ({
    offsetInDays: item.offset,
    timeOfDay: item?.time?.value,
    notificationFrequency: item?.notificationFrequency?.value,
    beforeCallMessageId: item.beforeActivity?.value?.id,
    beforeCallMessageOffsetDays: item.beforeActivityOffset,
    afterCallMessageId: item.afterActivity?.value?.id,
    afterCallMessageOffsetDays: item.afterActivityOffset,
  }));
};

export const getActivityModifiedFields = (
  item:
    | IGuidedCareTemplateActivityUpsertFormValues
    | IGuidedCareTemplateInterventionActivityUpsertFormValues
    | IGuidedCareProgramActivityUpsertFormValues,
  activities?:
    | Maybe<Maybe<TemplateActivity>[]>
    | Maybe<Maybe<GuidedCareHealthProgramActivity>[]>
    | Maybe<Maybe<TemplateInterventionActivity>[]>
) => {
  const existingItem = activities?.find(oldItem => oldItem?.id === item?.activityId);

  if (!existingItem) {
    return {
      isBeforeActivityModified: true,
      isBeforeActivityOffsetModified: true,
      isAfterActivityModified: true,
      isAfterActivityOffsetModified: true,
      isNotificationFrequencyModified: true,
      isTimeOfDayModified: true,
    };
  }

  return {
    isBeforeActivityModified: isValueModified(existingItem?.beforeCallMessage?.id, item?.beforeActivity?.value?.id),
    isBeforeActivityOffsetModified: isValueModified(existingItem?.beforeCallMessageOffsetDays, item?.beforeActivityOffset),
    isAfterActivityModified: isValueModified(existingItem?.afterCallMessage?.id, item?.afterActivity?.value?.id),
    isAfterActivityOffsetModified: isValueModified(existingItem?.afterCallMessageOffsetDays, item?.afterActivityOffset),
    isNotificationFrequencyModified: isValueModified(existingItem?.notificationFrequency, item?.notificationFrequency?.value),
    isTimeOfDayModified: isValueModified(existingItem?.timeOfDay, item?.timeOfDay?.value),
  };
};
