import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import React, { FC } from "react";

export const AgentInformationForm: FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const { t } = useTranslation("domains");

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"code"} label={t("Code")} disabled />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"name"} label={t("Name")} disabled={!isAdmin} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField
          name={"description"}
          label={t("Description")}
          placeholder={t("Agent Name…..")}
          disabled={!isAdmin}
          rows={6}
          multiline
        />
      </Grid>
    </Grid>
  );
};
