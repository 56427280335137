import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IoAgentDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IoAgentDeleteMutation = { __typename?: 'Mutation', deleteAgentDefinition?: { __typename?: 'AgentDefinition', id?: string | null } | null };


export const IoAgentDeleteDocument = gql`
    mutation IOAgentDelete($id: ID!) {
  deleteAgentDefinition(id: $id) {
    id
  }
}
    `;
export type IoAgentDeleteMutationFn = Apollo.MutationFunction<IoAgentDeleteMutation, IoAgentDeleteMutationVariables>;

/**
 * __useIoAgentDeleteMutation__
 *
 * To run a mutation, you first call `useIoAgentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIoAgentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ioAgentDeleteMutation, { data, loading, error }] = useIoAgentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIoAgentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<IoAgentDeleteMutation, IoAgentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IoAgentDeleteMutation, IoAgentDeleteMutationVariables>(IoAgentDeleteDocument, options);
      }
export type IoAgentDeleteMutationHookResult = ReturnType<typeof useIoAgentDeleteMutation>;
export type IoAgentDeleteMutationResult = Apollo.MutationResult<IoAgentDeleteMutation>;
export type IoAgentDeleteMutationOptions = Apollo.BaseMutationOptions<IoAgentDeleteMutation, IoAgentDeleteMutationVariables>;