import { AgentDefinition } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertIOAgentToFormValues } from "../../utils";
import { ioAgentUpsertFormSchema, ioAgentUpsertFormDefaultValues, IIOAgentUpsertForm } from "./IOAgentUpsertFormSchema";

type IOAgentUpsertFormProps = {
  ioAgent?: AgentDefinition;
  isLoading?: boolean;
  isSubmitting?: boolean;
  buttonLabel: string;
  onChange: (values: IIOAgentUpsertForm) => void;
};

export const IOAgentUpsertForm: FC<IOAgentUpsertFormProps> = props => {
  const { ioAgent, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IIOAgentUpsertForm>({
    defaultValues: ioAgentUpsertFormDefaultValues,
    schema: ioAgentUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IIOAgentUpsertForm) => {
    onChange(values);
  };

  useEffect(() => {
    if (ioAgent) {
      const _ioAgent = convertIOAgentToFormValues(ioAgent);
      setValues(_ioAgent);
    }
  }, [ioAgent, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <FormCard title={t("IO Agent Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
              </Grid>

              <Grid item xs={4}>
                <FormTextField name={"display"} label={t("Display")} placeholder={t("Display")} />
              </Grid>

              <Grid item xs={4}>
                <FormTextField name={"url"} label={t("Url")} placeholder={t("Url")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
