import { HealthCondition, HealthConditionInput, HealthConditionUpdateInput } from "@/schema/types";
import { healthConditionTypeOptionsMap } from "@health/enum-options";
import { IHealthConditionUpsertFormValues } from "../forms/HealthConditionUpsert/HealthConditionUpsertFormSchema";

export const convertHealthConditionToFormValues = (
  healthCondition: HealthCondition,
  isClone: boolean
): IHealthConditionUpsertFormValues => {
  return {
    display: healthCondition?.display!,
    arabicDisplay: healthCondition?.arabicDisplay!,
    code: isClone ? "" : healthCondition?.code!,
    umlsCode: healthCondition?.umlsCode!,
    type: healthConditionTypeOptionsMap[healthCondition?.type!],
    disease: healthCondition?.disease!,
    icon: healthCondition?.icon,
    isActive: !!healthCondition?.isActive,
  };
};

export const convertHealthConditionFormValuesToBackEndValues = (values: IHealthConditionUpsertFormValues) => {
  return {
    display: values?.display,
    arabicDisplay: values?.arabicDisplay,
    umlsCode: values?.umlsCode,
    type: values?.type?.value,
    disease: values?.disease,
    icon: values?.icon,
    isActive: values?.isActive,
  };
};

export const convertHealthConditionCreateFormValuesToBackEndValues = (values: IHealthConditionUpsertFormValues): HealthConditionInput => {
  return {
    ...convertHealthConditionFormValuesToBackEndValues(values),
    code: values?.code,
  };
};

export const convertHealthConditionUpdateFormValuesToBackEndValues = (
  values: IHealthConditionUpsertFormValues
): HealthConditionUpdateInput => {
  return convertHealthConditionFormValuesToBackEndValues(values);
};
