import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";

import { AgentUpdateContainer } from "@health/domains";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { agentsBreadcrumb } from "../constants";

export const AdminAgentUpdateContainer: React.FC = () => {
  const { t } = useTranslation("gcadmin");

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  useEffect(() => {
    const { title, main, update } = agentsBreadcrumb();
    setBreadCrumb({ title, values: [main, update()] });
  }, [setBreadCrumb, t]);
  const handleNavigateToList = () => {
    navigate(-1);
  };
  return <AgentUpdateContainer isAdmin handleNavigateToList={handleNavigateToList} />;
};
