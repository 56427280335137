import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useHealthConditionsAutocompleteQuery } from "./gql";
import { IHealthConditionAutocomplete } from "./schema";

export const createHealthConditionAutocompleteOption = (healthCondition: IHealthConditionAutocomplete) => {
  return createAutocompleteOption(
    {
      id: healthCondition?.id!,
      code: healthCondition?.code!,
      display: healthCondition?.display!,
      arabicDisplay: healthCondition?.arabicDisplay!,
    },
    "code",
    item => pickLocalizedValue(item?.display, item?.arabicDisplay)
  );
};

export const getHealthConditionsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "getHealthConditions",
    query: useHealthConditionsAutocompleteQuery,
    labelBy: "display",
    backendAccessor: "id",
    filterSearchKey: "display",
  });
};
