import { MedicalMessageVariant, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import { MedicalMessageInformation } from "pages/MedicalMessages/components";
import { createMedicalMessagesBreadcrumbs, medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { useMedicalMessageGetQuery, useMedicalMessageVariantListQuery } from "pages/MedicalMessages/gql";
import { IMedicalMessageVariantsContainerParams } from "pages/MedicalMessages/types";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMedicalMessageVariantsContainerStyle } from "./useMedicalMessageVariantsContainerStyle";
import { useMedicalMessageVariantsTableColumns } from "./useMedicalMessageVariantsTableColumns";

export const MedicalMessageVariantsContainer = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("gcadmin");

  const { medicalMessageId } = useParams<IMedicalMessageVariantsContainerParams>();

  const { classes } = useMedicalMessageVariantsContainerStyle();

  const { data, loading } = useMedicalMessageGetQuery({
    variables: { medicalMessageId: medicalMessageId! },
    skip: !medicalMessageId,
  });

  const medicalMessage = data?.medicalMessage;

  const onAddNewItemClick = () => {
    navigate(medicalMessagesPaths.variantCreate.fullPathWithParams({ medicalMessageId: medicalMessageId! }));
  };

  const onEditRowClick = (item: MedicalMessageVariant) => {
    navigate(
      medicalMessagesPaths.variantUpdate.fullPathWithParams({ medicalMessageId: medicalMessageId!, medicalMessageVariantId: item?.id })
    );
  };

  useEffect(() => {
    if (medicalMessageId) {
      const { variantsTitle, main } = createMedicalMessagesBreadcrumbs(t);
      setBreadCrumb({ title: variantsTitle, values: [main] });
    }
  }, [setBreadCrumb, t, medicalMessageId]);

  const hasManageHealthMessages = hasPermission(PermissionEnum.ManageHealthMessages);
  return (
    <GridProvider
      gridName='medicalMessageVaraintList'
      columns={useMedicalMessageVariantsTableColumns({ medicalMessageCategory: medicalMessage?.medicalMessageCategory! })}
      query={useMedicalMessageVariantListQuery}
      filterInput={{ medicalMessageId: medicalMessageId! }}
      tableAction={{
        isEditVisible: hasManageHealthMessages,
        isRowEditable: hasManageHealthMessages,
        onEditRow: onEditRowClick,
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={<MainListActions hasAddNew={hasManageHealthMessages} onAddNewItem={onAddNewItemClick} />}
      >
        <MedicalMessageInformation medicalMessage={medicalMessage!} isLoading={loading} />

        <div className={classes.divider} />

        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
