import { HealthSymptomSpecialization } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { InfoItems } from "@toolkit/ui";
import { Maybe } from "@/schema/types";

export const getSpecialtiesInfoItems = (
  specialties?: Array<Maybe<Pick<HealthSymptomSpecialization, "display" | "arabicDisplay" | "code"> | null>> | null
): InfoItems => {
  const specialtiesList = specialties?.map(
    specialty => specialty?.code + " - " + pickLocalizedValue(specialty?.display, specialty?.arabicDisplay)
  );
  return specialties
    ? [
        {
          value: specialtiesList,
          valueDisplayMode: "list",
        },
      ]
    : undefined;
};
