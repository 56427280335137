import { ArticleCategories } from "@/pages/Articles/components/ArticleCategories/ArticleCategories";
import { Article, ArticleCategoryType, ArticleSortingField } from "@/schema/types";
import { getArticleCategoriesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { compact } from "lodash";
import { useMemo } from "react";
import { ArticleActivation } from "../../components/ArticleActivation/ArticleActivation";
import { ArticlePreview } from "../../components/ArticlePreview/ArticlePreview";

export const useArticleListContainerColumns = (): CustomTableColumnProps<Article>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ headerImage }) => <GridImageModal image={headerImage!} title={t("Product Image")} altText={t("Product")} />,
      },
      {
        key: "title",
        header: t("Title"),
        type: "truncated-text",
        accessor: ({ title, titleAr }) => pickLocalizedValue(title!, titleAr!),
        filter: {
          type: "string",
          name: "title",
        },
      },
      {
        key: "readTimeMinutes",
        header: t("Read Time Minutes"),
        accessor: "readTimeMinutes",
      },
      {
        key: "priority",
        header: t("Priority"),
        accessor: "priority",
        sort: {
          columnEnum: ArticleSortingField.Priority,
        },
      },
      {
        key: "categories",
        header: t("Categories"),
        accessor: ({ category }) => <ArticleCategories articleCategories={category} />,
        filter: getArticleCategoriesAutocompleteFilter({ name: "categories", multiple: true, type: ArticleCategoryType.Child }),
      },
      {
        key: "relatedArticles",
        header: t("Related Articles"),
        type: "info",
        infoCellOptions: {
          title: t("Related Articles"),
          items: ({ relatedArticles }) => [
            { value: compact(relatedArticles)?.map(article => pickLocalizedValue(article.title, article.titleAr)) },
          ],
          layout: "one-column",
          valueDisplayMode: "list",
          disableShowButton: ({ relatedArticles }) => !relatedArticles?.length,
          emptyMessage: t("There is No Related Articles"),
        },
      },
      {
        key: "tags",
        header: t("Tags"),
        type: "info",
        infoCellOptions: {
          title: t("Tags"),
          items: ({ tags }) => [{ value: compact(tags) }],
          layout: "one-column",
          valueDisplayMode: "chips",
          disableShowButton: ({ tags }) => !tags?.length,
          emptyMessage: t("There is No Tags"),
        },
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: ({ id, title, description, content, contentAr, headerImage, titleAr }) => (
          <ArticlePreview
            id={id!}
            title={title!}
            titleAr={titleAr!}
            description={description!}
            content={content!}
            contentAr={contentAr!}
            headerImage={headerImage!}
          />
        ),
      },
      {
        key: "activation",
        header: t("Active"),
        accessor: ({ id, active }) => <ArticleActivation id={id} isActive={!!active} />,
        filter: getAutocompleteEnumFilter("YesNo", "active"),
      },
    ];
  }, [t]);
};
