import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IoAgentUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.AgentDefinitionInput;
}>;


export type IoAgentUpdateMutation = { __typename?: 'Mutation', updateAgentDefinition?: { __typename?: 'AgentDefinition', id?: string | null } | null };


export const IoAgentUpdateDocument = gql`
    mutation IOAgentUpdate($id: ID!, $input: AgentDefinitionInput!) {
  updateAgentDefinition(agentDefinition: $input, id: $id) {
    id
  }
}
    `;
export type IoAgentUpdateMutationFn = Apollo.MutationFunction<IoAgentUpdateMutation, IoAgentUpdateMutationVariables>;

/**
 * __useIoAgentUpdateMutation__
 *
 * To run a mutation, you first call `useIoAgentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIoAgentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ioAgentUpdateMutation, { data, loading, error }] = useIoAgentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIoAgentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IoAgentUpdateMutation, IoAgentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IoAgentUpdateMutation, IoAgentUpdateMutationVariables>(IoAgentUpdateDocument, options);
      }
export type IoAgentUpdateMutationHookResult = ReturnType<typeof useIoAgentUpdateMutation>;
export type IoAgentUpdateMutationResult = Apollo.MutationResult<IoAgentUpdateMutation>;
export type IoAgentUpdateMutationOptions = Apollo.BaseMutationOptions<IoAgentUpdateMutation, IoAgentUpdateMutationVariables>;