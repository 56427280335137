import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useRiskFactorTemplatesAutocompleteQuery } from "./gql";
import { IRiskFactorTemplateAutocomplete } from "./schema";

export const createRiskFactorTemplateAutocompleteOption = (riskFactorTemplate: IRiskFactorTemplateAutocomplete) => {
  return createAutocompleteOption(
    {
      id: riskFactorTemplate?.id!,
      code: riskFactorTemplate?.code!,
      display: riskFactorTemplate?.display!,
      arabicDisplay: riskFactorTemplate?.arabicDisplay!,
    },
    "code",
    item => pickLocalizedValue(item?.display, item?.arabicDisplay)
  );
};

export const getRiskFactorTemplatesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "getRiskFactorTemplates",
    query: useRiskFactorTemplatesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.display!, item?.arabicDisplay!),
    backendAccessor: "id",
  });
};
