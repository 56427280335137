import { CodeSystemCode, HealthSymptom, HealthSymptomOrderField } from "@/schema/types";
import { getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { getSpecialtiesInfoItems } from "../../others/cellInfo.utils";

export const useHealthSymptomListContainerColumns = (): CustomTableColumnProps<HealthSymptom>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: "name",
        sort: {
          columnEnum: HealthSymptomOrderField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "specializations",
        header: t("Specializations"),
        type: "info",
        infoCellOptions: {
          title: t("Specializations"),
          items: ({ specializations }) => getSpecialtiesInfoItems(specializations),
          emptyMessage: t("There is No Specialization Information"),
          disableShowButton: ({ specializations }) => isEmpty(specializations),
          layout: "one-column",
        },
        filter: {
          ...getSystemCodeAutocompleteFilter({
            name: "specializations",
            multiple: true,
            isCodeHidden: true,
            queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
          }),
        },
      },
      {
        key: "created",
        header: t("Created"),
        type: "date",
        accessor: "created",
        sort: {
          columnEnum: HealthSymptomOrderField.Created,
        },
      },
    ];
  }, [t]);
};
