import { GuidedCareActivityType } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { isGuidedCareActivityTypeMedicalMessage, zodEnumSchema } from "@health/enum-options";
import { createZodAutocomplete } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { z } from "zod";
import { GuidedCareTemplateActivityServiceUpsertFormSchema } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertFormSchema";
import { guidedCareTemplateInterventionUpsertFormSchema } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";
import { GuidedCareTemplateMemberUpsertFormSchema } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

export const GuidedCareTemplateActivityUpsertFormSchema = z
  .object({
    activityId: z.string().optional(),
    templateActivityType: zodEnumSchema.guidedCareActivityType,
    teamMember: createZodAutocomplete(GuidedCareTemplateMemberUpsertFormSchema),
    numberOfOccurrences: z.coerce.number().min(1),
    medicalForm: zodSchema.medicalFormClassification.nullish(),
    medicalMessage: zodSchema.medicalMessage.nullish(),
    frequencyType: zodEnumSchema.appointmentFrequencyType,
    notificationFrequency: zodEnumSchema.notificationFrequency.nullish(),
    timeOfDay: zodEnumSchema.timeOfDay.nullish(),
    activityServices: z.array(GuidedCareTemplateActivityServiceUpsertFormSchema).optional(),
    beforeActivity: zodSchema.medicalMessage.nullish(),
    medicalFormClassificationsInterventions: z.array(
      z.object({
        id: z.string().nullish(),
        classification: z.string(),
        intervention: createZodAutocomplete(guidedCareTemplateInterventionUpsertFormSchema).nullish(),
      })
    ),
    beforeActivityOffset: z.coerce.number().min(0).nullish(),
    afterActivity: zodSchema.medicalMessage.nullish(),
    afterActivityOffset: z.coerce.number().min(0).nullish(),
  })
  .superRefine((data, ctx) => {
    if (data.templateActivityType?.key === GuidedCareActivityType.MedicalForm && !data.medicalForm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["medicalForm"],
      });
    }

    if (isGuidedCareActivityTypeMedicalMessage(data.templateActivityType?.value) && !data.medicalMessage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["medicalMessage"],
      });
    }

    if (data.beforeActivity && !data.beforeActivityOffset) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["beforeActivityOffset"],
      });
    }

    if (data.beforeActivityOffset && !data.beforeActivity) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["beforeActivity"],
      });
    }

    if (data.afterActivity && !data.afterActivityOffset) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["afterActivityOffset"],
      });
    }

    if (data.afterActivityOffset && !data.afterActivity) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["afterActivity"],
      });
    }
  });

export type IGuidedCareTemplateActivityUpsertFormValues = z.infer<typeof GuidedCareTemplateActivityUpsertFormSchema>;

export const guidedCareTemplateActivityUpsertFormDefaultValues: Partial<IGuidedCareTemplateActivityUpsertFormValues> = {
  templateActivityType: undefined,
  frequencyType: undefined,
  notificationFrequency: undefined,
  timeOfDay: undefined,
  numberOfOccurrences: undefined,
  medicalForm: undefined,
  medicalMessage: undefined,
  activityServices: [],
  medicalFormClassificationsInterventions: [],
  beforeActivity: null,
  beforeActivityOffset: undefined,
  afterActivity: null,
  afterActivityOffset: undefined,
  teamMember: undefined,
};
