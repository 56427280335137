import { HealthParameter, TemplateFieldType } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useHealthParameterGetQuery, useHealthParameterUpdateMutation } from "../../gql";
import { useSetHealthParametersBreadcrumbs } from "../../hooks";
import { convertHealthParameterUpdateFormValuesToBackEndValues } from "../../others";
import { IHealthParameterUpdateContainerParams } from "../../types";
import { HealthParameterDecisionManage } from "../../components/HealthParameterDecisionManage/HealthParameterDecisionManage";
import { HealthParameterUpsertForm, IHealthParameterUpsertFormEvent } from "../../forms/HealthParameterUpsert/HealthParameterUpsertForm";

export const HealthParameterUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { healthParameterId } = useParams<IHealthParameterUpdateContainerParams>();

  const { data, loading } = useHealthParameterGetQuery({
    variables: {
      id: healthParameterId!,
    },
    skip: !healthParameterId,
  });

  const healthParameter = data?.getHealthParameter as HealthParameter;

  const [updateHealthParameter, { loading: isSubmitting }] = useHealthParameterUpdateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.updateHealthParameter?.errors?.length) {
        succeeded(t("Health Parameter updated successfully"));
        navigate(-1);
      } else {
        failed(t("Health Parameter update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthParameterUpsertFormChange = (event: IHealthParameterUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthParameterUpdateFormValuesToBackEndValues(event.payload.values);

      updateHealthParameter({
        variables: {
          id: healthParameterId!,
          input: values,
        },
      });
    }
  };

  useSetHealthParametersBreadcrumbs("UPDATE", pickLocalizedValue(healthParameter?.display!, healthParameter?.arabicDisplay!));

  return (
    <>
      <HealthParameterUpsertForm
        healthParameter={healthParameter}
        submitButtonLabel={t("Update")}
        isLoading={loading}
        isButtonDisabled={isSubmitting}
        onChange={onHealthParameterUpsertFormChange}
      />

      {healthParameter?.type === TemplateFieldType.Number && (
        <Grid container spacing={2} px={5} mb={3}>
          <Grid item xs={12}>
            <FormCard title={t("Default Range Rules")} loading={loading} doYouHaveData>
              <HealthParameterDecisionManage healthParameterCode={healthParameter?.code!} />
            </FormCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};
