import { ArticleCategoryType } from "@/schema/types";
import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useArticleCategoriesAutocompleteQuery } from "./gql";
import { IArticleCategoryAutocomplete } from "./schema";

type ArticleCategoryAutocompleteFilter = IAutocompleteFilter & {
  type?: ArticleCategoryType;
};

export const createArticleCategoryAutocompleteOption = (articleCategory: IArticleCategoryAutocomplete) => {
  return createAutocompleteOption(
    {
      id: articleCategory?.id!,
      code: articleCategory?.code!,
      display: articleCategory?.display!,
      displayAr: articleCategory?.displayAr!,
    },
    "id",
    item => pickLocalizedValue(item?.display, item?.displayAr)
  );
};

export const getArticleCategoriesAutocompleteFilter = ({ name, multiple, type }: ArticleCategoryAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "articleCategories",
    query: useArticleCategoriesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.display!, item?.displayAr!),
    backendAccessor: "id",
    filterSearchKey: "display",
    filtersForQuery: {
      type,
    },
  });
};
