import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useParams } from "react-router-dom";
import { FC } from "react";
import { AgentUpsertForm, IAgentUpsertFormValues } from "../../forms";
import { ChatFlowUpdateMutation, useChatFlowUpdateMutation } from "../../gql";
import { useChatFlowQuery } from "../../gql";
import { IAgentUpdateContainerParams } from "../../types";
import { convertAgentFormValuesToBackEndValues } from "../../utils";

type AgentUpdateContainerProps = {
  isAdmin: boolean;
  handleNavigateToList: () => void;
};

export const AgentUpdateContainer: FC<AgentUpdateContainerProps> = props => {
  const { isAdmin, handleNavigateToList } = props;

  const { t } = useTranslation("domains");
  const { agentId } = useParams<IAgentUpdateContainerParams>();

  const { data, loading } = useChatFlowQuery({
    variables: { chatFlowId: agentId! },
    skip: !agentId,
    fetchPolicy: "no-cache",
  });

  const agent = data?.chatFlow;

  const { succeeded, failed } = useAddToast();

  const [chatFlowUpdateMutation, { loading: isSubmitting }] = useChatFlowUpdateMutation({
    onCompleted: (data: ChatFlowUpdateMutation) => {
      if (data?.chatFlowUpdate?.id) {
        succeeded(t("Agent updated successfully!"));
        handleNavigateToList();
      } else {
        failed(t("Agent update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onAgentUpsertFormChange = (values: IAgentUpsertFormValues) => {
    const _values = convertAgentFormValuesToBackEndValues(values);

    chatFlowUpdateMutation({
      variables: {
        chatFlowUpdateId: agentId!,
        input: _values,
      },
    });
  };

  return (
    <AgentUpsertForm
      isLoading={loading}
      agent={agent!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onAgentUpsertFormChange}
      isAdmin={isAdmin}
    />
  );
};
