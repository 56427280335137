import { HealthParameter } from "@/schema/types";
import { useOidcUserProfile } from "@/shared/hooks";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { HealthParameterInformationForm } from "@/pages/HealthParameters/forms/HealthParameterInformation/HealthParameterInformationForm";
import { HealthParameterRiskParameterForm } from "@/pages/HealthParameters/forms/HealthParameterRiskParameter/HealthParameterRiskParameterForm";
import { convertHealthParameterToFormValues, createHealthParameterCategoryOverallRiskParameterFormValues } from "../../others/utils";
import {
  healthParameterUpsertFormDefaultValues,
  healthParameterUpsertFormSchema,
  IHealthParameterUpsertFormValues,
} from "./HealthParameterUpsertFormSchema";
import { HealthParameterCategoryCode } from "@/pages/HealthParameters/types";

export type IHealthParameterUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthParameterUpsertFormValues;
  };
};

type HealthParameterUpsertFormProps = {
  healthParameter?: HealthParameter;
  submitButtonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IHealthParameterUpsertFormEvent) => void;
};

export const HealthParameterUpsertForm: FC<HealthParameterUpsertFormProps> = props => {
  const { healthParameter, submitButtonLabel, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IHealthParameterUpsertFormValues>({
    defaultValues: healthParameterUpsertFormDefaultValues,
    schema: healthParameterUpsertFormSchema,
  });

  const {
    watch,
    setValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const categoryCode = watch("category")?.value?.code;
  const isCategoryOverallRiskParameter = !!(categoryCode && categoryCode === HealthParameterCategoryCode.OverallRiskParameter);

  const onSubmit = (values: IHealthParameterUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (!healthParameter && isCategoryOverallRiskParameter) {
      const _categoryOverallRiskParameter = createHealthParameterCategoryOverallRiskParameterFormValues();
      setValues(_categoryOverallRiskParameter);
    }
  }, [healthParameter, isCategoryOverallRiskParameter]);

  useEffect(() => {
    if (healthParameter) {
      const _healthParameter = convertHealthParameterToFormValues(healthParameter);
      setValues(_healthParameter);
    }
  }, [healthParameter]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Health Parameter Information")} loading={isLoading} doYouHaveData>
                <HealthParameterInformationForm
                  isCategoryOverallRiskParameter={isCategoryOverallRiskParameter}
                  isUpdateMode={!!healthParameter}
                />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Health Parameter Icon")} loading={isLoading} doYouHaveData>
                <ImageUploadController
                  control={form.control}
                  name={"icon"}
                  label={t("Icon")}
                  validationRation
                  error={!!errors?.icon}
                  helperText={errors?.icon?.message}
                  token={accessToken!}
                />
              </FormCard>
            </Grid>

            {healthParameter && healthParameter?.category?.code !== HealthParameterCategoryCode.OverallRiskParameter && (
              <Grid item xs={12}>
                <FormCard loading={isLoading} doYouHaveData>
                  <HealthParameterRiskParameterForm id={healthParameter?.id!} riskParameter={healthParameter?.riskParameter} />
                </FormCard>
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
