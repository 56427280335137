import { FormAutocomplete, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { healthConditionTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";

type HealthConditionInformationFormProps = {
  isUpdateMode: boolean;
  isClone?: boolean;
};

export const HealthConditionInformationForm: FC<HealthConditionInformationFormProps> = props => {
  const { isUpdateMode, isClone } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"display"} label={t("Display")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"arabicDisplay"} label={t("Arabic Display")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"code"} label={t("Code")} disabled={isUpdateMode && !isClone} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"umlsCode"} label={t("UMLS Code")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"disease"} label={t("Disease")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"type"} label={t("Type")} options={healthConditionTypeOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormSwitch name={"isActive"} label={t("Is Active")} />
      </Grid>
    </Grid>
  );
};
