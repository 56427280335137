import { DecisionPlanCategory } from "@/schema/types";
import { FC, useEffect, useState } from "react";
import { useOpenState } from "@toolkit/core";
import { Box, CustomDrawer } from "@toolkit/ui";
import { DecisionCreate, IDecisionCreateEvent } from "@/pages/Decisions/components/DecisionCreate/DecisionCreate";
import { DecisionUpdate, IDecisionUpdateEvent } from "@/pages/Decisions/components/DecisionUpdate/DecisionUpdate";
import { useDecisionPlanBaseGetQuery } from "../../gql";
import { DecisionPlanCode } from "../../types";

type IDecisionManageDrawerData = {
  decisionId?: string;
  healthParameterCode?: string;
  ioAgentCode?: string;
};

type IOpen = (data: IDecisionManageDrawerData) => void;
type IClose = () => void;

export const DecisionManageDrawerApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type DecisionManageDrawerProps = {
  category: DecisionPlanCategory;
  decisionPlanCode: DecisionPlanCode;
  guidedCareTemplateCode?: string;
  onChange?: () => void;
};

export const DecisionManageDrawer: FC<DecisionManageDrawerProps> = props => {
  const { category, decisionPlanCode, guidedCareTemplateCode, onChange } = props;

  const [data, setData] = useState<IDecisionManageDrawerData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const { data: healthParameterDecisionPlanData } = useDecisionPlanBaseGetQuery({
    variables: {
      filter: {
        planCode: decisionPlanCode,
      },
    },
  });

  const selectedDecisionPlan = healthParameterDecisionPlanData?.getPlans?.edges?.[0]?.node!;

  const onDecisionCreateChange = (event: IDecisionCreateEvent) => {
    if (event.type === "CREATE") {
      DecisionManageDrawerApi.close();
      onChange?.();
    }
  };

  const onDecisionUpdateChange = (event: IDecisionUpdateEvent) => {
    if (event.type === "UPDATE") {
      DecisionManageDrawerApi.close();
      onChange?.();
    }
  };

  useEffect(() => {
    DecisionManageDrawerApi.open = open;
    DecisionManageDrawerApi.close = handleClose;
  }, []);

  return (
    <CustomDrawer isOpen={isOpen} width={"60vw"} onClose={handleClose}>
      <Box sx={{ marginTop: "50px" }}>
        {data?.decisionId ? (
          <DecisionUpdate
            decisionId={data?.decisionId}
            category={category}
            healthParameterCode={data?.healthParameterCode}
            ioAgentCode={data?.ioAgentCode}
            guidedCareTemplateCode={guidedCareTemplateCode}
            onChange={onDecisionUpdateChange}
          />
        ) : (
          <DecisionCreate
            category={category}
            selectedDecisionPlan={selectedDecisionPlan}
            healthParameterCode={data?.healthParameterCode}
            ioAgentCode={data?.ioAgentCode}
            guidedCareTemplateCode={guidedCareTemplateCode}
            onChange={onDecisionCreateChange}
          />
        )}
      </Box>
    </CustomDrawer>
  );
};
