import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useHealthParameterCategoriesAutocompleteQuery } from "./gql";
import { IHealthParameterCategoryAutocomplete } from "./schema";

export const createHealthParameterCategoryAutocompleteOption = (category: IHealthParameterCategoryAutocomplete) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      code: category?.code!,
      display: category?.display!,
      arabicDisplay: category?.arabicDisplay!,
    },
    "id",
    item => pickLocalizedValue(item?.display, item?.arabicDisplay)
  );
};

export const getHealthParameterCategoriesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "getHealthParameterCategories",
    query: useHealthParameterCategoriesAutocompleteQuery,
    filtersForQuery: {},
    labelBy: item => pickLocalizedValue(item?.display!, item?.arabicDisplay!),
    backendAccessor: "id",
    filterSearchKey: "nameContains",
  });
};
