import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthConditionsPaths } from "../constants";

export const useSetHealthConditionsBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "CLONE" | "DIAGNOSIS_LIST" | "PARAMETERS_LIST" | "ARTICLE_CATEGORY_LIST",
  display?: string
) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Health Conditions");

  const healthConditionListBreadcrumb = useMemo(() => {
    return {
      name: t("Health Conditions"),
      route: healthConditionsPaths.list.fullPath,
    };
  }, [t]);

  const setHealthConditionListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthConditionCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthConditionListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthConditionListBreadcrumb, t]);

  const setHealthConditionUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthConditionListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthConditionListBreadcrumb]);

  const setHealthConditionCloneContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthConditionListBreadcrumb, { name: display }, { name: t("Clone") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthConditionListBreadcrumb, t]);

  const setHealthConditionDiagnosisListContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthConditionListBreadcrumb, { name: display }, { name: t("Diagnoses") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthConditionListBreadcrumb, t]);

  const setHealthConditionParameterListContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthConditionListBreadcrumb, { name: display }, { name: t("Parameters") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthConditionListBreadcrumb, t]);

  const setHealthConditionArticleCategoryListContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthConditionListBreadcrumb, { name: display }, { name: t("Article Categories") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthConditionListBreadcrumb, t]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthConditionListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthConditionCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthConditionUpdateContainerBreadcrumb();
    } else if (containerType === "CLONE") {
      setHealthConditionCloneContainerBreadcrumb();
    } else if (containerType === "DIAGNOSIS_LIST") {
      setHealthConditionDiagnosisListContainerBreadcrumb();
    } else if (containerType === "PARAMETERS_LIST") {
      setHealthConditionParameterListContainerBreadcrumb();
    } else if (containerType === "ARTICLE_CATEGORY_LIST") {
      setHealthConditionArticleCategoryListContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthConditionListContainerBreadcrumb,
    setHealthConditionCreateContainerBreadcrumb,
    setHealthConditionUpdateContainerBreadcrumb,
    setHealthConditionCloneContainerBreadcrumb,
    setHealthConditionDiagnosisListContainerBreadcrumb,
    setHealthConditionParameterListContainerBreadcrumb,
    setHealthConditionArticleCategoryListContainerBreadcrumb,
  ]);
};
