import { createAutocompleteOption, getAutocompleteFilter, IAutocompleteFilter } from "@toolkit/ui";
import { useGuidedCareTeamsAutocompleteQuery } from "./gql";
import { IGuidedCareTeamAutocomplete } from "./schema";

export const createGuidedCareTeamAutocompleteOption = (team: IGuidedCareTeamAutocomplete) => {
  return createAutocompleteOption({ id: team?.id!, name: team?.name! }, "id", "name");
};

export const getGuidedCareProgramTeamsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "providerGuidedCareHealthProgramTeams",
    query: useGuidedCareTeamsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
    filterSearchKey: "name",
  });
};
