import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { articlesCategoriesPaths } from "../constants";

export const useSetArticlesCategoriesBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "SUB_CATEGORY_LIST",
  display?: string
) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Articles Categories");

  const articleCategoryListBreadcrumb = useMemo(() => {
    return {
      name: t("Articles Categories"),
      route: articlesCategoriesPaths.list.fullPath,
    };
  }, [t]);

  const setArticleCategoryListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setArticleCategoryCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [articleCategoryListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, articleCategoryListBreadcrumb, t]);

  const setArticleCategoryUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [articleCategoryListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, articleCategoryListBreadcrumb]);

  const setArticleCategorySubCategoryListContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [articleCategoryListBreadcrumb, { name: display }, { name: t("Sub Categories") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, articleCategoryListBreadcrumb, t]);

  useEffect(() => {
    if (containerType === "LIST") {
      setArticleCategoryListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setArticleCategoryCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setArticleCategoryUpdateContainerBreadcrumb();
    } else if (containerType === "SUB_CATEGORY_LIST") {
      setArticleCategorySubCategoryListContainerBreadcrumb();
    }
  }, [
    containerType,
    setArticleCategoryListContainerBreadcrumb,
    setArticleCategoryCreateContainerBreadcrumb,
    setArticleCategoryUpdateContainerBreadcrumb,
    setArticleCategorySubCategoryListContainerBreadcrumb,
  ]);
};
