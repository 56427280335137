import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useHealthParametersAutocompleteQuery } from "./gql";
import { IHealthParameterAutocomplete } from "./schema";

export const createHealthParameterAutocompleteOption = (healthParameter: IHealthParameterAutocomplete) => {
  return createAutocompleteOption(
    {
      id: healthParameter?.id!,
      code: healthParameter?.code!,
      display: healthParameter?.display!,
      arabicDisplay: healthParameter?.arabicDisplay!,
    },
    "code",
    item => pickLocalizedValue(item?.display, item?.arabicDisplay)
  );
};

export const getHealthParametersAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "getHealthParameters",
    query: useHealthParametersAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.display!, item?.arabicDisplay!),
    backendAccessor: "id",
  });
};
