import { MedicalMessageCategory, MedicalMessageType } from "@/schema/types";
import { FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { MedicalMessageTypeForm } from "../MedicalMessageType/MedicalMessageTypeForm";
import { HealthParametersAutocomplete } from "@health/autocompletes";

type MedicalMessageVariantInformationProps = {
  medicalMessageType: MedicalMessageType;
  medicalMessageCategory: MedicalMessageCategory;
};

export const MedicalMessageVariantInformation: FC<MedicalMessageVariantInformationProps> = props => {
  const { medicalMessageType, medicalMessageCategory } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormTextField name={"presentedTitleEnglish"} label={t("Title (English)")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"presentedTitleArabic"} label={t("Title (Arabic)")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"bodyEnglish"} multiline rows={5} label={t("Body (English)")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"bodyArabic"} multiline rows={5} label={t("Body (Arabic)")} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {medicalMessageCategory === MedicalMessageCategory.Monitoring ? (
            <Grid item xs={6}>
              <HealthParametersAutocomplete name={"healthParameter"} filter={{ isActive: true }} />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <MedicalMessageTypeForm medicalMessageType={medicalMessageType!} />
              </Grid>

              <Grid item xs={6}>
                <MedicalMessageTypeForm medicalMessageType={medicalMessageType!} isArabic />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"buttonTextEnglish"} label={t("Button Text (English)")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"buttonTextArabic"} label={t("Button Text (Arabic)")} />
      </Grid>
    </Grid>
  );
};
