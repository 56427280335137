import { GuidedCareType, HealthProgramTemplate } from "@/schema/types";
import { HealthConditionsAutocomplete, HealthParametersAutocomplete, RiskFactorTemplatesAutocomplete } from "@health/autocompletes";
import { guidedCareTypeOptions } from "@health/enum-options";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormHelperText, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { HealthParameterCategoryCode } from "@/pages/HealthParameters/types";

type GuidedCareTemplateInformationFormProps = {
  template?: HealthProgramTemplate;
  isClone?: boolean;
};

export const GuidedCareTemplateInformationForm: FC<GuidedCareTemplateInformationFormProps> = props => {
  const { template, isClone } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { watch, setValue } = form;

  const guidedCareType = watch("guidedCareType")?.value;
  const healthCondition = watch("healthCondition")?.value;
  const templateDuration = watch("templateDuration");

  const isDurationModified = template && !isClone && template?.templateDuration !== templateDuration;

  useEffect(() => {
    if (guidedCareType === GuidedCareType.Enrollment) {
      setValue("isRenewable", false);
    }
  }, [guidedCareType, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"name"} label={t("Name")} disabled={!!template && !isClone} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"guidedCareType"} label={t("Type")} options={guidedCareTypeOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormNumberField name={"templateDuration"} label={t("Duration (Days)")} />

        {isDurationModified && <FormHelperText error> {t("Please be aware that all activities will be regenerated")} </FormHelperText>}
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthConditionsAutocomplete name={"healthCondition"} filter={{ isActive: true }} disabled={!!template?.healthCondition} />
      </Grid>

      {healthCondition && (
        <Grid item xs={12} sm={6} md={4}>
          <HealthParametersAutocomplete
            name={"overAllRiskParameter"}
            label={t("Overall Risk Parameter")}
            filter={{
              healthConditionId: healthCondition?.id,
              categoryCode: [HealthParameterCategoryCode.OverallRiskParameter],
              isActive: true,
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={4}>
        <RiskFactorTemplatesAutocomplete name={"riskFactorTemplate"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"isRenewable"} label={t("Is Renewable")} disabled={guidedCareType === GuidedCareType.Enrollment} />
      </Grid>
    </Grid>
  );
};
