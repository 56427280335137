import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareActivityItemUpdateFormValues } from "../../forms/GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateFormSchema";

export const useGuidedCareActivityItemsFormColumns = (): CustomTableColumnProps<IGuidedCareActivityItemUpdateFormValues>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "offset",
        header: t("Offset (Days)"),
        accessor: "offset",
      },
      {
        key: "time",
        header: t("Time"),
        accessor: ({ time }) => time?.label,
      },
      {
        key: "notificationFrequency",
        header: t("Notification Frequency"),
        accessor: ({ notificationFrequency }) => notificationFrequency?.label,
      },
      {
        key: "beforeActivity",
        header: t("Before Activity"),
        accessor: ({ beforeActivity }) => (beforeActivity ? `${beforeActivity?.value?.code} - ${beforeActivity?.value?.name}` : ""),
      },
      {
        key: "beforeActivityOffset",
        header: t("Before Activity Offset (Days)"),
        accessor: "beforeActivityOffset",
      },
      {
        key: "afterActivity",
        header: t("After Activity"),
        accessor: ({ afterActivity }) => (afterActivity ? `${afterActivity?.value?.code} - ${afterActivity?.value?.name}` : ""),
      },
      {
        key: "afterActivityOffset",
        header: t("After Activity Offset (Days)"),
        accessor: "afterActivityOffset",
      },
    ];
  }, [t]);
};
