import { AgentDefinition, AgentDefinitionInput } from "@/schema/types";
import { IIOAgentUpsertForm } from "../forms/IOAgentUpsert/IOAgentUpsertFormSchema";

export const convertIOAgentToFormValues = (ioAgent: AgentDefinition): IIOAgentUpsertForm => {
  return {
    code: ioAgent.code!,
    display: ioAgent.display!,
    url: ioAgent.url!,
  };
};

export const convertIOAgentFormValuesToBackEndValues = (values: IIOAgentUpsertForm): AgentDefinitionInput => {
  return {
    code: values.code,
    display: values.display,
    url: values.url,
  };
};
