import { HealthCondition } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useArticleCategoryHealthConditionsColumns = (): CustomTableColumnProps<HealthCondition>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(
    () => [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display!, arabicDisplay!),
      },
    ],
    [t]
  );
};
