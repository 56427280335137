import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ArticleCategoryHealthConditionFragmentFragmentDoc } from '../../../../ArticlesCategories/gql/fragments/__generated__/ArticleCategoryHealthCondition';
import { ArticleCategoryFragmentFragmentDoc } from '../../../../ArticlesCategories/gql/fragments/__generated__/ArticleCategory';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionArticleCategoryListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ArticleCategoryFilterInput>;
}>;


export type HealthConditionArticleCategoryListQuery = { __typename?: 'Query', articleCategories?: { __typename?: 'ArticleCategoryCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ArticleCategoryCountableEdge', node: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null, parent?: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null } | null, healthConditions?: Array<{ __typename?: 'HealthCondition', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } }> } | null };


export const HealthConditionArticleCategoryListDocument = gql`
    query HealthConditionArticleCategoryList($first: Int, $last: Int, $after: String, $before: String, $filter: ArticleCategoryFilterInput) {
  articleCategories(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        ...ArticleCategoryHealthConditionFragment
        parent {
          ...ArticleCategoryFragment
        }
      }
    }
  }
}
    ${ArticleCategoryHealthConditionFragmentFragmentDoc}
${ArticleCategoryFragmentFragmentDoc}`;

/**
 * __useHealthConditionArticleCategoryListQuery__
 *
 * To run a query within a React component, call `useHealthConditionArticleCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionArticleCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthConditionArticleCategoryListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthConditionArticleCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>(HealthConditionArticleCategoryListDocument, options);
      }
export function useHealthConditionArticleCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>(HealthConditionArticleCategoryListDocument, options);
        }
export function useHealthConditionArticleCategoryListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>(HealthConditionArticleCategoryListDocument, options);
        }
export type HealthConditionArticleCategoryListQueryHookResult = ReturnType<typeof useHealthConditionArticleCategoryListQuery>;
export type HealthConditionArticleCategoryListLazyQueryHookResult = ReturnType<typeof useHealthConditionArticleCategoryListLazyQuery>;
export type HealthConditionArticleCategoryListSuspenseQueryHookResult = ReturnType<typeof useHealthConditionArticleCategoryListSuspenseQuery>;
export type HealthConditionArticleCategoryListQueryResult = Apollo.QueryResult<HealthConditionArticleCategoryListQuery, HealthConditionArticleCategoryListQueryVariables>;