import { GridProvider } from "@health/domains";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { truncate } from "lodash";
import { useGuidedCareProgramTeamListQuery, useProgramGetNameQuery } from "pages/Programs/gql";
import { useGuidedCareProgramsBreadcrumbs } from "pages/Programs/hooks";
import { IGuidedCareProgramTeamsContainerParams } from "pages/Programs/types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGuidedCareProgramTeamListColumns } from "./useGuidedCareProgramTeamListColumns";

export const GuidedCareProgramTeamListContainer = () => {
  const { setGuidedCareProgramTeamListContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const { programId } = useParams<IGuidedCareProgramTeamsContainerParams>();
  const { data } = useProgramGetNameQuery({
    variables: {
      id: programId!,
    },
  });

  const programName = data?.guidedCareHealthProgram?.name;
  const truncatedName = truncate(programName || "", { length: 50 });

  useEffect(() => {
    setGuidedCareProgramTeamListContainerBreadcrumb(truncatedName);
  }, [setGuidedCareProgramTeamListContainerBreadcrumb, truncatedName]);

  return (
    <GridProvider
      gridName={"guidedCareProgramTeamList"}
      columns={useGuidedCareProgramTeamListColumns()}
      filterInput={{ guidedCareHealthProgramId: programId! }}
      query={useGuidedCareProgramTeamListQuery}
      hasTableSetting
      hideFilterInput
      skipUrlState={false}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
