import { HealthParameter, HealthParameterSortingField, TemplateFieldType } from "@/schema/types";
import { getHealthParameterCategoriesAutocompleteFilter } from "@health/autocompletes";
import { chartTypeOptionsMap, getAutocompleteEnumFilter, templateFieldTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal, formatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { HealthParameterActivation } from "../../components/HealthParameterActivation/HealthParameterActivation";
import { getHealthParameterDefaultValuesInfoItems } from "../../others/cellInfo.utils";

export const useHealthParameterListContainerColumns = (): CustomTableColumnProps<HealthParameter>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "icon",
        header: t("Icon"),
        accessor: ({ icon }) => <GridImageModal image={icon!} title={t("Icon")} altText={t("Icon")} />,
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display!, arabicDisplay!),
        sort: {
          columnEnum: pickLocalizedValue(HealthParameterSortingField.Display, HealthParameterSortingField.ArabicDisplay),
        },
        filter: {
          type: "string",
          name: pickLocalizedValue("display", "arabicDisplay"),
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        sort: {
          columnEnum: HealthParameterSortingField.Code,
        },
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "standardCode",
        header: t("Standard Code"),
        accessor: "standardCodeDisplay",
        sort: {
          columnEnum: HealthParameterSortingField.StandardCode,
        },
        filter: {
          type: "string",
          name: "standardCode",
        },
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => pickLocalizedValue(category?.display!, category?.arabicDisplay!),
        filter: getHealthParameterCategoriesAutocompleteFilter({ name: "category" }),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => templateFieldTypeOptionsMap[type!]?.label,
      },
      {
        key: "chartType",
        header: t("Chart Type"),
        accessor: ({ chartType }) => chartTypeOptionsMap[chartType!]?.label,
      },
      {
        key: "unit",
        header: t("Unit"),
        accessor: ({ unit }) => pickLocalizedValue(unit?.display!, unit?.arabicDisplay!),
      },
      {
        key: "referenceRange",
        header: t("Reference Range"),
        accessor: "referenceRange",
      },
      {
        key: "defaultValues",
        header: t("Default Values"),
        type: "info",
        infoCellOptions: {
          containerProps: {
            maxWidth: "sm",
          },
          title: t("Default Range Values"),
          items: getHealthParameterDefaultValuesInfoItems,
          layout: "one-column",
          disableShowButton: ({ type }) => type !== TemplateFieldType.Number && type !== TemplateFieldType.String,
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        sort: {
          columnEnum: HealthParameterSortingField.CreatedDate,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthParameterActivation id={id!} isActive={!!isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "isManuallyEnteredByPatient",
        header: t("Manually Entered By Patient"),
        filter: getAutocompleteEnumFilter("YesNo", "allowManualEditForPatient"),
        showOnlyForFilterField: true,
        settings: {
          hideFromPreferencesAndTable: true,
        },
      },
    ];
  }, [t]);
};
