import { Decision, DecisionPlanCategory } from "@/schema/types";
import { DecisionListContainer, DecisionPlanCode, DecisionManageDrawer, DecisionManageDrawerApi } from "@health/domains";
import { FC, useState } from "react";

type HealthParameterDecisionListProps = {
  healthParameterCode: string;
};

export const HealthParameterDecisionManage: FC<HealthParameterDecisionListProps> = props => {
  const { healthParameterCode } = props;

  const [key, setKey] = useState(0);

  const onAddNewClick = () => {
    DecisionManageDrawerApi.open({ healthParameterCode });
  };

  const onEditRowClick = (item: Decision) => {
    DecisionManageDrawerApi.open({ decisionId: item?.id!, healthParameterCode });
  };

  const onDecisionManageDrawerChange = () => {
    setKey(prev => prev + 1);
  };

  if (!healthParameterCode) {
    return <></>;
  }

  return (
    <>
      <DecisionManageDrawer
        category={DecisionPlanCategory.HealthCondition}
        decisionPlanCode={DecisionPlanCode.HealthParameterReference}
        onChange={onDecisionManageDrawerChange}
      />

      <DecisionListContainer
        key={key}
        category={DecisionPlanCategory.HealthCondition}
        filter={{ healthParameterCode }}
        onAddNewClick={onAddNewClick}
        onEditRowClick={onEditRowClick}
      />
    </>
  );
};
