import { MedicalMessage, MedicalMessageVariant } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks";
import { convertMedicalMessageVariantToFormValues } from "pages/MedicalMessages/others";
import { IMedicalMessageVariantUpsertFormEvent } from "pages/MedicalMessages/types";
import { MedicalMessageVariantInformation } from "../MedicalMessageVariantInformation/MedicalMessageVariantInformation";
import {
  IMedicalMessageVariantUpsertFormValues,
  medicalMessageVariantUpsertFormDefaultValues,
  medicalMessageVariantUpsertFormSchema,
} from "./MedicalMessageVariantUpsertFormSchema";

type MedicalMessageVariantUpsertFormProps = {
  buttonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  medicalMessageVariant?: MedicalMessageVariant;
  medicalMessage: MedicalMessage;
  onChange: (values: IMedicalMessageVariantUpsertFormEvent) => void;
};

export const MedicalMessageVariantUpsertForm: FC<MedicalMessageVariantUpsertFormProps> = props => {
  const { buttonLabel, isLoading, isButtonDisabled, medicalMessageVariant, medicalMessage, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IMedicalMessageVariantUpsertFormValues>({
    defaultValues: medicalMessageVariantUpsertFormDefaultValues,
    schema: medicalMessageVariantUpsertFormSchema(medicalMessage),
  });

  const {
    setValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (values: IMedicalMessageVariantUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (medicalMessageVariant) {
      const _medicalMessageVariant = convertMedicalMessageVariantToFormValues(medicalMessageVariant, medicalMessage?.medicalMessageType!);
      setValues(_medicalMessageVariant);
    }
  }, [medicalMessage?.medicalMessageType, medicalMessageVariant, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasFormButton isLoading={isLoading} formButtonTitle={buttonLabel} newButtonDisabled={isButtonDisabled} />
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormCard title={t("Medical Message Variant Information")} loading={isLoading} doYouHaveData>
                <MedicalMessageVariantInformation
                  medicalMessageType={medicalMessage?.medicalMessageType!}
                  medicalMessageCategory={medicalMessage?.medicalMessageCategory!}
                />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Medical Message Variant Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"headerImage"}
                      label={t("Image")}
                      token={accessToken!}
                      validationRation
                      error={!!errors?.headerImage}
                      helperText={errors?.headerImage?.message}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
