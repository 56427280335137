import { createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IMedicalFormClassificationAutocomplete } from "./schema";

export const createMedicalFormClassificationAutocompleteOption = (medicalForm: IMedicalFormClassificationAutocomplete) => {
  return createAutocompleteOption(
    {
      id: medicalForm?.id!,
      code: medicalForm?.code!,
      name: medicalForm?.name!,
      nameAr: medicalForm?.nameAr!,
      classifications:
        medicalForm?.medicalFormClassifications?.map(classification => ({
          id: classification?.id!,
          name: classification?.name!,
          rangeFrom: classification?.rangeFrom!,
          rangeTo: classification?.rangeTo!,
        })) || [],
    },
    "id",
    item => `${item?.code} - ${pickLocalizedValue(item?.name!, item?.nameAr!)}`
  );
};
