import { ChatFlow, ChatFlowUpdateInput } from "@/schema/types";
import { IAgentUpsertFormValues } from "../forms/AgentFormUpsert/AgentUpsertFormSchema";

export const convertAgentFormValuesToBackEndValues = (values: IAgentUpsertFormValues): ChatFlowUpdateInput => {
  return {
    name: values.name || "",
    description: values.description || "",
    conditionsAndRules: values.conditionsAndRules || "",
  };
};

export const convertAgentToFormValues = (agent: ChatFlow): IAgentUpsertFormValues => {
  return {
    name: agent?.name || "",
    description: agent?.description || "",
    conditionsAndRules: agent?.conditionsAndRules || "",
    code: agent?.code,
  };
};

export function convertBytes(bytes) {
  const kb = bytes / 1024;
  const mb = kb / 1024;

  if (mb >= 1) {
    return `${mb.toFixed(2)} MB`;
  } else {
    return `${kb.toFixed(2)} KB`;
  }
}
