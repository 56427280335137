/* eslint-disable react/prop-types */
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomIcon, IconButton, Tooltip, useAddToast } from "@toolkit/ui";
import { useChatFlowDocumentDeleteMutation } from "../gql";

type DeleteDocsButtonsProps = {
  docId: string;
  fileName?: string;
};
export const DeleteDocsButtons: React.FC<DeleteDocsButtonsProps> = ({ docId, fileName }) => {
  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();
  const { open, handleToggle } = useOpenState();
  const [deleteDoc, { loading }] = useChatFlowDocumentDeleteMutation({
    onCompleted: () => {
      succeeded(t("Document deleted successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    update: cache => {
      const normalizedId = cache.identify({ id: docId, __typename: "ChatFlowDocument" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });
  const handleDeleteDocsClick = () => {
    deleteDoc({
      variables: {
        chatFlowDocumentDeleteId: docId,
      },
    });
  };
  return (
    <>
      <Tooltip title={t("DeleteDocs Agent")}>
        <IconButton onClick={handleToggle} disabled={loading}>
          <CustomIcon icon='delete' />
        </IconButton>
      </Tooltip>
      {open && (
        <CustomDialog
          type='delete'
          text={{
            body: t("Are you sure you want to delete this agent?"),
            title: t(`Delete {{fileName}}`, { fileName: fileName || "this" }),
          }}
          isOpen={open}
          onClose={handleToggle}
          onDelete={handleDeleteDocsClick}
        />
      )}
    </>
  );
};
