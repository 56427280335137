import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ArticleCategoryFragmentFragmentDoc } from './ArticleCategory';
export type ArticleCategoryHealthConditionFragmentFragment = { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null, healthConditions?: Array<{ __typename?: 'HealthCondition', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null };

export const ArticleCategoryHealthConditionFragmentFragmentDoc = gql`
    fragment ArticleCategoryHealthConditionFragment on ArticleCategory {
  ...ArticleCategoryFragment
  healthConditions {
    id
    code
    display
    arabicDisplay
  }
}
    ${ArticleCategoryFragmentFragmentDoc}`;