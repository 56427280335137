import { MedicalMessageInput, MedicalMessageType, MedicalMessageVariant, MedicalMessageVariantInput } from "@/schema/types";
import { medicalMessageCategoryOptionsMap, medicalMessageTypeOptionsMap } from "@health/enum-options";
import { getMediaLink } from "@toolkit/core";
import { createAutocompleteOption } from "@toolkit/ui";
import { IMedicalMessageUpsertFormValues, IMedicalMessageVariantUpsertFormValues } from "pages/MedicalMessages/forms";
import { IMedicalMessage, IMedicalMessageVariantExtraData } from "pages/MedicalMessages/types";
import {
  createArticleAutocompleteOption,
  createHealthConditionAutocompleteOption,
  createHealthParameterAutocompleteOption,
} from "@health/autocompletes";

export const convertMedicalMessageToFormValues = (medicalMessage: IMedicalMessage): IMedicalMessageUpsertFormValues => {
  return {
    name: medicalMessage?.name!,
    code: medicalMessage?.code!,
    headerImage: getMediaLink(medicalMessage?.headerImage),
    medicalMessageType: medicalMessageTypeOptionsMap[medicalMessage?.medicalMessageType!],
    medicalMessageCategory: medicalMessageCategoryOptionsMap[medicalMessage?.medicalMessageCategory!],
    healthCondition: medicalMessage?.healthCondition ? createHealthConditionAutocompleteOption(medicalMessage?.healthCondition) : null,
  };
};

export const convertMedicalMessageFormValuesToBackEndValues = (values: IMedicalMessageUpsertFormValues): MedicalMessageInput => {
  return {
    name: values.name,
    code: values.code,
    headerImage: values.headerImage,
    medicalMessageType: values.medicalMessageType?.value,
    medicalMessageCategory: values.medicalMessageCategory?.value,
    healthConditionId: values.healthCondition?.value?.id,
  };
};

export const convertMedicalMessageVariantToFormValues = (
  medicalMessageVariant: MedicalMessageVariant,
  medicalMessageType: MedicalMessageType
): IMedicalMessageVariantUpsertFormValues => {
  return {
    presentedTitleArabic: medicalMessageVariant.presentedTitleArabic!,
    presentedTitleEnglish: medicalMessageVariant.presentedTitleEnglish!,
    bodyArabic: medicalMessageVariant.bodyArabic!,
    bodyEnglish: medicalMessageVariant.bodyEnglish!,
    buttonTextArabic: medicalMessageVariant.buttonTextArabic || undefined,
    buttonTextEnglish: medicalMessageVariant.buttonTextEnglish || undefined,
    contentURLEnglish: medicalMessageVariant?.contentURLEnglish || undefined,
    contentURLArabic: medicalMessageVariant?.contentURLArabic || undefined,
    headerImage: getMediaLink(medicalMessageVariant?.headerImage),
    healthParameter: medicalMessageVariant?.healthParameter
      ? createHealthParameterAutocompleteOption(medicalMessageVariant?.healthParameter)
      : undefined,
    article: medicalMessageVariant?.article ? createArticleAutocompleteOption(medicalMessageVariant?.article) : undefined,
    ...convertMedicalMessageVariantVideoToFormValues(medicalMessageVariant, medicalMessageType),
  };
};

export const convertMedicalMessageVariantVideoToFormValues = (
  medicalMessageVariant: MedicalMessageVariant,
  medicalMessageType: MedicalMessageType
) => {
  const extraData: IMedicalMessageVariantExtraData = medicalMessageVariant?.extraData && JSON.parse(medicalMessageVariant?.extraData);

  let videoArabic: IMedicalMessageVariantUpsertFormValues["videoArabic"] = undefined;
  let videoEnglish: IMedicalMessageVariantUpsertFormValues["videoEnglish"] = undefined;

  if (medicalMessageType === MedicalMessageType.Video) {
    if (extraData?.videoArabic) {
      videoArabic = createAutocompleteOption(extraData?.videoArabic, "id", "title");
    }

    if (extraData?.videoEnglish) {
      videoEnglish = createAutocompleteOption(extraData?.videoEnglish, "id", "title");
    }
  }

  return {
    videoArabic,
    videoEnglish,
  };
};

export const convertMedicalMessageVariantFormValuesToBackEndValues = (
  values: IMedicalMessageVariantUpsertFormValues,
  medicalMessageType: MedicalMessageType
): MedicalMessageVariantInput => {
  const extraData: IMedicalMessageVariantExtraData | undefined = {};

  if (medicalMessageType === MedicalMessageType.Video) {
    if (values?.videoArabic?.value) {
      extraData.videoArabic = values?.videoArabic?.value;
    }

    if (values?.videoEnglish?.value) {
      extraData.videoEnglish = values?.videoEnglish?.value;
    }
  }

  return {
    bodyArabic: values?.bodyArabic,
    bodyEnglish: values?.bodyEnglish,
    buttonTextArabic: values?.buttonTextArabic,
    buttonTextEnglish: values?.buttonTextEnglish,
    contentURLArabic: values?.contentURLArabic,
    contentURLEnglish: values?.contentURLEnglish,
    presentedTitleArabic: values?.presentedTitleArabic,
    presentedTitleEnglish: values?.presentedTitleEnglish,
    headerImage: values?.headerImage,
    articleId: values?.article?.value?.id,
    healthParameterId: values?.healthParameter?.value?.id,
    extraData: Object.keys(extraData)?.length ? JSON.stringify(extraData) : undefined,
  };
};
