import { MedicalMessageCategory, MedicalMessageVariant, MedicalMessageVariantSortField, PermissionEnum } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { hasPermission } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { MedicalMessageVariantActivation } from "../../components";

type MedicalMessageVariantsTableColumnsProps = {
  medicalMessageCategory: MedicalMessageCategory;
};

export const useMedicalMessageVariantsTableColumns = (
  props: MedicalMessageVariantsTableColumnsProps
): CustomTableColumnProps<MedicalMessageVariant>[] => {
  const { medicalMessageCategory } = props;

  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("id-as-number"),
        accessor: "id",
        sort: {
          columnEnum: MedicalMessageVariantSortField.Id,
        },
      },
      {
        key: "presentedTitleEnglish",
        header: t("Title (English)"),
        accessor: "presentedTitleEnglish",
        filter: {
          type: "string",
          name: "titleContains",
        },
      },
      {
        key: "presentedTitleArabic",
        header: t("Title (Arabic)"),
        accessor: "presentedTitleArabic",
      },
      {
        key: "healthParameter",
        header: t("Health Parameter"),
        accessor: ({ healthParameter }) => pickLocalizedValue(healthParameter?.display, healthParameter?.arabicDisplay),
        isHidden: medicalMessageCategory !== MedicalMessageCategory.Monitoring,
      },
      {
        key: "sentCounter",
        header: t("Sent Count"),
        accessor: "sentCounter",
      },
      {
        key: "readCounter",
        header: t("Read Count"),
        accessor: "readCounter",
      },
      {
        key: "buttonTextEnglish",
        header: t("Button Text (English)"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "buttonText",
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        type: "date",
        accessor: "createdDate",
        sort: {
          columnEnum: MedicalMessageVariantSortField.CreatedDate,
        },
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        type: "date",
        accessor: "lastModifiedDate",
        sort: {
          columnEnum: MedicalMessageVariantSortField.LastModifiedDate,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <MedicalMessageVariantActivation id={id} isActive={!!isActive} />,
        isHidden: !hasPermission(PermissionEnum.ManageHealthMessages),
        sort: {
          columnEnum: MedicalMessageVariantSortField.IsActive,
        },
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [medicalMessageCategory, t]);
};
