/* eslint-disable react/prop-types */
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomIcon, IconButton, Tooltip, useAddToast, useGridContext } from "@toolkit/ui";
import { useChatFlowDeleteMutation } from "../gql";

type DeleteButtonsProps = {
  agentId: string;
  agentName?: string;
};
export const DeleteButtons: React.FC<DeleteButtonsProps> = ({ agentId, agentName }) => {
  const { t } = useTranslation("domains");
  const { open, handleToggle } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const { refetch } = useGridContext();
  const [deleteAgentMutation, { loading }] = useChatFlowDeleteMutation({
    onCompleted: () => {
      succeeded("Agent deleted successfully");
      refetch({ first: 10, filter: null, after: null });
      handleToggle();
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const handleDeleteAgent = () => {
    deleteAgentMutation({
      variables: {
        chatFlowDeleteId: agentId,
      },
    });
  };
  return (
    <>
      <Tooltip title={t("Delete Agent")}>
        <IconButton onClick={handleToggle} disabled={loading}>
          <CustomIcon icon='delete' />
        </IconButton>
      </Tooltip>
      {open && (
        <CustomDialog
          type='delete'
          text={{
            title: t("Delete {{agentName}} Agent", { agentName: agentName || "this" }),
            body: t("Are you sure you want to delete this agent?"),
          }}
          isOpen={open}
          onClose={handleToggle}
          onDelete={handleDeleteAgent}
        />
      )}
    </>
  );
};
