import {
  ProviderGuidedCareHealthProgramTeam,
  ProviderGuidedCareHealthProgramTeamSortingField,
  ProviderGuidedCareProgramTeamMember,
} from "@/schema/types";
import {
  getDoctorsAutocompleteFilter,
  getGuidedCareProgramsAutocompleteFilter,
  getHealthProgramMembersAutocompleteFilter,
  getPatientsAutocompleteFilter,
  getPayersAutocompleteFilter,
  getVendorsAutocompleteFilter,
} from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, useFormatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { GuidedCareProgramTeamMemberListView } from "../../components/ProgramTeamMemberListView/GuidedCareProgramTeamMemberListView";
import { GuidedCareProgramTeamStatus } from "../../components/ProgramTeamStatus/GuidedCareProgramTeamStatus";

export const useGuidedCareProgramTeamListColumns = (): CustomTableColumnProps<ProviderGuidedCareHealthProgramTeam>[] => {
  const { t } = useTranslation("gcadmin");
  const { formatLocalizedDate } = useFormatDate();

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        sort: {
          columnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Name,
        },
      },
      {
        key: "provider",
        header: t("Provider"),
        accessor: ({ provider }) => provider?.name,
        filter: getVendorsAutocompleteFilter({ name: "providerId" }),
      },
      {
        key: "program",
        header: t("Program"),
        showOnlyForFilterField: true,
        filter: getGuidedCareProgramsAutocompleteFilter({ name: "guidedCareHealthProgramId" }),
      },
      {
        key: "payer",
        header: t("Payer"),
        showOnlyForFilterField: true,
        filter: getPayersAutocompleteFilter({ name: "payerId" }),
      },
      {
        key: "doctors",
        header: t("Doctors"),
        showOnlyForFilterField: true,
        filter: getDoctorsAutocompleteFilter({ name: "doctorIds", multiple: true }),
      },
      {
        key: "patients",
        header: t("Patients"),
        showOnlyForFilterField: true,
        filter: getPatientsAutocompleteFilter({ name: "patients", multiple: true }),
      },
      {
        key: "healthProgramMembers",
        header: t("Health Program Members"),
        showOnlyForFilterField: true,
        filter: getHealthProgramMembersAutocompleteFilter({ name: "healthProgramMembers", multiple: true }),
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ teamStatus }) => (teamStatus ? <GuidedCareProgramTeamStatus teamStatus={teamStatus} /> : undefined),
        sort: {
          columnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Status,
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatLocalizedDate(createdDate),
        sort: {
          columnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Created,
        },
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        accessor: ({ lastModifiedDate }) => formatLocalizedDate(lastModifiedDate),
        sort: {
          columnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Modified,
        },
      },
      {
        key: "review",
        header: t("View"),
        accessor: ({ id, teamMembers }) => {
          return <GuidedCareProgramTeamMemberListView id={id} teamMembers={teamMembers as ProviderGuidedCareProgramTeamMember[]} />;
        },
        type: "string",
      },
    ];
  }, [formatLocalizedDate, t]);
};
