import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { GuidedCareProgramTemplateActivityItemList } from "../../components/ProgramTemplateActivityItemList/GuidedCareProgramTemplateActivityItemList";

type GuidedCareProgramTemplateActivityItemsModalProps = {
  templateActivityId: string;
};

export const GuidedCareProgramTemplateActivityItemsModal: FC<GuidedCareProgramTemplateActivityItemsModalProps> = props => {
  const { templateActivityId } = props;

  const { t } = useTranslation("gcadmin");

  const { open, handleOpen, handleClose } = useOpenState();

  return (
    <CustomDialog
      open={open}
      type={"base"}
      maxWidth={"lg"}
      button={<Button onClick={handleOpen}> {t("View")} </Button>}
      DialogTitleProps={{ title: t("Activity Items"), onClose: handleClose }}
    >
      <GuidedCareProgramTemplateActivityItemList templateActivityId={templateActivityId} />
    </CustomDialog>
  );
};
