import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { IOAgentCreateContainer, IOAgentListContainer, IOAgentUpdateContainer } from "../containers";
import { ioAgentsPaths, ioAgentsRoute } from "./IOAgentsPaths";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";

export const ioAgentsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "io-agents-routes",
    text: i18n.t("IO Agents", { ns: "gcadmin" }),
    route: ioAgentsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageAgentDefinitions),
    subItems: [
      {
        id: "io-agent-list-route",
        route: ioAgentsPaths.list.route,
        fullPath: ioAgentsPaths.list.fullPath,
        element: <IOAgentListContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "io-agent-create-route",
        route: ioAgentsPaths.create.route,
        fullPath: ioAgentsPaths.create.fullPath,
        hidden: true,
        element: <IOAgentCreateContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "io-agent-update-route",
        route: ioAgentsPaths.update.route,
        fullPath: ioAgentsPaths.update.fullPath,
        hidden: true,
        element: <IOAgentUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
