import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useArticlesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IArticleAutocomplete } from "./schema";

export const createArticleAutocompleteOption = (article: IArticleAutocomplete) => {
  return createAutocompleteOption(
    {
      id: article?.id!,
      title: article?.title!,
      titleAr: article?.titleAr!,
    },
    "id",
    item => pickLocalizedValue(item?.title, item?.titleAr)
  );
};

export const getArticleAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "articles",
    query: useArticlesAutocompleteQuery,
    labelBy: "title",
    backendAccessor: "id",
  });
};
