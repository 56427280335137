import { MedicalMessage, MedicalMessageSortField, PermissionEnum } from "@/schema/types";
import { getHealthConditionsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, medicalMessageCategoryOptionsMap, medicalMessageTypeOptionsMap } from "@health/enum-options";
import { hasPermission } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { MedicalMessagePublish } from "pages/MedicalMessages/components";
import { medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { MedicalMessagePreview } from "../MedicalMessagePreview/MedicalMessagePreview";

export const useMedicalMessagesTableColumns = (): CustomTableColumnProps<MedicalMessage>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowVariantsClick = useCallback(
    (medicalMessageId: string) => {
      navigate(medicalMessagesPaths.variants.fullPathWithParams({ medicalMessageId }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        sort: {
          columnEnum: MedicalMessageSortField.Name,
        },
        filter: {
          type: "string",
          name: "nameContains",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "sentCounter",
        header: t("Sent Count"),
        accessor: "sentCounter",
      },
      {
        key: "readCounter",
        header: t("Read Count"),
        accessor: "readCounter",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ medicalMessageType }) => medicalMessageTypeOptionsMap[medicalMessageType!]?.label,
        filter: getAutocompleteEnumFilter("MedicalMessageType", "type"),
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ medicalMessageCategory }) => medicalMessageCategoryOptionsMap[medicalMessageCategory!]?.label,
        filter: getAutocompleteEnumFilter("MedicalMessageCategory", "category"),
      },
      {
        key: "healthCondition",
        header: t("Health Condition"),
        accessor: ({ healthCondition }) => pickLocalizedValue(healthCondition?.display, healthCondition?.arabicDisplay),
        filter: getHealthConditionsAutocompleteFilter({ name: "healthConditionId" }),
      },
      {
        key: "variants",
        header: t("Variants"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowVariantsClick(id!),
          tooltipMessage: row => t("show {{name}} variants", { name: row?.name }),
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        type: "date",
        accessor: "createdDate",
        sort: {
          columnEnum: MedicalMessageSortField.CreatedDate,
        },
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        type: "date",
        accessor: "lastModifiedDate",
        sort: {
          columnEnum: MedicalMessageSortField.LastModifiedDate,
        },
      },
      {
        key: "active",
        header: t("Is Published"),
        accessor: ({ id, isPublished }) => <MedicalMessagePublish id={id} isPublished={!!isPublished} />,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
        isHidden: !hasPermission(PermissionEnum.ManageHealthMessages),
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: ({ id, headerImage }) => <MedicalMessagePreview id={id} image={headerImage} />,
      },
    ];
  }, [onShowVariantsClick, t]);
};
