import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, PlusIcon } from "@toolkit/ui";
import {
  GuidedCareTemplateHealthParameterUpsertForm,
  GuidedCareTemplateHealthParameterUpsertFormApi,
  IGuidedCareTemplateHealthParameterUpsertFormEvent,
} from "../../forms/TemplateHealthParameterUpsert/GuidedCareTemplateHealthParameterUpsertForm";
import { IGuidedCareTemplateUpsertFormValues } from "../../forms/TemplateUpsert/GuidedCareTemplateUpsertFormSchema";

type GuidedCareTemplateHealthParameterUpsertModalProps = {
  isOpen: boolean;
  healthConditionId: string;
  healthParameters: IGuidedCareTemplateUpsertFormValues["healthParameters"];
  handleToggle: () => void;
  onChange: (event: IGuidedCareTemplateHealthParameterUpsertFormEvent) => void;
};

export const GuidedCareTemplateHealthParameterUpsertModal: FC<GuidedCareTemplateHealthParameterUpsertModalProps> = props => {
  const { isOpen, healthConditionId, healthParameters, handleToggle, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const onAssignClick = () => {
    GuidedCareTemplateHealthParameterUpsertFormApi.submit();
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      maxWidth={"sm"}
      button={
        <Button startIcon={<PlusIcon />} fullWidth onClick={handleToggle}>
          {t("Add Health Parameter")}
        </Button>
      }
      DialogTitleProps={{
        title: t("Add Health Parameter"),
        onClose: handleToggle,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Add"),
        onSubmit: onAssignClick,
      }}
    >
      <GuidedCareTemplateHealthParameterUpsertForm
        healthConditionId={healthConditionId}
        healthParameters={healthParameters}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
