import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateActivityGetQueryVariables = Types.Exact<{
  templateActivityId: Types.Scalars['ID']['input'];
}>;


export type GuidedCareTemplateActivityGetQuery = { __typename?: 'Query', templateActivity?: { __typename?: 'TemplateActivity', id: string, templateActivityType?: Types.GuidedCareActivityType | null, templateTeamMember?: { __typename?: 'TemplateTeamMember', id: string, position?: Types.TeamMemberPosition | null, specialization?: string | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, templateActivityEventItems?: Array<{ __typename?: 'TemplateActivityEventItem', id: string, offsetInDays?: number | null, timeOfDay?: Types.TimeOfDay | null, notificationFrequency?: Types.NotificationFrequency | null, beforeCallMessageOffsetDays?: number | null, afterCallMessageOffsetDays?: number | null, beforeCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, afterCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null } | null> | null } | null };


export const GuidedCareTemplateActivityGetDocument = gql`
    query GuidedCareTemplateActivityGet($templateActivityId: ID!) {
  templateActivity(id: $templateActivityId) {
    id
    templateActivityType
    templateTeamMember {
      id
      position
      specialization
    }
    medicalMessage {
      id
      code
      name
    }
    templateActivityEventItems {
      id
      offsetInDays
      timeOfDay
      notificationFrequency
      beforeCallMessageOffsetDays
      afterCallMessageOffsetDays
      beforeCallMessage {
        id
        code
        name
      }
      afterCallMessage {
        id
        code
        name
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTemplateActivityGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplateActivityGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateActivityGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplateActivityGetQuery({
 *   variables: {
 *      templateActivityId: // value for 'templateActivityId'
 *   },
 * });
 */
export function useGuidedCareTemplateActivityGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables> & ({ variables: GuidedCareTemplateActivityGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables>(GuidedCareTemplateActivityGetDocument, options);
      }
export function useGuidedCareTemplateActivityGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables>(GuidedCareTemplateActivityGetDocument, options);
        }
export function useGuidedCareTemplateActivityGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables>(GuidedCareTemplateActivityGetDocument, options);
        }
export type GuidedCareTemplateActivityGetQueryHookResult = ReturnType<typeof useGuidedCareTemplateActivityGetQuery>;
export type GuidedCareTemplateActivityGetLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplateActivityGetLazyQuery>;
export type GuidedCareTemplateActivityGetSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTemplateActivityGetSuspenseQuery>;
export type GuidedCareTemplateActivityGetQueryResult = Apollo.QueryResult<GuidedCareTemplateActivityGetQuery, GuidedCareTemplateActivityGetQueryVariables>;