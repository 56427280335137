import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useGuidedCareProgramsAutocompleteQuery } from "./gql";
import { IGuidedCareProgramAutocomplete } from "./schema";

export const createGuidedCareProgramAutocompleteOption = (program: IGuidedCareProgramAutocomplete) => {
  return createAutocompleteOption({ id: program?.id!, name: program?.name! }, "id", "name");
};

export const getGuidedCareProgramsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "guidedCareHealthPrograms",
    query: useGuidedCareProgramsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
    filtersForQuery: {
      name_Icontains: "",
    },
  });
};
