import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type ArticleCategoryFragmentFragment = { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null };

export const ArticleCategoryFragmentFragmentDoc = gql`
    fragment ArticleCategoryFragment on ArticleCategory {
  id
  code
  display
  displayAr
  icon
}
    `;