import { useCustomFormContext, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, Grid, Typography } from "@toolkit/ui";
import { useFieldArray } from "react-hook-form";
import { FC } from "react";
import { IGuidedCareTemplateHealthParameter } from "../../types";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { GuidedCareTemplateHealthParameterUpsertModal } from "../../modals/TemplateHealthParameterUpsert/GuidedCareTemplateHealthParameterUpsertModal";
import { GuidedCareTemplateHealthParameterDecisionsDrawer } from "@/pages/Templates/components/TemplateHealthParameterDecisionsDrawer/GuidedCareTemplateHealthParameterDecisionsDrawer";
import { IGuidedCareTemplateHealthParameterUpsertFormEvent } from "../TemplateHealthParameterUpsert/GuidedCareTemplateHealthParameterUpsertForm";
import { useGuidedCareTemplateHealthParametersColumns } from "./useGuidedCareTemplateHealthParametersColumns";
import { useGuidedCareTemplateMembersFormStyle } from "./useGuidedCareTemplateMembersFormStyle";

type GuidedCareTemplateHealthParametersFormProps = {
  isUpdateMode: boolean;
  guidedCareTemplateCode: string;
  healthConditionId: string;
};

export const GuidedCareTemplateHealthParametersForm: FC<GuidedCareTemplateHealthParametersFormProps> = props => {
  const { isUpdateMode, guidedCareTemplateCode, healthConditionId } = props;

  const { t } = useTranslation("gcadmin");

  const { open, handleToggle } = useOpenState();

  const { classes } = useGuidedCareTemplateMembersFormStyle();

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { watch, control } = form;

  const healthParameters = watch("healthParameters");

  const { fields, append, remove } = useFieldArray<IGuidedCareTemplateUpsertFormValues, "healthParameters">({
    control,
    name: "healthParameters",
  });

  const onGuidedCareTemplateHealthParameterUpsertModalChange = (event: IGuidedCareTemplateHealthParameterUpsertFormEvent) => {
    if (event.type === "CHANGE") {
      append(event.payload.values);
      handleToggle();
    }
  };

  const onDeleteRowClick = (_: IGuidedCareTemplateHealthParameter, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateHealthParameterDecisionsDrawer guidedCareTemplateCode={guidedCareTemplateCode} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={9}>
            <Typography className={classes.title}> {t("Digital Twin Health Parameters")} </Typography>
          </Grid>

          <Grid xs={4} lg={3}>
            <GuidedCareTemplateHealthParameterUpsertModal
              isOpen={open}
              healthConditionId={healthConditionId}
              healthParameters={healthParameters}
              handleToggle={handleToggle}
              onChange={onGuidedCareTemplateHealthParameterUpsertModalChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateHealthParametersColumns({ isUpdateMode })}
          isDeleteVisible
          scrollable
          hasFooter={false}
          onDeleteRow={onDeleteRowClick}
        />
      </Grid>
    </Grid>
  );
};
