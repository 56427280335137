import { Article } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { articlesBreadcrumb } from "../../constants";
import { ArticleUpsertForm, IArticleUpsertFormEvent } from "../../forms/ArticleUpsert/ArticleUpsertForm";
import { useArticleGetQuery, useArticleUpdateMutation } from "../../gql";
import { IArticleUpdateContainerParams } from "../../types";
import { convertArticleFormValuesToBackEndValues } from "../../utils";

export const ArticleUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();

  const { articleId } = useParams<IArticleUpdateContainerParams>();

  const { data, loading } = useArticleGetQuery({
    variables: { articleId: articleId! },
    skip: !articleId,
    fetchPolicy: "no-cache",
  });

  const article = data?.article as Article;

  const [updateArticle, { loading: isSubmitting }] = useArticleUpdateMutation({
    onCompleted: () => {
      succeeded(t("Article updated successfully"));
      navigate(-1);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onArticleUpsertFormChange = (event: IArticleUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertArticleFormValuesToBackEndValues(event.payload.values);

      updateArticle({
        variables: {
          articleId: articleId!,
          input: values,
        },
      });
    }
  };

  useEffect(() => {
    const { title, main, update } = articlesBreadcrumb();
    setBreadCrumb({ title, values: [main, update(article?.title!)] });
  }, [setBreadCrumb, article, t]);

  return (
    <ArticleUpsertForm
      submitButtonLabel={t("Update")}
      article={article}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onArticleUpsertFormChange}
    />
  );
};
