import { makeStyles } from "@toolkit/ui";

export const useArticleCategoryInformationStyle = makeStyles()(theme => ({
  root: {
    padding: 16,
    marginBottom: 16,
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  title: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
