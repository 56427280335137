import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import {
  MedicalFormClassificationUpsertForm,
  MedicalFormClassificationUpsertFormRef,
} from "@/pages/MedicalForms/forms/MedicalFormClassificationUpsert/MedicalFormClassificationUpsertForm";
import { IMedicalFormClassificationUpsertFormValues } from "@/pages/MedicalForms/forms/MedicalFormClassificationUpsert/MedicalFormClassificationUpsertFormSchema";
import { IMedicalFormClassificationUpsertFormEvent } from "@/pages/MedicalForms/types";
import { FC, MouseEvent, useRef } from "react";

type MedicalFormClassificationUpsertModalProps = {
  classification?: IMedicalFormClassificationUpsertFormValues;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
  onChange: (values: IMedicalFormClassificationUpsertFormEvent) => void;
};

export const MedicalFormClassificationUpsertModal: FC<MedicalFormClassificationUpsertModalProps> = props => {
  const { classification, isOpen, onClose, onChange } = props;

  const { t } = useTranslation("domains");
  const medicalFormClassificationUpsertFormRef = useRef<MedicalFormClassificationUpsertFormRef>(null);

  const onButtonClick = () => {
    medicalFormClassificationUpsertFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: classification ? t("Update Classification") : t("Add New Classification"),
        onClose,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: classification ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <MedicalFormClassificationUpsertForm
        classification={classification!}
        ref={medicalFormClassificationUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
