/* eslint-disable react/jsx-handler-names */
import { articlesCategoriesPaths } from "@/pages/ArticlesCategories/constants";
import { ArticleCategory, HealthCondition } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, StyledButton, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { HealthConditionInformation } from "../../components/HealthConditionInformation/HealthConditionInformation";
import { useHealthConditionArticleCategoryListQuery, useHealthConditionGetQuery } from "../../gql";
import { useSetHealthConditionsBreadcrumbs } from "../../hooks";
import { IHealthConditionParameterListContainerParams } from "../../types";
import { useHealthConditionArticleCategoryListContainerColumns } from "./useHealthConditionArticleCategoryListContainerColumns";

export const HealthConditionArticleCategoryListContainer = () => {
  const { t } = useTranslation("gcadmin");

  const navigate = useNavigate();

  const { healthConditionId } = useParams<IHealthConditionParameterListContainerParams>();

  const { data, loading } = useHealthConditionGetQuery({
    variables: {
      id: healthConditionId!,
    },
    skip: !healthConditionId,
  });

  const healthCondition = data?.getHealthCondition as HealthCondition;

  const onAddNewItemClick = () => {
    navigate(`${articlesCategoriesPaths.create.fullPath}?healthConditionId=${healthConditionId}`);
  };

  const onEditRowClick = (item: ArticleCategory) => {
    navigate(articlesCategoriesPaths.update.fullPathWithParams({ articleCategoryId: item?.id }));
  };

  const onCancelClick = () => {
    navigate(-1);
  };

  useSetHealthConditionsBreadcrumbs(
    "ARTICLE_CATEGORY_LIST",
    pickLocalizedValue(healthCondition?.display!, healthCondition?.arabicDisplay!)
  );

  return (
    <GridProvider
      gridName={"healthConditionArticleCategories"}
      query={useHealthConditionArticleCategoryListQuery}
      columns={useHealthConditionArticleCategoryListContainerColumns()}
      hasTableSetting
      hideFilterInput
      fetchPolicy={"no-cache"}
      filterInput={{
        healthConditionIds: [healthConditionId],
      }}
      skipCall={!healthConditionId}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />

            <StyledButton variant={"outlined"} onClick={onCancelClick}>
              {t("Cancel")}
            </StyledButton>
          </>
        }
      >
        <HealthConditionInformation healthCondition={healthCondition} isLoading={loading} />

        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
