import { FlowiseIntegrationChatSortingField, FlowrseIntegrationSortDirection } from "@/schema/types";
import { FilterGrid, PageWrapper, TableGrid } from "@toolkit/ui";
import { SyncButton } from "@/pages/AIAgents/components";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useChatFlowsQuery } from "../../gql/queries";
import { useAgentsListTableColumns } from "./useAgentsListTableColumns";

export const AgentsList: FC<{ isAdmin: boolean; handleEditClick: (agentId: string) => void }> = ({ isAdmin, handleEditClick }) => {
  return (
    <GridProvider
      gridName='marketplace-brands'
      query={useChatFlowsQuery}
      columns={useAgentsListTableColumns({ isAdmin, handleEditClick })}
      variables={{
        sortBy: {
          field: FlowiseIntegrationChatSortingField.Updated,
          direction: FlowrseIntegrationSortDirection.Desc,
        },
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={isAdmin ? <SyncButton /> : <></>}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
