import { TemplateActivityEventItem } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { timeOfDayOptionsMap } from "@health/enum-options";

export const useGuidedCareProgramTemplateActivityItemListColumns = (): CustomTableColumnProps<TemplateActivityEventItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "offset",
        header: t("Offset (Days)"),
        accessor: "offsetInDays",
      },
      {
        key: "time",
        header: t("Time"),
        accessor: ({ timeOfDay }) => (timeOfDay ? timeOfDayOptionsMap[timeOfDay]?.label : undefined),
      },
      {
        key: "beforeActivity",
        header: t("Before Activity"),
        accessor: ({ beforeCallMessage }) => beforeCallMessage?.name,
      },
      {
        key: "beforeActivityOffset",
        header: t("Before Activity Offset (Days)"),
        accessor: "beforeCallMessageOffsetDays",
      },
      {
        key: "afterActivity",
        header: t("After Activity"),
        accessor: ({ afterCallMessage }) => afterCallMessage?.name,
      },
      {
        key: "afterActivityOffset",
        header: t("After Activity Offset (Days)"),
        accessor: "afterCallMessageOffsetDays",
      },
    ];
  }, [t]);
};
