import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthParameterGetQuery = { __typename?: 'Query', getHealthParameter?: { __typename?: 'HealthParameter', id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, standard?: Types.FieldStandard | null, standardCode?: string | null, standardCodeDisplay?: string | null, icon?: string | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, isMandatory?: boolean | null, visibleForPatientOverview?: boolean | null, referenceRange?: string | null, defaultValue?: string | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, allowedValues?: Array<string | null> | null, allowManualEditForPatient?: boolean | null, riskParameter?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null, unit?: { __typename?: 'FieldUnit', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null };


export const HealthParameterGetDocument = gql`
    query HealthParameterGet($id: ID!) {
  getHealthParameter(id: $id) {
    id
    isActive
    code
    display
    arabicDisplay
    standard
    standardCode
    standardCodeDisplay
    icon
    type
    chartType
    isMandatory
    visibleForPatientOverview
    referenceRange
    type
    defaultValue
    minNormalRangeValue
    maxNormalRangeValue
    allowedValues
    allowManualEditForPatient
    riskParameter {
      id
      code
      display
      arabicDisplay
    }
    category {
      id
      code
      display
      arabicDisplay
    }
    unit {
      id
      code
      display
      arabicDisplay
    }
  }
}
    `;

/**
 * __useHealthParameterGetQuery__
 *
 * To run a query within a React component, call `useHealthParameterGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParameterGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthParameterGetQuery(baseOptions: Apollo.QueryHookOptions<HealthParameterGetQuery, HealthParameterGetQueryVariables> & ({ variables: HealthParameterGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthParameterGetQuery, HealthParameterGetQueryVariables>(HealthParameterGetDocument, options);
      }
export function useHealthParameterGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthParameterGetQuery, HealthParameterGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthParameterGetQuery, HealthParameterGetQueryVariables>(HealthParameterGetDocument, options);
        }
export function useHealthParameterGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthParameterGetQuery, HealthParameterGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthParameterGetQuery, HealthParameterGetQueryVariables>(HealthParameterGetDocument, options);
        }
export type HealthParameterGetQueryHookResult = ReturnType<typeof useHealthParameterGetQuery>;
export type HealthParameterGetLazyQueryHookResult = ReturnType<typeof useHealthParameterGetLazyQuery>;
export type HealthParameterGetSuspenseQueryHookResult = ReturnType<typeof useHealthParameterGetSuspenseQuery>;
export type HealthParameterGetQueryResult = Apollo.QueryResult<HealthParameterGetQuery, HealthParameterGetQueryVariables>;