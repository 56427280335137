import { AppTypes, HealthProgramTemplate, HealthProgramTemplateSortingField } from "@/schema/types";
import { getHealthConditionsAutocompleteFilter, getUsersEmailsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, guidedCareTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps, Typography, useFormatDate } from "@toolkit/ui";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GuidedCareTemplateActivation } from "../../components/TemplateActivation/GuidedCareTemplateActivation";

export const useGuidedCareTemplateListColumns = (): CustomTableColumnProps<HealthProgramTemplate>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { formatLocalizedDate } = useFormatDate();

  const onShowTimelineClick = useCallback(
    (templateId: string) => {
      return () => {
        navigate(guidedCareTemplatesPaths.timeline.fullPathWithParams({ templateId }));
      };
    },
    [navigate]
  );

  const onManageClick = useCallback(
    (templateId: string) => {
      navigate(guidedCareTemplatesPaths.activitiesItemsManage.fullPathWithParams({ templateId: templateId }));
    },
    [navigate]
  );

  const onCloneClick = useCallback(
    (templateId: string) => {
      navigate(guidedCareTemplatesPaths.clone.fullPathWithParams({ templateId: templateId }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        type: "string",
        sort: {
          columnEnum: HealthProgramTemplateSortingField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ guidedCareType }) => guidedCareTypeOptionsMap[guidedCareType!]?.label,
        type: "string",
        sort: {
          columnEnum: HealthProgramTemplateSortingField.GuidedCareType,
        },
        filter: getAutocompleteEnumFilter("GuidedCareType", "guidedCareType"),
      },
      {
        key: "healthCondition",
        header: t("Health Condition"),
        showOnlyForFilterField: true,
        filter: getHealthConditionsAutocompleteFilter({ name: "healthConditionId" }),
      },
      {
        key: "timeline",
        header: t("Timeline"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowTimelineClick(id!)(),
          tooltipMessage: row => t("show {{name}} timeline", { name: row.name }),
        },
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ id }) => <Button onClick={() => onManageClick(id!)}>{t("Manage")}</Button>,
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => <Button onClick={() => onCloneClick(id!)}>{t("Clone")}</Button>,
      },
      {
        key: "version",
        header: t("Version"),
        type: "string",
        accessor: "version",
      },
      {
        key: "published",
        header: t("Published"),
        accessor: ({ isDraft }) => (isDraft ? t("No") : t("Yes")),
        sort: {
          columnEnum: HealthProgramTemplateSortingField.IsDraft,
        },
        filter: getAutocompleteEnumFilter("YesNo", "isDraft", { reverse: true }),
      },
      {
        key: "templateDuration",
        header: t("Duration"),
        accessor: "templateDuration",
      },
      {
        key: "NoOfActivities",
        header: t("Number of Activities"),
        accessor: ({ templateActivities }) => <Typography>{templateActivities?.length || "-"}</Typography>,
      },
      {
        key: "createdBy",
        header: t("Created By"),
        showOnlyForFilterField: true,
        filter: getUsersEmailsAutocompleteFilter({ name: "createdBy", filter: { appType: [AppTypes.Admin] } }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        sort: {
          columnEnum: HealthProgramTemplateSortingField.Created,
        },
        accessor: ({ createdDate }) => formatLocalizedDate(createdDate),
      },
      {
        key: "createdDateFrom",
        header: t("Created Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "creationDate.gte",
        },
      },
      {
        key: "createdDateTo",
        header: t("Created Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "creationDate.lte",
        },
      },
      {
        key: "isVerified",
        header: t("Verified"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isVerified"),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: row => <GuidedCareTemplateActivation id={row.id} isActive={!!row.active} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [formatLocalizedDate, onCloneClick, onManageClick, onShowTimelineClick, t]);
};
