import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useIoAgentCreateMutation } from "../../gql";
import { useIOAgentsBreadcrumbs } from "../../hooks";
import { convertIOAgentFormValuesToBackEndValues } from "../../utils";
import { IOAgentUpsertForm } from "../../forms/IOAgentUpsert/IOAgentUpsertForm";
import { IIOAgentUpsertForm } from "../../forms/IOAgentUpsert/IOAgentUpsertFormSchema";

export const IOAgentCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createIoAgent, { loading: isSubmitting }] = useIoAgentCreateMutation({
    onCompleted: () => {
      succeeded(t("Agent created successfully"));
      navigate(-1);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onIOAgentUpsertFormChange = (values: IIOAgentUpsertForm) => {
    createIoAgent({
      variables: {
        input: convertIOAgentFormValuesToBackEndValues(values),
      },
    });
  };

  useIOAgentsBreadcrumbs("CREATE");

  return <IOAgentUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onIOAgentUpsertFormChange} />;
};
