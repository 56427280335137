/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import DefaultArticleImage from "@/assets/sessionContent.png";
import { PermissionEnum } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { getMediaLink, hasPermission, useOpenState } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import {
  ArrowButton,
  Box,
  Button,
  CustomDialog,
  CustomToggleButton,
  EmptyCard,
  IconButton,
  PrevButton,
  ShowButton,
  Skeleton,
  Typography,
} from "@toolkit/ui";
import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { medicalMessagesPaths } from "../../constants";
import { useMedicalMessageVariantListQuery } from "../../gql";
import { useStyles } from "./MedicalMessagePreviewStyle";

type MedicalMessagePreviewProps = {
  id?: string;
  image?: string | null;
};

export const MedicalMessagePreview: FC<MedicalMessagePreviewProps> = props => {
  const { id, image: parentHeaderImage } = props;
  const { t } = useTranslation("gcadmin");
  const [isArabic, setIsArabic] = useState(false);
  const { open, handleOpen, handleClose } = useOpenState();
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const [currentVariantIndex, setCurrentVariantIndex] = useState<{ first: number; after?: string; before?: string }>({ first: 1 });

  const handleChangeLanguage = () => {
    setIsArabic(prev => !prev);
  };

  const { data, loading, networkStatus, fetchMore } = useMedicalMessageVariantListQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...currentVariantIndex,
      filter: {
        medicalMessageId: id!,
      },
    },
    fetchPolicy: "no-cache",
    skip: !open || !id,
  });

  const medicalMessageVariants = data?.medicalMessageVariants?.edges?.map(({ node }) => node) || [];
  const pageInfo = data?.medicalMessageVariants?.pageInfo;

  const handleNext = async () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 1,
          after: pageInfo?.endCursor,
          filter: {
            medicalMessageId: id!,
          },
        },
      }).then(() => {
        setCurrentVariantIndex(prev => ({
          first: prev.first,
          after: pageInfo?.endCursor!,
          before: undefined,
        }));
      });
    }
  };

  const handlePrevious = () => {
    if (!pageInfo?.hasPreviousPage) return;
    setCurrentVariantIndex(prev => ({
      first: prev.first,
      after: undefined,
      before: pageInfo?.startCursor!,
    }));
  };

  const onNavigateToEdit = () => {
    navigate(medicalMessagesPaths.variantUpdate.fullPathWithParams({ medicalMessageId: id!, medicalMessageVariantId: currentVariant?.id }));
  };
  const onNavigateToCreate = () => {
    navigate(medicalMessagesPaths.variantCreate.fullPathWithParams({ medicalMessageId: id! }));
  };

  const previousArrowIcon = pickLocalizedValue(<PrevButton color='inherit' />, <ArrowButton color='inherit' />);
  const nextArrowIcon = pickLocalizedValue(<ArrowButton color='inherit' />, <PrevButton color='inherit' />);
  const [currentVariant] = medicalMessageVariants;
  const isLoading = loading || networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.fetchMore;

  const imageUrl = currentVariant?.headerImage
    ? getMediaLink(currentVariant.headerImage)
    : parentHeaderImage
    ? getMediaLink(parentHeaderImage)
    : DefaultArticleImage;

  return (
    <CustomDialog
      type={"base"}
      open={open}
      maxWidth='sm'
      DialogContentProps={{
        className: classes.dialogContent,
      }}
      button={<ShowButton onClick={handleOpen} />}
      DialogTitleProps={{
        hasCloseButton: false,
        title: t("Preview"),
        children: (
          <Box className={classes.dialogBox}>
            <CustomToggleButton checked={!isArabic} color='info' onChange={handleChangeLanguage} className={classes.toggleIconButton} />
            <Typography>{isArabic ? "عربي" : "English"}</Typography>
          </Box>
        ),
      }}
      DialogActionsProps={{
        children: (
          <Box className={classes.dialogActions}>
            <Box flex={1} display='flex' justifyContent='start'>
              {hasPermission(PermissionEnum.ManageHealthMessages) &&
                (currentVariant ? (
                  <Button color='primary' onClick={onNavigateToEdit}>
                    {t("Edit")}
                  </Button>
                ) : (
                  <Button color='primary' onClick={onNavigateToCreate}>
                    {t("Create Variant")}
                  </Button>
                ))}
            </Box>
            <Box flex={1} display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
              <IconButton onClick={handlePrevious} color='primary' disabled={isLoading || !pageInfo?.hasPreviousPage}>
                {previousArrowIcon}
              </IconButton>
              <Typography className={classes.variantNumber}>{currentVariant?.id}</Typography>
              <IconButton onClick={handleNext} color='primary' disabled={isLoading || !pageInfo?.hasNextPage}>
                {nextArrowIcon}
              </IconButton>
            </Box>
            <Box flex={1} display='flex' justifyContent='end'>
              <Button color='primary' variant='outlined' onClick={handleClose}>
                {t("Cancel")}
              </Button>
            </Box>
          </Box>
        ),
      }}
    >
      {isLoading ? (
        <>
          <Skeleton variant='rectangular' width='100%' height='200px' />
          <Box width='100%' margin='10px'>
            <Skeleton variant='text' width='60%' />
            <Skeleton variant='text' width='80%' />
            <Skeleton variant='text' width='80%' />
            <Skeleton variant='text' width='60%' />
            <Skeleton variant='text' width='60%' />
            <Skeleton variant='text' width='70%' />
            <Skeleton variant='text' width='80%' />
            <Skeleton variant='text' width='60%' />
            <Skeleton variant='text' width='70%' />
          </Box>
        </>
      ) : currentVariant ? (
        <Fragment>
          <img src={imageUrl} alt='Header' width='60' height='30' className={classes.img} />
          <Typography
            my={2}
            textAlign={"center"}
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.typography.fontWeightMedium}
            color={theme.palette.stale.main}
          >
            {isArabic ? currentVariant.presentedTitleArabic : currentVariant.presentedTitleEnglish}
          </Typography>
          <Typography textAlign={"center"} mb={1}>
            {isArabic ? currentVariant.bodyArabic : currentVariant.bodyEnglish}
          </Typography>
        </Fragment>
      ) : (
        <EmptyCard
          sx={{
            padding: "32px",
          }}
          title=''
          message={t("No variants available")}
        />
      )}
    </CustomDialog>
  );
};
