import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  dialogContent: {
    padding: 0,
  },
  dialogBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "104px",
    alignItems: "center",
  },
  toggleIconButton: {
    "&:hover": {
      "& .Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.info.main,
      },
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  grid: {
    borderRight: `1px solid ${theme.palette.gray[300]}`,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  box: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    display: "block",
  },
  boxShadow: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 60%)",
  },
}));
