import { ArticleCategory, Maybe } from "@/schema/types";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTable, ShowButton } from "@toolkit/ui";
import { useArticleCategoriesColumns } from "./useArticleCategoriesColumns";

type ArticleCategoryHealthConditionsProps = {
  articleCategories?: Maybe<Array<Maybe<ArticleCategory>>>;
};

export const ArticleCategories: FC<ArticleCategoryHealthConditionsProps> = props => {
  const { articleCategories } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog
      type={"info"}
      button={<ShowButton disabled={!articleCategories || !articleCategories?.length} />}
      title={t("Article Categories")}
    >
      <CustomTable data={articleCategories as ArticleCategory[]} columns={useArticleCategoriesColumns()} scrollable hasFooter={false} />
    </CustomDialog>
  );
};
