import { HealthCondition, HealthConditionSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, GridImageModal, formatDate } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { convertToTitleCase } from "utils";
import { HealthConditionActivation } from "../../components/HealthConditionActivation/HealthConditionActivation";
import { healthConditionsPaths } from "../../constants";

export const useHealthConditionListContainerColumns = (): CustomTableColumnProps<HealthCondition>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowParametersClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.parameterList.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  const onShowArticleCategoriesClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.articleCategoryList.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  const onShowDiagnosesClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.diagnosisList.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  const onCloneClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.clone.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "icon",
        header: t("Icon"),
        accessor: ({ icon }) => <GridImageModal image={icon!} title={t("Icon")} altText={t("Icon")} />,
      },
      {
        key: "code",
        header: t("Code"),
        type: "truncated-text",
        accessor: "code",
        sort: {
          columnEnum: HealthConditionSortingField.Code,
        },
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("risk-display"),
        type: "truncated-text",
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display!, arabicDisplay!),
        sort: {
          columnEnum: pickLocalizedValue(HealthConditionSortingField.Display, HealthConditionSortingField.ArabicDisplay),
        },
        filter: {
          type: "string",
          name: pickLocalizedValue("display", "arabicDisplay"),
        },
      },
      {
        key: "ArabicDisplay",
        header: t("risk-arabic-display"),
        filter: {
          type: "string",
          name: "arabicDisplay",
        },
        showOnlyForFilterField: true,
        settings: {
          hideFromSettings: true,
        },
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => t(convertToTitleCase(type || "-")),
        sort: {
          columnEnum: HealthConditionSortingField.Type,
        },
        filter: getAutocompleteEnumFilter("HealthConditionType", "type"),
      },
      {
        key: "disease",
        header: t("Disease"),
        type: "truncated-text",
        accessor: "disease",
        sort: {
          columnEnum: HealthConditionSortingField.Disease,
        },
        filter: {
          type: "string",
          name: "disease",
        },
      },
      {
        key: "umlsCode",
        header: t("UMLS Code"),
        type: "truncated-text",
        accessor: "umlsCode",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        type: "date",
        sort: {
          columnEnum: HealthConditionSortingField.CreatedDate,
        },
      },
      {
        key: "parameters",
        header: t("Parameters"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowParametersClick(id!),
          tooltipMessage: row => t("show {{name}} parameters", { name: pickLocalizedValue(row?.display, row?.arabicDisplay) }),
        },
      },
      {
        key: "articleCategories",
        header: t("Article Categories"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowArticleCategoriesClick(id!),
          tooltipMessage: row => t("show {{name}} article categories", { name: pickLocalizedValue(row?.display, row?.arabicDisplay) }),
        },
      },
      {
        key: "diagnosis",
        header: t("Diagnosis"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowDiagnosesClick(id!),
          tooltipMessage: row => t("show {{name}} diagnosis", { name: pickLocalizedValue(row?.display, row?.arabicDisplay) }),
        },
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon={"clone"} />} variant={"text"} onClick={() => onCloneClick(id!)}>
            {t("Clone")}
          </Button>
        ),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthConditionActivation id={id!} isActive={!!isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [onCloneClick, onShowArticleCategoriesClick, onShowDiagnosesClick, onShowParametersClick, t]);
};
