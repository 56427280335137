import { FC, useEffect, useState } from "react";
import { Box, CustomDrawer } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { Decision, DecisionPlanCategory } from "@/schema/types";
import { DecisionListContainer, DecisionManageDrawer, DecisionManageDrawerApi, DecisionPlanCode } from "@health/domains";

type IGuidedCareTemplateHealthParameterDecisionsDrawerData = {
  healthParameterCode: string;
};

type IOpen = (data: IGuidedCareTemplateHealthParameterDecisionsDrawerData) => void;
type IClose = () => void;

export const GuidedCareTemplateHealthParameterDecisionsDrawerApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateHealthParameterDecisionsDrawerProps = {
  guidedCareTemplateCode: string;
};

export const GuidedCareTemplateHealthParameterDecisionsDrawer: FC<GuidedCareTemplateHealthParameterDecisionsDrawerProps> = props => {
  const { guidedCareTemplateCode } = props;

  const [data, setData] = useState<IGuidedCareTemplateHealthParameterDecisionsDrawerData>();
  const [key, setKey] = useState(0);

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const healthParameterCode = data?.healthParameterCode!;

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const onAddNewClick = () => {
    DecisionManageDrawerApi.open({ healthParameterCode });
  };

  const onEditRowClick = (item: Decision) => {
    DecisionManageDrawerApi.open({ decisionId: item?.id!, healthParameterCode });
  };

  const onDecisionManageDrawerChange = () => {
    setKey(prev => prev + 1);
  };

  useEffect(() => {
    GuidedCareTemplateHealthParameterDecisionsDrawerApi.open = open;
    GuidedCareTemplateHealthParameterDecisionsDrawerApi.close = handleClose;
  }, []);

  return (
    <>
      <DecisionManageDrawer
        category={DecisionPlanCategory.RiskParameter}
        decisionPlanCode={DecisionPlanCode.RiskParameter}
        guidedCareTemplateCode={guidedCareTemplateCode}
        onChange={onDecisionManageDrawerChange}
      />

      <CustomDrawer isOpen={isOpen} onClose={handleClose}>
        <Box sx={{ marginTop: "50px" }}>
          <DecisionListContainer
            key={key}
            category={DecisionPlanCategory.RiskParameter}
            filter={{ guidedCareTemplateCode, healthParameterCode }}
            onAddNewClick={onAddNewClick}
            onEditRowClick={onEditRowClick}
          />
        </Box>
      </CustomDrawer>
    </>
  );
};
