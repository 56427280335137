import { IAutocompleteBasicProps } from "@/types";
import { useGetEventList } from "@sada/react/queries";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";

type SadaVideosAutocompleteProps = IAutocompleteBasicProps;

export const SadaVideosAutocomplete: FC<SadaVideosAutocompleteProps> = props => {
  const { label, ...rest } = props;

  const { t } = useTranslation("domains");

  const { events, loading, hasMore, fetchMore } = useGetEventList({ type: "vod" });

  const _data = events?.map(event => ({
    id: event?.slug,
    title: event?.title,
  }));

  const options = mapToAutocompleteOptions(_data, "id", "title");

  return (
    <FormAutocomplete
      {...rest}
      label={label || (props.multiple ? t("Videos") : t("Video"))}
      options={options}
      loading={loading}
      hasMore={hasMore}
      handleFetchMore={fetchMore}
    />
  );
};
