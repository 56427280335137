import { MedicalMessage, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { createMedicalMessagesBreadcrumbs, medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { useMedicalMessageListQuery } from "pages/MedicalMessages/gql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMedicalMessagesTableColumns } from "./useMedicalMessagesTableColumns";

export const MedicalMessagesContainer = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("gcadmin");

  const onAddNewItemClick = () => {
    navigate(medicalMessagesPaths.create.fullPath);
  };

  const onEditRowClick = (item: MedicalMessage) => {
    navigate(medicalMessagesPaths.update.fullPathWithParams({ medicalMessageId: item?.id }));
  };

  useEffect(() => {
    setBreadCrumb({ title: createMedicalMessagesBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  const hasManageHealthMessages = hasPermission(PermissionEnum.ManageHealthMessages);
  return (
    <GridProvider
      gridName={"medicalMessageList"}
      columns={useMedicalMessagesTableColumns()}
      query={useMedicalMessageListQuery}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: hasManageHealthMessages,
        isRowEditable: hasManageHealthMessages,
        onEditRow: onEditRowClick,
      }}
      fetchPolicy='network-only'
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew={hasManageHealthMessages} onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
