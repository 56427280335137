import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ArticleCategoryFragmentFragmentDoc } from '../../fragments/__generated__/ArticleCategory';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoryParentGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ArticleCategoryParentGetQuery = { __typename?: 'Query', articleCategory?: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null } | null };


export const ArticleCategoryParentGetDocument = gql`
    query ArticleCategoryParentGet($id: ID!) {
  articleCategory(id: $id) {
    ...ArticleCategoryFragment
  }
}
    ${ArticleCategoryFragmentFragmentDoc}`;

/**
 * __useArticleCategoryParentGetQuery__
 *
 * To run a query within a React component, call `useArticleCategoryParentGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryParentGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryParentGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleCategoryParentGetQuery(baseOptions: Apollo.QueryHookOptions<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables> & ({ variables: ArticleCategoryParentGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables>(ArticleCategoryParentGetDocument, options);
      }
export function useArticleCategoryParentGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables>(ArticleCategoryParentGetDocument, options);
        }
export function useArticleCategoryParentGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables>(ArticleCategoryParentGetDocument, options);
        }
export type ArticleCategoryParentGetQueryHookResult = ReturnType<typeof useArticleCategoryParentGetQuery>;
export type ArticleCategoryParentGetLazyQueryHookResult = ReturnType<typeof useArticleCategoryParentGetLazyQuery>;
export type ArticleCategoryParentGetSuspenseQueryHookResult = ReturnType<typeof useArticleCategoryParentGetSuspenseQuery>;
export type ArticleCategoryParentGetQueryResult = Apollo.QueryResult<ArticleCategoryParentGetQuery, ArticleCategoryParentGetQueryVariables>;