import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthConditionFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthConditionSortingInput>;
}>;


export type HealthConditionListQuery = { __typename?: 'Query', getHealthConditions?: { __typename?: 'HealthConditioneConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'HealthConditionEdge', node?: { __typename?: 'HealthCondition', id?: string | null, display?: string | null, arabicDisplay?: string | null, code?: string | null, umlsCode?: string | null, type?: Types.HealthConditionType | null, disease?: string | null, icon?: string | null, isActive?: boolean | null, createdDate?: string | null } | null } | null> | null } | null };


export const HealthConditionListDocument = gql`
    query HealthConditionList($first: Int, $last: Int, $before: String, $after: String, $filter: HealthConditionFilterInput, $sortBy: HealthConditionSortingInput) {
  getHealthConditions(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        display
        arabicDisplay
        code
        umlsCode
        type
        disease
        icon
        isActive
        createdDate
      }
    }
  }
}
    `;

/**
 * __useHealthConditionListQuery__
 *
 * To run a query within a React component, call `useHealthConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthConditionListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthConditionListQuery(baseOptions?: Apollo.QueryHookOptions<HealthConditionListQuery, HealthConditionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthConditionListQuery, HealthConditionListQueryVariables>(HealthConditionListDocument, options);
      }
export function useHealthConditionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthConditionListQuery, HealthConditionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthConditionListQuery, HealthConditionListQueryVariables>(HealthConditionListDocument, options);
        }
export function useHealthConditionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthConditionListQuery, HealthConditionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthConditionListQuery, HealthConditionListQueryVariables>(HealthConditionListDocument, options);
        }
export type HealthConditionListQueryHookResult = ReturnType<typeof useHealthConditionListQuery>;
export type HealthConditionListLazyQueryHookResult = ReturnType<typeof useHealthConditionListLazyQuery>;
export type HealthConditionListSuspenseQueryHookResult = ReturnType<typeof useHealthConditionListSuspenseQuery>;
export type HealthConditionListQueryResult = Apollo.QueryResult<HealthConditionListQuery, HealthConditionListQueryVariables>;