import { useCustomFormContext, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography } from "@toolkit/ui";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { MedicalFormQuestionUpsertModal } from "../../modals";
import { IMedicalFormQuestionItem, IMedicalFormQuestionUpsertFormEvent } from "../../types";
import { IMedicalFormUpsertFormValues } from "../MedicalFormUpsert/MedicalFormUpsertFormSchema";
import { useMedicalFormQuestionsFormStyle } from "./useMedicalFormQuestionsFormStyle";
import { useMedicalFormQuestionsTableColumns } from "./useMedicalFormQuestionsTableColumns";

export const MedicalFormQuestionsForm = () => {
  const [selectedQuestion, setSelectedQuestion] = useState<IMedicalFormQuestionItem>();

  const { t } = useTranslation("domains");
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes, theme } = useMedicalFormQuestionsFormStyle();

  const form = useCustomFormContext<IMedicalFormUpsertFormValues>();

  const { fields, append, remove, update } = useFieldArray<IMedicalFormUpsertFormValues, "questions">({
    control: form.control,
    name: "questions",
  });

  const onAddClick = () => {
    setSelectedQuestion(undefined);
    handleOpen();
  };

  const onMedicalFormQuestionUpsertModalChange = (event: IMedicalFormQuestionUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      handleClose();
    } else if (event.type === "UPDATE" && selectedQuestion) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedQuestion.id);
      if (updatedFieldIndex > -1) {
        const updatedTeaMember = { ...fields[updatedFieldIndex], ...event.payload.values };
        update(updatedFieldIndex, updatedTeaMember);
      }
      handleClose();
    }
  };

  const onEditRowClick = (item: IMedicalFormQuestionItem) => {
    setSelectedQuestion(item);
    handleOpen();
  };

  const onDeleteRowClick = (item: IMedicalFormQuestionItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);
    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  return (
    <Grid container spacing={2}>
      <MedicalFormQuestionUpsertModal
        isOpen={open}
        onClose={handleClose}
        question={selectedQuestion}
        onChange={onMedicalFormQuestionUpsertModalChange}
      />

      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"}>
          <Grid>
            <Typography className={classes.title}> {t("Questions")} </Typography>
          </Grid>

          <Grid>
            <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon fill={theme.palette.common.white} />}>
              {t("Add Question")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useMedicalFormQuestionsTableColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable
          isRowDeletable
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
