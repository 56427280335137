import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { RobotIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { ioAgentsRoutes } from "@/pages/IOAgents/constants";
import { aiAgentsRoutes } from "@/pages/AIAgents/constants";

export const agentsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "agents-routes",
    text: i18n.t("Agents", { ns: "gcadmin" }),
    icon: <RobotIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageChatFlow) && !hasPermission(PermissionEnum.ManageAgentDefinitions),
    subItems: [aiAgentsRoutes({ navigate }), ioAgentsRoutes(navigate)],
  };
};
