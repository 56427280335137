import { HealthParameter, TemplateFieldType } from "@/schema/types";
import { chartTypeOptionsMap, templateFieldTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { getHealthParameterDefaultValuesInfoItems } from "../../others/cellInfo.utils";

export const useHealthParameterCustomTableListColumns = (): CustomTableColumnProps<HealthParameter>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "standardCode",
        header: t("Standard Code"),
        accessor: "standardCode",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "display",
        header: t("Display"),
        type: "truncated-text",
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display!, arabicDisplay!),
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => pickLocalizedValue(category?.display!, category?.arabicDisplay!),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => templateFieldTypeOptionsMap[type!]?.label,
      },
      {
        key: "chartType",
        header: t("Chart Type"),
        accessor: ({ chartType }) => chartTypeOptionsMap[chartType!]?.label,
      },
      {
        key: "unit",
        header: t("Unit"),
        accessor: ({ unit }) => pickLocalizedValue(unit?.display!, unit?.arabicDisplay!),
      },
      {
        key: "referenceRange",
        header: t("Reference Range"),
        accessor: "referenceRange",
      },
      {
        key: "defaultRangeValues",
        header: t("Default Range Values"),
        type: "info",
        infoCellOptions: {
          containerProps: {
            maxWidth: "sm",
          },
          title: t("Default Range Values"),
          items: getHealthParameterDefaultValuesInfoItems,
          layout: "one-column",
          disableShowButton: ({ type }) => type !== TemplateFieldType.Number && type !== TemplateFieldType.String,
        },
      },
    ];
  }, [t]);
};
