import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import {
  MedicalFormQuestionUpsertForm,
  MedicalFormQuestionUpsertFormRef,
} from "@/pages/MedicalForms/forms/MedicalFormQuestionUpsert/MedicalFormQuestionUpsertForm";
import { IMedicalFormQuestionUpsertFormValues } from "@/pages/MedicalForms/forms/MedicalFormQuestionUpsert/MedicalFormQuestionUpsertFormSchema";
import { IMedicalFormQuestionUpsertFormEvent } from "@/pages/MedicalForms/types";
import { FC, MouseEvent, useRef } from "react";

type MedicalFormQuestionUpsertModalProps = {
  question?: IMedicalFormQuestionUpsertFormValues;
  onChange: (values: IMedicalFormQuestionUpsertFormEvent) => void;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const MedicalFormQuestionUpsertModal: FC<MedicalFormQuestionUpsertModalProps> = props => {
  const { question, onChange, onClose: handleClose, isOpen } = props;

  const { t } = useTranslation("domains");
  const medicalFormQuestionUpsertFormRef = useRef<MedicalFormQuestionUpsertFormRef>(null);

  const onButtonClick = () => {
    medicalFormQuestionUpsertFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: question ? t("Update Question") : t("Add New Question"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: !!question,
        submitTitle: question ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <MedicalFormQuestionUpsertForm question={question!} ref={medicalFormQuestionUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
