import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IMedicalFormQuestionItem } from "../../types";

export const useMedicalFormQuestionsTableColumns = (): CustomTableColumnProps<IMedicalFormQuestionItem>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "question",
        header: t("Question"),
        accessor: ({ question }) => question,
      },
      {
        key: "questionType",
        header: t("Question Type"),
        accessor: ({ questionType }) => questionType?.label,
      },
      {
        key: "required",
        header: t("Required"),
        accessor: ({ required }) => (required ? t("Required") : "-"),
      },
    ];
  }, [t]);
};
