import { ArticleCategoryType } from "@/schema/types";
import { ArticleCategoriesAutocomplete, ArticlesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormChipsInput, FormMDTextEditor, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import React from "react";
import { useOidcUserProfile } from "@/shared/hooks";

export const ArticleInformationForm = () => {
  const { t } = useTranslation("gcadmin");

  const { accessToken } = useOidcUserProfile();

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"title"} label={t("Title")} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"titleAr"} label={t("Arabic Title")} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <ArticleCategoriesAutocomplete name={"categories"} multiple filter={{ type: ArticleCategoryType.Child }} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormNumberField name={"readTimeMinutes"} label={t("Read Time (Minutes)")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormNumberField name={"priority"} label={t("Priority")} />
      </Grid>

      <Grid item xs={6}>
        <FormChipsInput name={"tags"} placeholder={t("Tags")} />
      </Grid>

      <Grid item xs={6}>
        <ArticlesAutocomplete label={t("Related Articles")} name={"relatedArticles"} multiple />
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"active"} label={t("Is Active")} />
      </Grid>

      <Grid item xs={12}>
        <FormMDTextEditor name={"description"} label={t("Description")} token={accessToken} />
      </Grid>
      <Grid item xs={12}>
        <FormMDTextEditor name={"descriptionAr"} label={t("Arabic Description")} token={accessToken} />
      </Grid>
    </Grid>
  );
};
