import { Theme } from "@toolkit/ui";
import { ProviderGuidedCareTeamStatus } from "../../schema/types";

export const getProviderGuidedCareTeamStatusColor = (status: ProviderGuidedCareTeamStatus, theme: Theme) => {
  switch (status) {
    case ProviderGuidedCareTeamStatus.Approved:
      return theme.palette.success.main;
    case ProviderGuidedCareTeamStatus.Pending:
      return theme.palette.warning.main;
    case ProviderGuidedCareTeamStatus.Rejected:
      return theme.palette.error.main;
  }
};
