import { DecisionMakerOrderDirection, HealthParameter, HealthParameterSortingField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { HealthParameterListPageWrapper } from "../../components/HealthParameterListPageWrapper/HealthParameterListPageWrapper";
import { healthParametersPaths } from "../../constants";
import { useHealthParameterListQuery } from "../../gql";
import { useSetHealthParametersBreadcrumbs } from "../../hooks";
import { HealthParameterCategoryCode } from "../../types";
import { useHealthParameterListContainerColumns } from "./useHealthParameterListContainerColumns";

export const HealthParameterListContainer = () => {
  const navigate = useNavigate();

  const onEditRowClick = (item: HealthParameter) => {
    navigate(healthParametersPaths.update.fullPathWithParams({ healthParameterId: item?.id! }));
  };

  useSetHealthParametersBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthParameterList"}
      query={useHealthParameterListQuery}
      columns={useHealthParameterListContainerColumns()}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
      variables={{
        filter: {
          excludeCategoryCode: [HealthParameterCategoryCode.RiskParameter],
        },
        sortBy: {
          direction: DecisionMakerOrderDirection.Desc,
          field: HealthParameterSortingField.CreatedDate,
        },
      }}
      fetchPolicy='network-only'
    >
      <HealthParameterListPageWrapper />
    </GridProvider>
  );
};
